
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { FleetManagerActions } from 'src/app/store/actions';
import { getFleetConfig } from 'src/app/store/selectors/fm-configuration.selector';
import { State } from 'src/app/store/state';
import { FleetConfigurationService } from '../../services/fleet-configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { FleetManagementService } from '../../services/fleet-management.service';
import { DeploymentParameters } from '../../models/deploymentParameters.model';
import { NoDataRow, TableGroup } from 'src/app/shared/models/ui/table-group';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, catchError, map, of, startWith } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Packager, Provisioned } from '../../models/provisioned.model';
import { OrganizationService } from 'src/app/internal-user/customer-management/services/organization.service';
import { AssetService } from 'src/app/external-user/inventory/services/asset.service';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';
import { Asset } from 'src/app/external-user/inventory/models/asset';
import { AutoCompleteDropDownValidators } from 'src/app/shared/directives/validate-selectedvalue-in-collection';
import { GenericConfirmationDialogComponent } from 'src/app/shared/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { AutoCompleteField } from 'src/app/shared/models/auto-complete-field';

interface TableRow extends TableGroup, NoDataRow, OrgSystemModel {
  index?: number;
}
interface depTypeGrpModel {
  groupName: string;
  count: number;
  enabled: true | false;
  orgSysList: OrgSystemModel[];
}

interface orgSysDeployModel {
  edgeDeviceId?: string;
  edgeDeviceFriendlyName: string;
  customerId: string;
  provisioned: string;
  ipAddress?: string;
  connectionState: string;
  connectionStateUpdatedTime: string;
  organizationUI: string;
  packagerSystemDTOs: OrgSystemModel[];
  tags:deviceTagsModel;
}
interface deviceTagsModel {
  CustomerId: string;
  FriendlyName: string;
  SiteId: string;
  DeviceID: string;
  template: string;
  provisioned: boolean;
  DeprovisionState?: string;
}

interface OrgSystemModel {
  organizationId?: string;
  systemFriendlyName: string;
  siteId: string;
  siteFriendlyName: string;
  systemId: string;
  deploymentType?: string;
  hostName: string;
  username?: string;
  enabled: boolean;
}

@Component({
  selector: 'app-add-new-system-dialog',
  templateUrl: './add-new-system-dialog.component.html',
  styleUrls: ['./add-new-system-dialog.component.scss']
})
export class AddNewSystemDialogComponent implements OnInit {
  readonly defaultValidationLength: number = 50;
  deploymentList: any[] = [];
  templateDetails: any = {};
  provisionedSystems: Packager[];
  sites: Organization[];
  systems: Asset[];
  activeAssets: Asset[];
  filteredSites: Observable<Organization[]>;
  filteredSystems: Observable<Asset[]>;
  
  get deploymentTemplate() { return this.formGroup.get('deploymentType'); }
  formGroup: FormGroup;
  
  
  @ViewChild('tblProvisionedHeader') tblProvisionedHeader: any;
  @ViewChild('tblProvisionedContent') tblProvisionedContent: any;
  displayedColumns: string[] = ['siteFriendlyName','siteId','systemFriendlyName', 'systemId', 'hostName'];
  provisionedList = new MatTableDataSource();
  packagerList = new MatTableDataSource<TableRow>([]);
  currentOrgElement: depTypeGrpModel;
  currentOrgHeader: string;
  orgName: string;
  siteName: string;
  orgId: string;
  siteId: string;
  orgList: OrgSystemModel[] = [];
  provisionedListData: depTypeGrpModel[] = [];
  isValidFormSubmitted: boolean = false;
  isSystemAdded: boolean = false;
  orgSysDeploy: orgSysDeployModel;
  edgeDeviceId:string;
  deploymentUserList:any[];

  constructor(
    public store: Store<State>,
    public fleetConfigService: FleetConfigurationService,
    private fleetManagementService: FleetManagementService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<AddNewSystemDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public parentCompData: any,    
    private fb: FormBuilder,
    public organizationService: OrganizationService,
    public assetService: AssetService,
  ) {this.formGroup = this.fb.group({
    deploymentType: new FormControl('', Validators.required),
    siteId: new FormControl('', Validators.required),
    systemId: new FormControl('', Validators.required),
    dbHostName: new FormControl('', [Validators.required, Validators.pattern('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')]),
    dbusername: new FormControl('', Validators.required)

  }); }

  ngOnInit() { 
      this.filteredSystems = this.formGroup.get('systemId').valueChanges.pipe(
        startWith(''),
        map(value => this._filterSystem(value || '')));

    this.deploymentTemplate.valueChanges.subscribe((templateName) => this.updateTemplate(templateName));

    this.store.select(getFleetConfig).subscribe(deploymentOptions => {
      if (!deploymentOptions) {
        return;
      }

      this.deploymentList = deploymentOptions.templates.map(template => {
        return {
          viewData: template,
          formData: template
        }
      });

      this.templateDetails = deploymentOptions.templateDetails = Object.keys(deploymentOptions.templateDetails).reduce((newObj, key) => {
        newObj[key] = deploymentOptions.templateDetails[key];
        return newObj;
      }, {});

      this.deploymentTemplate.setValue(this.deploymentList[0].formData, {
        onlySelf: true
      });
    });

    this.deploymentUserList =this.templateDetails[this.deploymentList[0].formData]?.Username?.map(template => {
      return {
        viewData: template,
        formData: template
      }
    });
    
    this.edgeDeviceId = this.parentCompData?.provisionedDeviceConfig.edgeDeviceId;
    this.orgName = this.parentCompData?.provisionedDeviceConfig.customerId;
    this.activeAssets = this.parentCompData?.activeAssets;
    
    this.loadSites();
    this.getProvisionedSystems();    
  }

  private getProvisionedSystems() {
    this.fleetManagementService.getProvisionedSystems()
      .subscribe({
        next: (systems) => {
          this.provisionedSystems = systems;
        }
      });
  }

  loadSites() {
    var org = this.parentCompData?.organizations?.find(x => x.name.toLowerCase().includes(this.orgName.toLowerCase()));
    this.organizationService.getSitesByParentOrgId(org?.organizationId)
      .subscribe({next: (sites) => {  
        this.sites = sites.filter(site => site.customerNo != null && site.enabled === true);                
        this.formGroup.get('siteId').clearValidators();
        this.formGroup.get('siteId').setValidators([Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(this.sites, AutoCompleteField.customerNo)]);      
        this.filteredSites = this.formGroup.get('siteId').valueChanges.pipe(
          startWith(''),
          map(value => this._filterSite(value || '')));
        this.getEdgeApplianceDetails();
      }, error: (e) => {
        this.toastService.stopLoadingAndShowError(e,
          'Error while loading sites for parent org!');        
      }});
  }

  getSystemName(option: any) {
    return this.getSelectedValue(option, "machineNo");
  }

  getSiteName(option: any) {
    return this.getSelectedValue(option, "customerNo");
  }

  getSelectedValue(option, caseType) : string {
    if(typeof option === 'string') {
      return option;  
    } else {
      switch(caseType) {
        case "customerNo": {
            return option ? `${option?.name} (${option?.customerNo})` : '';             
        }
        case "machineNo": {
          return option ? `${option?.name} (${option?.machineNo})`: '';             
        }        
      }
    }
  }

  formatToLowerCase(option: string) : string {
    return option === null || option.length === 0 ? '' : option?.toLowerCase();
  }

  getSite(option: any) {
    let searchValue = this.formatToLowerCase(this.getSelectedValue(option, "customerNo"));
    let site = this.sites?.find(x => `${x.name} (${x.customerNo})`?.toLowerCase() === searchValue);
    if(site != null){
      this.filteredSystems = of([]);      
      this.loadSystems(site);      
    }
  }

  private _filterSite(value: string): Organization[] {
    let filterValue = this.formatToLowerCase(this.getSelectedValue(value, "customerNo"));
    return this.sites?.filter(site => site?.customerNo?.toLowerCase()?.includes(filterValue) || site?.name?.toLowerCase()?.includes(filterValue) || `${site?.name?.toLowerCase()} (${site?.customerNo?.toLowerCase()})`?.includes(filterValue));    
  }

  private _filterSystem(value: string): Asset[] {
    let filterValue = this.formatToLowerCase(this.getSelectedValue(value, "customerNo"));    
    return this.systems?.filter(sys => sys?.machineNo?.toLowerCase()?.includes(filterValue) || sys?.name?.toLowerCase()?.includes(filterValue)  || `${sys?.name?.toLowerCase()} (${sys?.machineNo?.toLowerCase()})`?.includes(filterValue));
  }

  loadSystems(org) {            
    this.assetService.getAssetByOrgId(org.organizationId)
      .subscribe({next: (_assets) => {
        _assets = _assets.filter(asset => asset.enabled === true);                
        _assets = this.disableProvisionedSystems(_assets);
        this.systems = _assets;
        var touched =this.formGroup?.get('systemId')?.touched;
        this.formGroup?.get('systemId')?.reset();
        this.formGroup?.get('systemId')?.clearValidators();
        this.formGroup?.get('systemId')?.setValidators([Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(this.systems, AutoCompleteField.machineNo)]); 
        if (touched){
          this.formGroup?.get('systemId').markAsTouched();
        }
        this.filteredSystems = this.formGroup?.get('systemId')?.valueChanges.pipe(
                        startWith(''), 
                        map(value => this._filterSystem(value || '')));
        
      }, error: (response) => {
        this.toastService.stopLoadingAndShowError(response,
          'Unable to load systems!');        
      }});
  }

  disableProvisionedSystems(assets) {
    assets?.forEach((element) => {
      const system = this.provisionedSystems?.find(system => system?.systemId?.toLowerCase() === element?.machineNo?.toLowerCase());
      if(system != null) {
        element.isProvisioned = true;
      }
      else {
        element.isProvisioned = false;
      }
    });
    return assets;    
  }

  getEdgeApplianceDetails() {
    const loadingKey = 'GetDeploymentParameters';

    this.fleetManagementService.getSystemssByEdgeDeviceId(this.edgeDeviceId).subscribe(
      (response) => {

        this.orgSysDeploy = response;
        let data = response;

        data.packagerSystemDTOs.map((t) => {
          let sysData: OrgSystemModel = {
            organizationId: data.customerId,
            systemFriendlyName: this.getSystemFriendlyName(t.systemId),
            siteId: t.siteId,
            siteFriendlyName: this.getSiteFriendlyName(t.siteId),
            systemId: t.systemId,
            deploymentType: t.deploymentType,
            hostName: t.hostName,
            username: t.username,
            enabled: t.enabled
          }
          this.orgList.push(sysData);
          this.provisionedListData = [];
          this.orgList.map((t) => { this.groupByElement(t); });
          this.provisionedList.data = this.provisionedListData;
        });
      },
    );
  }

  updateTemplate(templateName): void {  
    let templateOptions = this.templateDetails[templateName];
    this.formGroup.get('dbusername').setValue(''); 
    this.deploymentUserList =templateOptions?.Username?.map(template => {
      return {
        viewData: template,
        formData: template
      }
    });
  }

  getSystemFriendlyName(machineNo: string): string {
    const system = this.activeAssets.find(asset => asset.machineNo === machineNo);
    return system ? `${system.name}` : '';
  }
  
  getSiteFriendlyName(siteId: string): string {
    const site = this.sites.find(site => site.customerNo === siteId);
    return site ? `${site.name}` : '';
  }
 
  async addSystems() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.orgList.length > 14) {
      this.toastService.openToast('Deployment limit is set to 15', constants.ToastPanelType.error);
      return;
    }

    if (!(await this.checkForDuplicateIpWithinSiteAndProceed())) {
      return; // User chose not to proceed
    }
    
    var selectedSite = this.getSelectedValue(this.formGroup.get('siteId').value, "customerNo").toLowerCase();
    var selectedSystem = this.getSelectedValue(this.formGroup.get('systemId').value, "machineNo").toLowerCase();
    var site = this.sites?.find(x => `${x.name} (${x.customerNo})`.toLowerCase() === selectedSite);
    var system = this.systems?.find(sys => `${sys.name} (${sys.machineNo})`.toLowerCase() ===  selectedSystem);

    let org = {
      siteId: site?.customerNo,
      siteFriendlyName:this.getSiteFriendlyName(site?.customerNo),
      organizationId: this.orgId,
      systemFriendlyName:this.getSystemFriendlyName(system?.machineNo),
      systemId: system?.machineNo,
      hostName: this.formGroup.get('dbHostName').value?.trim(),
      username: this.formGroup.get('dbusername').value,
      deploymentType: this.formGroup.get('deploymentType').value,
      enabled: true
    };
    let isSystemExist = this.orgList.filter(item => item.systemId.toLowerCase() === org.systemId.toLowerCase())
    if (isSystemExist.length == 1) {
      this.toastService.openToast('System Id already exists!', constants.ToastPanelType.error);
    }
    else {
      this.orgList.push(org);
      this.isSystemAdded = true;
      this.provisionedListData = [];
      this.orgList.map((t) => { this.groupByElement(t); }
      );
      this.provisionedList.data = this.provisionedListData;
      this.formGroup.reset();
      this.filteredSystems = of([]);      
      this.systems=[];
      this.deploymentTemplate.setValue(this.deploymentList[0].formData, {
        onlySelf: true
      });
    }
  }

  private groupByElement(receivedData: OrgSystemModel) {
    let elements: depTypeGrpModel[] = this.provisionedListData;
    var existingEle = elements.find(x => x.groupName.toLowerCase() == receivedData.deploymentType.toLowerCase());

    if (!existingEle) {
      let groupElement: depTypeGrpModel = {
        groupName: receivedData.deploymentType,
        orgSysList: [],
        count: 1,
        enabled: true
      };

      groupElement.orgSysList.push(receivedData),

        elements.push(groupElement);
    } else {
      let updateElementCounter = existingEle?.count;
      updateElementCounter! += 1;
      let indexElementCount = this.provisionedListData.findIndex(x => x.groupName.toLowerCase() == receivedData.deploymentType.toLowerCase());
      this.provisionedListData[indexElementCount].count = updateElementCounter!;
      this.provisionedListData[indexElementCount].orgSysList.push(receivedData);
      this.provisionedListData[indexElementCount].enabled = true;
    }
  }

  submitForm() {
    if (!this.isSystemAdded && this.formGroup.valid) {
      this.toastService.openToast('Please add system to configure!', constants.ToastPanelType.warning);
      return;
    }
    else if (!this.isSystemAdded) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const loadingKey = 'SystemConfigurationSubmit';
    
    this.ngxLoader.start(loadingKey);
    this.isValidFormSubmitted = true;
    let data =
    {
      edgeDeviceId: this.parentCompData?.provisionedDeviceConfig.edgeDeviceId,
      edgeDeviceFriendlyName: '',
      customerId: this.parentCompData?.provisionedDeviceConfig.customerId,
      Systems: [{}],
      additionalProp1: '',
      additionalProp2: '',
      additionalProp3: ''
    }

    this.orgList.forEach((element, index) => {
      let systemValue = {
        DeploymentType: element.deploymentType,
        RequestOptions: {
          SystemID: element.systemId,
          SystemFriendlyName: element.systemFriendlyName,
          SiteID: element.siteId,
          SiteName:element?.siteFriendlyName,
          HostName: element.hostName,
          User: element.username
        },
      }
      data.Systems.push(systemValue);
    });
    data.Systems.splice(0, 1);

    this.fleetConfigService.putConfigTemplate(data).subscribe({
      next: () => {
        setTimeout(() => {
          this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());
          this.ngxLoader.stop(loadingKey);
          this.dialogRef.close('success');
        }, 5000);
        this.toastService.openToast('Systems configured successfully.', constants.ToastPanelType.done);
        this.isSystemAdded = false;
      },
      error: (error: any) => {
        this.ngxLoader.stop(loadingKey);
        if (error.status === 500) {
          this.toastService.openToast('Unable to configure systems!', constants.ToastPanelType.error);
        }
        else {
          this.toastService.openToast(error.error, constants.ToastPanelType.error);
        }
        this.dialogRef.close('error');
      }
    });   
  }

  toggleRow(element: depTypeGrpModel) {
    this.packagerList.data = element.orgSysList;
    this.currentOrgElement = element;
    this.currentOrgHeader = element.groupName + " (" + element.count + ")";
    this.currentOrgElement.enabled = true;
    console.log(this.packagerList);
  }

  applyRowClass(row: any): boolean {
    return row && row.enabled;
  }

  applyColumnClass(element: any): boolean {
    return element && element.enabled;
  }
  
  getSystemIdErrorMessage(): string | null {
    const systemIdControl = this.formGroup?.get('systemId');
    if (!systemIdControl) return null; // Return null if the control is not found
    let length = 0;
    this.filteredSystems?.subscribe(systems => {
      length = systems?.length;
    });

    if (length == null || length === 0){
      return 'No Serial Number to provision';
    }
    else if(systemIdControl?.hasError('required')){
      return 'Serial Number required';
    }
    else if (systemIdControl?.hasError('ShowValidatonError'))  {
      return 'Serial Number is invalid';
    }
    else {
      return null; // Return null if no error message is applicable
    }
  }

  getSiteIdErrorMessage(): string | null {
    const siteIdControl = this.formGroup?.get('siteId');
    if (!siteIdControl) return null; // Return null if the control is not found
    let length = 0;
    this.filteredSites?.subscribe(sites => {
      length = sites?.length;
    });

    if (length == null || length === 0){
      return 'No Customer Number to provision';
    }
    else if(siteIdControl?.hasError('required')){
      return 'Customer Number required';
    }
    else if (siteIdControl?.hasError('ShowValidatonError'))  {
      return 'Customer Number is invalid';
    }
    else {
      return null; // Return null if no error message is applicable
    }
  }

  checkForDuplicateIpWithinSiteAndProceed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let site = this.formGroup.get('siteId').value;
      let ipAddress = this.formGroup.get('dbHostName').value;

      // Clear duplicateIp error if any before checking 
    this.formGroup.get('dbHostName')?.setErrors(null);
  
      const systemsWithinSameDevice = this.orgList.filter(x => x.siteId == site.customerNo && x.hostName == ipAddress);
  
      if (systemsWithinSameDevice.length >= 1) {
        const message = `IP ${ipAddress} is already in use at the site ${site.name}. Are you sure you want to proceed?`;
        this.showDuplicateIpDialog(message).then((dialogResult) => {
          if (!dialogResult) {
            // User chose not to proceed, set custom validation error for duplicate IP
            this.formGroup.get('dbHostName')?.setErrors({ duplicateIp: true });
          }
          resolve(dialogResult);
        });
      } else {
        this.fleetManagementService.getSystemsInSiteByIp(site.customerNo, ipAddress).subscribe(
          (response) => {
            if (response.length >= 1) {
              const message = `IP ${ipAddress} is already in use at the site ${site.name}. Are you sure you want to proceed?`;
              this.showDuplicateIpDialog(message).then((dialogResult) => {
                if (!dialogResult) {
                  // User chose not to proceed, set custom validation error for duplicate IP
                  this.formGroup.get('dbHostName')?.setErrors({ duplicateIp: true });
                }
                resolve(dialogResult);
              });
            } else {
              resolve(true); // No duplicate IP found, proceed
            }
          },
        );
      }
    });
  }
  
  showDuplicateIpDialog(message: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let matDialogRef = this.dialog.open(GenericConfirmationDialogComponent, {
        backdropClass: 'smDialog'
      });
  
      matDialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult === true);
      });
  
      let instance = matDialogRef.componentInstance;
      instance.title = "Duplicate IP Address";
      instance.message = message;
      instance.dialogRef = matDialogRef;
    });
  }
  
}
