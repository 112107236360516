<div class="flex justify-between content-stretch items-stretch flex-col">
  <form [formGroup]="systemconfigForm">
    <div>
      <div>
        <h1 mat-dialog-title tabindex="0" class="xs:flex-1 gt-xs:basis-full">
          System Configuration
        </h1>
      </div>
      <mat-dialog-content>
        <div class="p-b-20 xs:flex xs:flex-col flex gap-4 flex-row">
          <mat-form-field class="p-t-15 xs:flex-1 gt-xs:basis-1/4 autocomplete" floatLabel="never">
            <label for="CustomerId" (click)="$event.stopPropagation()">
              <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
              Account Name
              <custom-tooltip text="Customer's Account Name in Salesforce."></custom-tooltip>
            </label>
            <div class="autocomplete-input">
              <input type="text" id="customerId" matInput appNoHtmlTags trim formControlName="customerId" [matAutocomplete]="auto" (blur)="getOrganization($event.target.value)">
              <button mat-button [ngStyle]="{'visibility': (customerId.value) ? 'visible' : 'hidden'}" mat-icon-button aria-label="" (click)="clear(customerId)">
                <mat-icon>close</mat-icon>
              </button>  
            </div>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getOrganization($event.option.value)'>
                <mat-option *ngFor="let org of filteredOrg | async" [value]="org.name"  matTooltip="{{org.name}}" matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
                  {{org.name}}
                </mat-option>
              </mat-autocomplete>
            <mat-error *ngIf="((filteredOrg | async) == null || (filteredOrg | async)?.length == 0) && customerId.errors?.['required'] && customerId.errors?.['ShowValidatonError']">No Account to provision</mat-error>
            <mat-error *ngIf="(filteredOrg | async)?.length > 0 && customerId.errors?.['required'] && customerId.errors?.['ShowValidatonError']">Account Name required</mat-error>
            <mat-error *ngIf="!customerId.errors?.['required'] && customerId.errors?.['ShowValidatonError']">Account Name is invalid</mat-error>  
          </mat-form-field>
          <div class="deploy-color padding flex gap-4 xs:flex-1 gt-xs:basis-3/4">
            <mat-form-field floatLabel="never" class="xs:basis-1/5 sm:basis-1/5 gt-sm:basis-[30%]">
              <label for="DeploymentType" (click)="$event.stopPropagation()">
                <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                Product Line Type
                <custom-tooltip text="Model of Device."></custom-tooltip>
              </label>
              <mat-select class="deploymentType" id="DeploymentType" formControlName="deploymentTemplate" 
                (selectionChange)="updateTemplate($event.value)" tabindex="0">
                <mat-option *ngFor="let type of deploymentList" [value]="type.formData">
                  {{ type.viewData }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="btn_add basis-[5%]">
              <button mat-button class="addPanel btn-default btn-sm" id="add" tabindex="0" type="button" 
                (click)="addPanel()">Add</button>
            </div>
          </div>
        </div>
        <div class="sysconfigdialog-container">
          <mat-accordion multi>
            <mat-expansion-panel togglePosition="before" *ngFor="let item of items;index i" [expanded]="panelOpenState">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{item?.title}} ({{item.rowcount}})
                </mat-panel-title>
                <mat-panel-description>
                  <button mat-button class="btn-default btn-sm" id="i" tabindex="0" 
                    (click)="valuesAdd($event,item?.title)" type="button">Add</button>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div formArrayName="filters">
                <div *ngFor="let system of systemFormArray.controls; let j = index;">
                  <div id="{{item?.title+j}}" [formGroupName]="j" *ngIf="item.title == system.value.template">
                    <div class="paddingtop flex flex-row justify-start content-stretch items-stretch xs:flex xs:flex-col gap-4">
                      <mat-form-field class="d-none xs:flex-1 gt-xs:basis-[0%]" floatLabel="never">
                        <input type="text" matInput appNoHtmlTags trim id="{{'Template'+j}}" formControlName="template" 
                          tabindex="0">
                      </mat-form-field>
                      <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/5">
                        <label for="SiteId">
                          <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                          Customer Number
                          <custom-tooltip text="Salesforce Customer Number."></custom-tooltip>
                        </label>
                          <input type="text" matInput appNoHtmlTags trim id="{{'siteId'+j}}" formControlName="siteId" [matAutocomplete]="auto" (blur)="getSite($event.target.value, j)">
                          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySite" (optionSelected)='getSite($event.option.value, j)'>
                            <mat-option *ngFor="let option of filteredSites[j] | async" [value]="option"  matTooltip="{{option.name}} ({{option.customerNo}})" matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
                              {{option.name}} ({{option.customerNo}})
                            </mat-option>
                          </mat-autocomplete>          
                        <mat-error *ngIf="((filteredSites[j] | async) == null || (filteredSites[j] | async)?.length == 0) && systemFormArray.controls[j].get('siteId').errors?.required && systemFormArray.controls[j].get('siteId').errors?.['ShowValidatonError']">No Customer Number to provision</mat-error>                                      
                        <mat-error *ngIf="(filteredSites[j] | async)?.length > 0 && systemFormArray.controls[j].get('siteId').errors?.required && systemFormArray.controls[j].get('siteId').errors?.['ShowValidatonError']">Customer Number required</mat-error>
                        <mat-error *ngIf="!systemFormArray.controls[j].get('siteId').errors?.required && systemFormArray.controls[j].get('siteId').errors?.['ShowValidatonError']">Customer Number is invalid</mat-error> 
                      </mat-form-field>
                      <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/5">
                        <label for="DeviceId">
                          <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                          Serial Number 
                          <custom-tooltip text="Device serial number, found on the nameplate attached to the chassis."></custom-tooltip>
                        </label>
                        <input type="text" matInput appNoHtmlTags trim id="{{'deviceId'+j}}" formControlName="deviceId" [matAutocomplete]="autosys">
                          <mat-autocomplete #autosys="matAutocomplete" [displayWith]="displaySystem">
                            <mat-option *ngFor="let system of filteredSystems[j] | async" [value]="system" class="disableProvisionedSystems" [disabled]="system.isProvisioned"  matTooltip="{{system.name}} ({{system.machineNo}})" matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
                              <span *ngIf="system.isProvisioned">{{system.name}} ({{system.machineNo}}) &nbsp;&nbsp;&nbsp;&nbsp;CONFIGURED</span>
                              <span *ngIf="!system.isProvisioned">{{system.name}} ({{system.machineNo}})</span>
                            </mat-option>
                          </mat-autocomplete>                          
                          <mat-error>{{ getSystemIdErrorMessage(j) }}</mat-error>                        
                      </mat-form-field>
                      <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/5">
                        <label for="IPAddress">
                          <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                          IP Address
                          <custom-tooltip text="The IP address of the database on the device connected to stratus."></custom-tooltip>
                        </label>
                        <input type="text" matInput appNoHtmlTags trim id="{{'IPAddress'+j}}" formControlName="ipaddress" 
                          tabindex="0"  pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$" >
                        <mat-error *ngIf="systemFormArray.controls[j].get('ipaddress').errors?.required">IP Address required</mat-error>
                        <mat-error *ngIf="systemFormArray.controls[j].get('ipaddress').errors?.pattern"> IP Address is invalid</mat-error>
                        <mat-error *ngIf="systemFormArray.controls[j].get('ipaddress').errors?.duplicateIp">IP Address is duplicate</mat-error>
                      </mat-form-field>
                      <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/5">
                        <label for="User" (click)="$event.stopPropagation()">
                          <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                          Database Username
                          <custom-tooltip text="Username for the device database."></custom-tooltip>
                        </label>
                        <mat-select id="{{'User'+j}}" formControlName="user" tabindex="0">
                          <ng-container *ngFor="let type of deploymentUserList">
                            <mat-option *ngIf="item.title == type.deploymentType" [value]="type.userName">
                              {{ type.userName }}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                        <mat-error
                          *ngIf="systemFormArray.controls[j].get('user').errors?.required">Database Username required</mat-error>
                      </mat-form-field>
                      <div class="trash flex justify-end content-stretch items-stretch xs:basis-[14%] gt-xs:basis-[14%]">
                        <button class="btn-transparent btn_icon" id="{{'Delete'+j}}" type="button" 
                          tabindex="0" aria-label="trash" (click)="removeSystem(item?.title,j)">
                          <span class="svgicon icon_trash"></span>
                        </button>
                      </div>
                    </div>
                    <hr>                  
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions class="flex justify-end content-end items-end flex-row">
      <button mat-button class="btn-default btn-md" [mat-dialog-close]="true" id="cancel" tabindex="0" 
        type="button">Cancel</button>
      <button mat-button type="button" class="btn-primary btn-md" id="Done" tabindex="0" 
       (click)="save()">
        Configure
      </button>
    </mat-dialog-actions>
  </form>
</div>
