import { AfterViewInit, Component, ElementRef, HostListener, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import { Packager, Provisioned } from '../../models/provisioned.model';
import { NoDataRow, TableGroup } from 'src/app/shared/models/ui/table-group';
import { DeviceConfigModel } from '../../models/iotDeviceDetails.model';
import * as constants from 'src/app/config/app-constants';
import { AddNewSystemDialogComponent } from '../add-new-system-dialog/add-new-system-dialog.component';
import { SystemConfigurationDialogComponent } from '../system-configuration/system-configuration-dialog.component';
import { SearchBarComponent } from 'src/app/shared/components/search-bar/search-bar.component';
import { interpolateZoom } from 'd3';
import { Asset } from 'src/app/external-user/inventory/models/asset';
import { AssetService } from 'src/app/external-user/inventory/services/asset.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { getProvision } from 'src/app/store/selectors/fleet-manager.selector';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { getLogOptions } from '../../models/getLogOptions.model';
import { FleetManagementService } from '../../services/fleet-management.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastPanelType } from 'src/app/config/app-constants';
import { EdgeApplianceDetailsComponent } from '../edge-appliance-details/edge-appliance-details.component';

interface TableRow extends TableGroup, NoDataRow, Packager {
  index?: number;
}
// basic select
interface SortItemMdl {
  active: string;
  fieldDispName: string;
  sortDirection: string;
  direction: string;
}

interface KeyValue {
  Key: string;
  Value: string;
}

@Component({
  selector: 'app-provisioned',
  templateUrl: './provisioned.component.html',
  styleUrls: ['./provisioned.component.scss']
})

export class ProvisionedComponent implements AfterViewInit {
  @Input() organizations: any[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(SearchBarComponent, { static: true }) childComponent: SearchBarComponent;  
  filterText = '';
  displayedColumns: string[] = ['name', 'id', 'status', 'heartbeat', 'actions'];
  provisioned: any;
  selectedOrgId: string = '';
  selectedStatusId: string = '';
  provisionedList = new MatTableDataSource();
  packagerList = new MatTableDataSource<TableRow>([]);
  currentOrgElement: Provisioned;
  currentOrgHeader: string;
  provisionedListData: Provisioned[] = [];
  statusList: KeyValue[] = [];
  orgDDList: KeyValue[] = [];
  downloadingLogs = false;
  activeAssets: Asset[];
  windowWidth = window.innerWidth;
  accordionCollapsed = false;

  @HostListener('window:resize')
  onResize() {
    this.windowWidth = window.innerWidth;
    this.calculateOffset();
  }

  offsetFromBottom: number;

  private calculateOffset() {
    const windowHeight = window.innerHeight;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    // Set the offset value based on your calculation
    this.offsetFromBottom = windowHeight - scrollTop - 245;
  }

  constructor(public dialog: MatDialog, 
        public store: Store<State>,
        public fleetManagementService: FleetManagementService,
        public assetService: AssetService,
        public toastService: ToastService,
        private ngxLoader: NgxUiLoaderService) {
        this.loadProvisionedData();
  }

  ngOnInit() {
    this.calculateOffset();
    this.bindStatusDropdown();
    this.bindOrgDropdown();
  }

  ngAfterViewInit() {
  }

  loadProvisionedData() {
    this.store.select(getProvision).subscribe(res => {
      if (res) {
        if (res) {
          this.assetService.getActiveAssetsByType("5")
            .subscribe((response) => {
              this.activeAssets = response;
              res.forEach(data => {
                data.packagerSystemDTOs.forEach(system => {
                  system.systemFriendlyName = this.getSystemFriendlyName(system.systemId);
                });
              });
            }, (error) => {
              this.toastService.openToast('Error while getting assets!', constants.ToastPanelType.error);
            });

          this.provisioned = res.sort((a, b) => {
            if (a.organizationUI?.toLowerCase() > b.organizationUI?.toLowerCase())
              return 1;
            if (a.organizationUI?.toLowerCase() < b.organizationUI?.toLowerCase())
              return -1;
            return 0;
          });

          this.provisionedListData = this.provisioned;
          this.provisionedList.data = this.provisionedListData;
        }
      }
    });
  }

  toggleRow(element: Provisioned) {
    this.packagerList.data = element.packagerSystemDTOs;
    this.currentOrgElement = element;
    this.currentOrgHeader = element.customerId + "- [" + element.edgeDeviceId + "]";
    this.currentOrgElement.enabled = true;
  }

  applyRowClass(row: any): boolean {
    return row && row.status !== 'Online';
  }

  applyColumnClass(element: any): boolean {
    return element && element.status !== 'Online';
  }

  bindStatusDropdown() {
    this.statusList = [{ Key: "all", Value: "All Statuses" }];
    const uniqueStatusIds = new Set();
    this.provisionedListData.forEach((t) => {
      if (!uniqueStatusIds.has(t.connectionState)) {
        this.statusList.push({ Key: t.connectionState.toString(), Value: t.connectionState });
        uniqueStatusIds.add(t.connectionState);
        t.packagerSystemDTOs.forEach((t) => {
          if (!uniqueStatusIds.has(t.status)) {
            this.statusList.push({ Key: t.status.toString(), Value: t.status });
            uniqueStatusIds.add(t.status);
          }
        });
      }
    });
    // Sort the statusList alphabetically based on the 'Value' (except "All Statuses")
    this.statusList.sort((a, b) => {
      if (a.Value === "All Statuses") return -1; // "All Statuses" comes first
      if (b.Value === "All Statuses") return 1; // "All Statuses" comes first
      return a.Value.localeCompare(b.Value); // Sort other options alphabetically
    });

    this.selectedStatusId = this.statusList[0].Key;
  }

  bindOrgDropdown() {
    this.orgDDList = [{ Key: "all", Value: "All Organizations" }];
    const uniqueCustomerIds = new Set();
    this.provisionedListData.forEach((t) => {
      if (!uniqueCustomerIds.has(t.customerId)) {
        this.orgDDList.push({ Key: t.customerId.toString(), Value: t.customerId });
        uniqueCustomerIds.add(t.customerId);
      }
    });
    this.selectedOrgId = this.orgDDList[0]?.Key;
  }

  filterChange() {
    this.filterData(this.filterText);
  }

  filterData(filter) {
    this.filterText = filter?.toLowerCase();
    let filterLowerCase = this.filterText;
    this.provisionedListData = [...this.provisioned];
    let filteredData = [...this.provisionedListData];
    let selectedStatus = this.selectedStatusId?.toLowerCase();
    if (filterLowerCase != '' || selectedStatus != "all" || this.selectedOrgId != "all") {
      if (this.selectedOrgId != "all") {
        filteredData = filteredData?.filter((item) => (
          this.selectedOrgId == "all" || item.customerId?.toString() == this.selectedOrgId?.toString()
        ));
      }
      if (selectedStatus != "all") {
        filteredData = filteredData?.map((item) => {

          const filteredSystems = item?.packagerSystemDTOs?.filter((child) => (
            selectedStatus != "all" && ((item?.connectionState?.toLowerCase().includes(selectedStatus) ||
             child?.status?.toLowerCase().includes(selectedStatus) || child?.status?.toLowerCase().includes(selectedStatus)))
          ));
          return { ...item, packagerSystemDTOs: filteredSystems };
        })?.filter(item => item !== null);
      }
      
      if (filterLowerCase != "") {
        filteredData = filteredData.map((item) => {
          const filteredSystems = item.packagerSystemDTOs?.filter((child) => (
            (filterLowerCase !== '' && (
              child.systemFriendlyName?.toLowerCase().includes(filterLowerCase) ||
              (child.systemId != null && child.systemId.toLowerCase().includes(filterLowerCase))
              || (item.edgeDeviceId?.toLowerCase().includes(filterLowerCase) || item.edgeDeviceFriendlyName?.toLowerCase().includes(filterLowerCase)  
              || item.organizationUI?.toLowerCase().includes(filterLowerCase))
            ))
          ));
          return { ...item, packagerSystemDTOs: filteredSystems };
        }).filter(item => item !== null);
      }
      filteredData = filteredData?.filter((item) => (
        (
          item?.packagerSystemDTOs?.length > 0)
      ));
    }
    this.provisionedListData = filteredData;
    this.provisionedList.data = this.provisionedListData;
  }

  compareAsc(a, b) {
    if (a && b) {
      if (a?.toLowerCase() > b?.toLowerCase()) { return 1; }
      if (a?.toLowerCase() < b?.toLowerCase()) { return -1; }
    }
    return 0;
  }

  addnewSystemDialog(deviceConfig: Provisioned) {
      const dialogRef: MatDialogRef<AddNewSystemDialogComponent> = 
      this.dialog.open(AddNewSystemDialogComponent,
        {
          disableClose: true,
          backdropClass: 'lgDialog',
          data: { provisionedDeviceConfig: deviceConfig, organizations: this.organizations, activeAssets: this.activeAssets }
        });

      dialogRef.afterClosed().subscribe((result: any) => {
       if (result !== true) {
        const loadingKey = 'AddNewSystemClosed';
        this.ngxLoader.start(loadingKey);
        setTimeout(() => {
          this.filterChange();
          this.ngxLoader.stop(loadingKey);
        }, 1000);
      }
      });
  }

  showEdgeApplianceDetails(deviceConfig: Provisioned) {    
    const dialogRef: MatDialogRef<EdgeApplianceDetailsComponent> = 
    this.dialog.open(EdgeApplianceDetailsComponent,
    {       
      disableClose: true
    });
    let instance = dialogRef.componentInstance;
    instance.parentCompData = deviceConfig;

    dialogRef.afterClosed().subscribe(() => {
       setTimeout(() => {
         this.filterChange();     
       }, 1000);
     });
  }

  restartModules(deviceId) {
    const ngxLoaderKey = 'restartModules';
    this.ngxLoader.start(ngxLoaderKey);
    this.fleetManagementService.getRestartModules(deviceId).subscribe(
      (result) => {
        this.ngxLoader.stop(ngxLoaderKey);
        console.log(result);
      },
      (error) => {
        this.toastService.stopLoadingAndShowError('error',
          'An error occurred while restarting modules', ngxLoaderKey);
      });
  }

  restartDevice(deviceId) {
    const ngxLoaderKey = 'restartDevice';
    this.ngxLoader.start(ngxLoaderKey);
    var device = this.provisionedListData.find(dev => dev.edgeDeviceId == deviceId);
    
    this.fleetManagementService.getRestartDevice(deviceId).subscribe(
      (result) => {
        this.ngxLoader.stop(ngxLoaderKey);
        console.log(result);
        this.toastService.openToast('Restarting Edge Appliance: ' + device.edgeDeviceFriendlyName + ' - ' + deviceId, ToastPanelType.done);
      },
      (error) => {
        this.toastService.stopLoadingAndShowError('error',
          'Unable to complete system restart: ' + device.edgeDeviceFriendlyName + ' - ' + deviceId, ngxLoaderKey);
      });
  }

  downloadLogs(deviceId) {
    if (this.downloadingLogs) { return; }
    this.downloadingLogs = true;
    const ngxLoaderKey = 'downloadLogs';
    this.ngxLoader.start(ngxLoaderKey);
    const logOptions: getLogOptions = { tail: '500' };
    this.fleetManagementService.getDeviceLog(deviceId, logOptions).subscribe((result: any) => {
      if (result.size == 0) {
        this.toastService.stopLoadingAndShowError('error',
          'Log file contains no information', ngxLoaderKey);
        this.downloadingLogs = false;
      }
      else {
        this.ngxLoader.stop(ngxLoaderKey);
        const blob = new Blob([result], { type: result.type });
        const blobUrl = window.URL.createObjectURL(blob);
        const fileAnchor = document.createElement('a');
        const filename = `${deviceId}_Logs.zip`;
        fileAnchor.download = decodeURIComponent(filename);
        fileAnchor.href = blobUrl;
        fileAnchor.click();
        this.downloadingLogs = false;
      }
    }, (error) => {
      console.log(error);
      let msgtext = 'An error has occurred';
      if (error.status == 504) {
        msgtext = 'A time out has occurred pulling logs';
      }
      else if (error.status == 500) {
        msgtext = 'Device not responding';
      }
      this.toastService.stopLoadingAndShowError('error',
        msgtext, ngxLoaderKey);
      this.downloadingLogs = false;
    });
  }

  getSystemFriendlyName(systemId: string): string {
    const system = this.activeAssets.find(system => system.machineNo === systemId);
    return system ? `${system.name}` : '';
  }

  toggleAccordion(){
    if(this.accordionCollapsed){
      this.accordionCollapsed = false;
      this.accordion.openAll();
    }
    else{
      this.accordionCollapsed = true;
      this.accordion.closeAll();
    }
  }
}