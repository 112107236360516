<app-card title="Customer Management">
  <section>
    <div class="sticky_search">
      <div class="p-16 bg-white">
        <div class="flex justify-between content-center items-center lg:flex-row md:flex-row sm:flex-row xs:flex-col">
          <app-search-bar (filterChange)="filterCustomers($event)" inputId="searchcustomers-input" minLengthToSearch="1" 
            placeholder="Search Stratus Customer List" 
            class="searchcustomers xs:basis-full sm:basis-[300px] md:basis-[300px] lg:basis-[300px]" id="search_customers"></app-search-bar>

          <div class="add_btns xs:basis-full sm:basis-1/2 md:basis-1/2 lg:basis-1/2">
            <div class="flex justify-end content-stretch items-stretch flex-row gap-4">
              <div class="xs:basis-[63%]">
                <button id="add_organization" *ngIf="enableAddNewOrg" class="btn-default btn-sm" type="button" 
                  (click)="openAddOrganization()">
                  Add New Organization
                </button>
              </div>
              <div class="xs:basis-[37%]">
                <button id="add_site" *ngIf="enableAddNewSite" class="btn-default btn-sm add_site" type="button" 
                  (click)="openAddSite(true, '', '')">
                  Add New Site
                </button> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="setup_scroll">
      <table mat-table [dataSource]="dataOrgList" multiTemplateDataRows class="customerlist" tabindex="0" role="table">

        <ng-container matColumnDef="orgName">
          <th mat-header-cell *matHeaderCellDef> Organization </th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element.orgName  | noHtml | highlightMatched: filterText" [attr.aria-label]="'Organization Name ' + element.orgName"> </td>
        </ng-container>

        <ng-container matColumnDef="organizationId">
          <th mat-header-cell *matHeaderCellDef> Customer Number </th>
          <td mat-cell *matCellDef="let element"> </td>
        </ng-container>

        <ng-container matColumnDef="addsite">
          <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
          <td mat-cell *matCellDef="let element" class="add_site_btn">
            <button *ngIf="enableAddNewSite" id="add_site_secondary" class="btn-default btn-sm add_site" type="submit" 
              (click)="openAddSite(false, element.orgName,element.organizationId)" [attr.aria-label]="'Add New Site for organization' + element.orgName" tabindex="0">
              Add New Site
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="arrow">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" tabindex="-1" aria-hidden="true">
          </td>
        </ng-container>


        <!-- Template for details row -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedOrgColumnsList.length">
            <div class="row student-element-detail flex justify-start content-center items-center" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'" *ngFor="let site of element.sites" [routerLink]="['/customer-management', site.organizationId]">
              <div class="orgw">
                <span [innerHTML]="site.name | noHtml | highlightMatched: filterText" id="{{site.name}}" tabindex="0" [attr.aria-label]="'Site Name' + site.name"  ></span>
              </div>
              <div class="cusnow">
                <span [innerHTML]="(site.customerNo?site.customerNo:'') | noHtml | highlightMatched: filterText" 
                  id="{{site.customerNo}}" tabindex="0" [attr.aria-label]="'Customer Number' + site.customerNo"></span>
              </div>
              <div class="arrw">
                <button class="btn-transparent arw_cm" [routerLink]="['/customer-management', site.organizationId]" [attr.aria-label]="'Detailed information on' + site.name" id="{{site.name}}_arrow" type="button" tabindex="0" title="Detailed information {{site.name}}">
                  <app-icon icon="arwrgt" class="arrow_right"></app-icon>
                </button>
              </div> 
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedOrgColumnsList;"></tr>
        <!--sticky: true-->
        <tr mat-row *matRowDef="let element; columns: displayedOrgColumnsList;" tabindex="0" class="student-element-row" 
          [class.student-expanded-row]="element.isExpanded" id="OrgRow"></tr>
        <!-- Extra row to show detail content column -->
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row" id="SiteRow" ></tr>
      </table>
    </div>
    <div class="setup_scroll_mini">
      <ng-container>
        <div *ngFor="let items of organizations">
          <ul class="org_site_table">
            <li class="organization_row flex justify-start content-center items-center flex-row">
              <div class="basis-[58%]">
                <p style="font-weight: 700;" [innerHTML]="items.orgName | noHtml | highlightMatched: filterText"></p>
              </div>
              <div class="org_row_sitebtn flex flex-row justify-end content-center items-center h-full basis-[37%]">
                <button class="btn-default btn-sm add_site" type="submit">
                  Add New Site
                </button>
              </div>
              <div class="org_arrow flex flex-row justify-end content-center items-center h-full basis-[5%]">
              </div>
            </li>
            <li class="site_row" *ngIf="items.sites && items.sites?.length">
              <div *ngFor="let site of items.sites" [routerLink]="['/customer-management', site.organizationId]">
                <div class="basis-[52%]">
                  <p>Organization</p>
                  <span [innerHTML]="site.name | noHtml | highlightMatched: filterText"></span>
                </div>
                <div class="basis-[42%]">
                  <p>Customer Number</p>
                  <span [innerHTML]="(site.customerNo?site.customerNo:'') | noHtml | highlightMatched: filterText"></span>
                </div>
                <div class="org_arrow flex justify-end content-center items-center basis-[6%]">
                  <img src="../../../../../assets/img/icons/ico.16.arwrgt.svg" alt="Arrow Right" 
                    [routerLink]="['/customer-management', site.organizationId]">
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>

  </section>
</app-card>
