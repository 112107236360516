<form>
  <section>
    <h3 class="section-label bg-transparent text_normal font_normal" tabindex="0" #onboarding>
      Fill in the details for each of your Parata automation system (ATP, PASS, Max, etc…)
    </h3>
    <form [formGroup]='formGroup' #formDirective="ngForm" (submit)="submit()" class="adding_asset flex xl:flex-row lg:flex-row lt-lg:flex-row lt-lg:flex-wrap gt-md:justify-start gt-md:items-center gt-md:content-center xs:justify-start xs:items-end xs:content-end gt-xs:gap-4">
      <mat-form-field floatLabel="never" class="xl:basis-[24%] lg:basis-[24%] md:basis-[200px] sm:basis-[200px] xs:basis-full md:order-1 sm:order-1">
        <label for="FriendlyName">
          <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
          <span>Friendly Name</span>
        </label>
        <input matInput formControlName="FriendlyName" appNoHtmlTags id="FriendlyName" required maxlength="51" (blur)="FriendlyNameUniqueValidation()">
        <mat-error *ngIf="formGroup.get('FriendlyName').hasError('required')">Friendly Name required</mat-error>        
      </mat-form-field>
      <mat-form-field floatLabel="never" class="xl:basis-[24%] lg:basis-[24%] md:basis-[200px] sm:basis-[200px] xs:basis-full md:order-2 sm:order-2">
        <label for="MachineType">
          <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
          <span>System Type</span>
        </label>
        <mat-select formControlName="MachineType" id="MachineType" required>
          <mat-option *ngFor="let machType of ltMachineTypes;" [id]="machType?.name" [value]="machType?.machineTypeId" (click)="getInnerText(machType?.name)">
            {{machType?.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('MachineType').hasError('required')">System Type required</mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="never" class="serialnum xl:basis-[24%] lg:basis-[24%] md:basis-[200px] sm:basis-[200px] xs:basis-full sm:order-4">
        <label for="SerialNumber">
          <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
          <span>Serial Number</span>
        </label>
        <input matInput formControlName="SerialNumber" appNoHtmlTags id="SerialNumber" maxlength="16" (blur)="SerialNoUniqueValidation()" required>
        <mat-error *ngIf="formGroup.get('SerialNumber').hasError('required')">Serial Number required</mat-error>        
      </mat-form-field>
      <mat-form-field floatLabel="center" class="scriptre xl:basis-[15%] lg:basis-[15%] md:basis-[200px] sm:basis-[18%] xs:basis-4/5 sm:order-5">
        <label>Script Routing Enabled</label>
        <input matInput class="invisible" formControlName="ScriptRouting">
      </mat-form-field>
      <div class="cs_control_btn flex xl:basis-[13%] lg:basis-[13%] md:basis-[44%] sm:basis-[38%] xs:basis-full md:flex md:flex-row sm:flex sm:flex-row md:order-3 sm:order-3 justify-end items-center content-center">
        <a id="AdminClearAll" class="btn-link" (click)="reset()" (keydown.enter)="reset()" tabindex="0" aria-label="Clear all">Clear All ></a>
        <button class="btn-default btn-sm" type="submit" id="AddSystem" tabindex="0">
          Add
        </button>
      </div>
    </form>

    <ul class="added_asset" *ngFor="let asset of pgAssets; let index = index">
      <li class="flex xl:flex-row lg:flex-row lt-lg:flex-row lt-lg:flex-wrap gt-md:justify-start gt-md:items-center gt-md:content-center xs:justify-start xs:items-end xs:content-end gt-xs:gap-4">
        <mat-form-field class="xl:basis-[24%] lg:basis-[24%] md:basis-[200px] sm:basis-[200px] xs:basis-full md:order-1 sm:order-1">
          <label class="xl:hidden lg:hidden md:hidden sm:block xs:block">
            <span>Friendly Name</span>
          </label>
          <input [id]="asset?.name + '_FriendlyName'"  matInput value="{{asset?.name}}" readonly tabindex="0"> 
        </mat-form-field>
        <mat-form-field class="xl:basis-[24%] lg:basis-[24%] md:basis-[200px] sm:basis-[200px] xs:basis-full md:order-2 sm:order-2">
          <label class="xl:hidden lg:hidden md:hidden sm:block xs:block">
            <span>System Type</span>
          </label>
          <input [id]="asset?.name + '_SystemType'"  matInput value="{{asset?.configurations?.machineType}}" readonly tabindex="0">
        </mat-form-field>
        <mat-form-field class="serialnum_v xl:basis-[24%] lg:basis-[24%] md:basis-[200px] sm:basis-[200px] xs:basis-full md:order-4 sm:order-4">
          <label class="xl:hidden lg:hidden md:hidden sm:block xs:block">
            <span>Serial Number</span>
          </label>
          <input [id]="asset?.name + '_SerialNumber'"  matInput value="{{asset?.machineNo}}" readonly tabindex="0">
        </mat-form-field>
        <div class="sre sre_v sm:flex sm:flex-col xs:flex xs:flex-col xl:basis-[15%] lg:basis-[15%] sm:basis-[24%] xs:basis-[65%] md:order-5 sm:order-5">
          <label class="xl:hidden lg:hidden md:hidden sm:block xs:block">Script Routing Enabled</label>
            <mat-slide-toggle [id]="asset?.name + '_ScriptRoutingEnabled'" [checked]="asset?.isregistered" (change)="registerDevicetoPSIS($event,asset)" tabindex="0">
            </mat-slide-toggle>
        </div>
        <div class="trash flex flex-row justify-end items-center content-center xl:basis-[13%] lg:basis-[13%] md:basis-[44%] sm:basis-[38%] md:order-3 sm:order-3 xs:basis-full">
          <button [id]="asset?.name + '_Delete'" class="btn-transparent btn_icon btn_trash" type="button" tabindex="0" aria-label="trash" (click)="deleteOrgAsset(asset)" (keypress.enter)="deleteOrgAsset(asset)">
            <span class="svgicon icon_trash"></span>
          </button>
        </div>
      </li>
    </ul>
  </section>

  <section class="text-right m-t-50 m-b-30 m-r-15">
  </section>
</form>
