import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { State } from '../reducers/fm-configuration.reducer';

export const getFleetConfigState = createFeatureSelector<State>('fleetConfig');

export const getFleetConfig = createSelector(
  getFleetConfigState,
  (state: State) => state.fleetConfig
);
