import { ImportWizardQuarantinedDrugListComponent } from './../import-wizard-quarantined-drug-list/import-wizard-quarantined-drug-list.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Drug } from '../../models/drug';
import { RpoToolService } from '../../services/rpo-tool.service';
import * as constants from 'src/app/config/app-constants';
import { Router } from '@angular/router';
import { routePaths } from 'src/app/config/app-constants';
@Component({
  selector: 'app-quarantined-drug-list',
  templateUrl: './quarantined-drug-list.component.html',
  styleUrls: ['./quarantined-drug-list.component.scss']
})
export class QuarantinedDrugListComponent implements OnInit {
  displayedColumns = ['name', 'ndc', 'countryCode', 'system', 'strength', 'manufacturer', 'priority', 'requestor', 'age','action'];
  selectedRow:any;
  @Input() searchText: string;
  @Input() countryId: number;
  dataSource= new MatTableDataSource<Drug>();
     //#region Pagination properties
     totalRows: number = 0;
     currentPage: number = 1;
     pageSize: number = 5;
     pageSizeOptions: number[] = [5, 10, 20, 40, 50];
     //#endregion
  constructor(
    private rpoService: RpoToolService,
    private dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private route: Router,
    private toastService: ToastService,) { }

  ngOnInit(): void {
   this.getDrugData();
  }
  redirectToDetailsPage(rowId:any){
    this.route.navigate([`/${routePaths.DRUG_MANAGEMENT}/drug-details`, rowId]);
  }


  handleInputChange(searchText:any, countryId:number){
    this.searchText = searchText;
    this.countryId = countryId;
    if(this.searchText.length>0 || this.countryId>=0){
        this.getDrugData();
    }
      
   
}

  getDrugData(){

    this.rpoService.getQuarantinedDrugata(
      this.searchText,
      this.countryId,
      this.currentPage,
      this.pageSize
    ).subscribe((result)=>{
     this.dataSource.data = result.data.drugs
     this.currentPage = result.data.currentPage
     this.totalRows = result.data.totalRows
   
    },
    (error: HttpErrorResponse) => {
      console.log(error);
      this.toastService.openToast(
        error.message,
        constants.ToastPanelType.error,
        2
      );
  
    })
  }


  openImportWizardQuanrantinedDrug() {

    const dialogRef = this.dialog.open(ImportWizardQuarantinedDrugListComponent, {
    backdropClass: ['lgDialog'],
    disableClose: true,
    data:{
      searchText: this.searchText,
      countryId:this.countryId
    }
    });

  }
}
