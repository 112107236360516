import { CreateDrug } from './../../models/create-drug';
import { FormControl, FormGroup, Validators,ValidatorFn,AbstractControl,ValidationErrors } from '@angular/forms';
import { DropDown, DropDownDosageForm, DropDownTwo } from './../../models/dropdown';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { RpoToolService } from '../../services/rpo-tool.service';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import * as constants from 'src/app/config/app-constants';
import { debounceTime } from 'rxjs';
@Component({
  selector: 'app-import-wizard-add-new-drug',
  templateUrl: './import-wizard-add-new-drug.component.html',
  styleUrls: ['./import-wizard-add-new-drug.component.scss']
})
export class ImportWizardAddNewDrugComponent implements OnInit {
  listRouteOfAdmistration: DropDown[] = [];
  listOfPackageType: DropDown[] = [];
  listOfPackageSize: DropDown[] = [];
  listOfCoating: DropDownTwo[] = [];
  listOfScoaring: DropDownTwo[] = [];
  listOfDrugStatus: DropDown[] = [];
  listOfPillType: DropDown[] = [];
  listOfSchedule: DropDown[] = [];
  listOfDosageForm: DropDownDosageForm[] = [];
  listOfNiosh: DropDown[] = [];
  listOfOtc: DropDownTwo[] = [];
  listofUnit: DropDown[] = [];
  listofManufacture: DropDown[] = [];
  createDrugModel: CreateDrug;
  listOfColor: DropDown[] = [];
  listOfClassificationShape: DropDown[] = [];
  countryList: DropDown[] = [];
  listOfDrugClassificationType: DropDown[] = [];
  searchText: string = '';
  isLoading: boolean = false;
  saveDrugForm: FormGroup;
  defaultImage: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHkAAAB5CAMAAAAqJH57AAAAMFBMVEX///+/v7+9vb26urrl5eXHx8fw8PDz8/PNzc3q6urCwsL5+fn8/Pzi4uLa2trW1tZO13xoAAACCUlEQVRoge3Z23aDIBAFUBlUEJH8/9/WS1UQSuMwpCvNnLd2me4KByXaNBwOh8N549iaycGthIrpsrKoGJZZ/nsZJGXghqwVZSa4IWeOuZ9Bsswyyyx/qGzHMbuBqyVbZ+Y7zkO9XB7N+muA4dVyt99gZf9a+dyowC/NsxOtrMWZ/FQ7SNYQK1tzwpAdbiVlsgnVZash/cdJRnvMfNjNRZSpA2o3rF+PeFDKjd5XVa5gtluPMImO4WXbbVcSkZtlt/17sqWUm2bSxnQuN8n9PiyJCal7xzgXQDwjpHdJdWnSdLYw7hiprGUAqKODc8eiSaGUWxAB3XlLPu4YoTwus+rRzjvlxIcJ5QcEM9oHsIgWH508fEvg1h+tDuWoY3TyMasb7S6nHF3dyeTplBZaXV0Blw1CkTyc14fRf5YiXdBrkSRK5Pl2cIxgOKugo7GO9mslcguwXyAGGUvRcIcdK5GX9QvrgPs7lEyCjhXI7TqiZqHjIqcig44VyNt5wkyrp+ALgpeP7ZBRqT4lT9rvWMF3jOc0P0HHCHaAd+J1DC0/1+ZL/I5hZeTzX49ByhYxy0u8r7xIGfvI2+sYTsaesvA6hpMn9Bss6crkoUXnmOg3fSrFMsv/RO7wqzgRd+NNmaB81Q533g7WCcsss8wyyyyzzDLLLLP8eXLNmIw89lWTkTkcDofD+SlfIsYv/LXKcNQAAAAASUVORK5CYII='
  frontImage: string = null;
  sideImage: string = null;
  backImage: string = null;
  topImage: string = null;

  packageType = new FormControl('', Validators.required);
  niosh = new FormControl('', Validators.required);
  routeOfAdministration = new FormControl('', Validators.required);
  unit = new FormControl('', Validators.required);
  manufacturer = new FormControl('', Validators.required)
  color = new FormControl('', Validators.required)
  packageSize = new FormControl('', Validators.required)
  dosageForm = new FormControl('', Validators.required)


  constructor(
    private rpoService: RpoToolService,
    public dialogRef: MatDialogRef<ImportWizardAddNewDrugComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {


  }
  positiveRealNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const isValid = /^[1-9]\d*(\.\d+)?$/.test(value) || /^[1-9]\d*(\.\d+)?(\s*\/\s*[1-9]\d*(\.\d+)?)*$/.test(value);
      return isValid ? null : { positiveRealNumber: true };
    };
  }



  ngOnInit(): void {
    this.getCoating();
    this.getSchedule();
    this.getClassificationShape();
    this.getOTC();
    this.getScoring();
    this.getCommonDrugStatus();
    this.getPillType();
    this.getNiosh();
    this.getCountryList();
    this.getDrugClassificationType();

    this.routeOfAdministration.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {
        this.getRouteOfAdministration(searchTerm);
      });


    this.unit.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {
        this.getUnit(searchTerm);
      });


    this.color.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {
        this.getColor(searchTerm);
      });



    this.dosageForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {

        this.getDosageForm(searchTerm);

      });


    this.manufacturer.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {

        this.getManufacture(searchTerm);

      });


    this.packageType.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {

        this.getPackageType(searchTerm);

      });





    this.saveDrugForm = new FormGroup({

      antibiotic: new FormControl('', Validators.required),
      barcode: new FormControl('', Validators.required),
      barcode2d: new FormControl('', Validators.required),
      classificationShape: new FormControl(''),
      coating: new FormControl('', Validators.required),
      color: this.color,
      diagonal: new FormControl(),
      dosageForm: this.dosageForm,
      drugId: new FormControl('', [Validators.required, Validators.maxLength(11)]),
      drugIdType: new FormControl('', Validators.required),
      drugStatus: new FormControl(''),
      drugClassificationType: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),

      genericName: new FormControl('', Validators.required),
      imprint1: new FormControl('', Validators.required),
      imprint2: new FormControl('', Validators.required),
      length: new FormControl(),
      manufacturer: this.manufacturer,
      niosh: this.niosh,
      otc: new FormControl('', Validators.required),
      packageSize: this.packageSize,
      packageType: this.packageType,
      pieceWeight: new FormControl(),
      pillType: new FormControl('', Validators.required),
      qtyClassified: new FormControl(''),
      qtyWeighted: new FormControl(''),
      routeOfAdministration: this.routeOfAdministration,
      schedule: new FormControl('', Validators.required),
      scoring: new FormControl('', Validators.required),
      storageConditions: new FormControl('', Validators.required),
      strength: new FormControl('', [Validators.required, this.positiveRealNumberValidator()]),
      thickness: new FormControl(),
      thirtyDramCapacity: new FormControl('', Validators.required),
      unit: this.unit,
      unitDose: new FormControl('', Validators.required),
      unitOfUse: new FormControl('', Validators.required),
      width: new FormControl(),
    });
  }
  percentage: number = 0;
  fielName: string = "";
  fieleSelect: boolean = false;
  backgroundcolor: string = "#053AB2 0% 0% no-repeat padding-box";
  selectedFile: File = null;
  responseMessage: String = "";
  responseMessageError: string = "";
  onFileDropped(event: any, imageNumber: number) {
    this.prepareFile(event, imageNumber);
  }

  onFileSelected(event: any, imageNumber: number) {
    this.selectedFile = <File>event.target.files[0];
    this.fielName = this.selectedFile.name;
    this.fieleSelect = true;
    this.prepareFile(event, imageNumber);
  }

  selectedFrontFileRemove() {
    this.frontImage = null

  }
  selectedBackFileRemove() {

    this.backImage = null;
    this.fielName = "";
    this.fieleSelect = false;
  }
  selectedSideFileRemove() {

    this.sideImage = null
    this.fielName = "";
    this.fieleSelect = false;
  }
  selectedTopFileRemove() {

    this.topImage = null
    this.fielName = "";
    this.fieleSelect = false;
  }

  prepareFile(event: any, imageNumber: number) {
    this.fielName = event.target.files[0].name;
    this.fieleSelect = true;
    const reader = new FileReader();
    const fileExtension = this.fielName.split('.').pop()?.toLowerCase();

    if (!fileExtension || !['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      this.toastService.openToast('Invalid file format. Please select an image file (jpg, jpeg, png, gif)',
        constants.ToastPanelType.error,
        2
      );
      return;
    }
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (readerEvent: any) => {
      const splitVal = reader.result.toString().split(",");
      if (splitVal.length > 0) {

        if (imageNumber === 1)
          this.frontImage = `${splitVal[1]}`;
        else if (imageNumber === 2)
          this.backImage = `${splitVal[1]}`;
        else if (imageNumber === 3)
          this.sideImage = `${splitVal[1]}`;
        else if (imageNumber === 4)
          this.topImage = `${splitVal[1]}`;
      }
    };
  }
  getBase64ImageSrc(photo) {
    if (photo == null)
      return null;
    else {
      return 'data:image/jpeg;base64,' + photo;
    }

  }



  displayFnPacakgeType(value?: any) {
    return value ? this.listOfPackageType.find(_ => _.value === value).label : undefined;
  }

  displayFnColor(value?: any) {
    return value ? this.listOfColor.find(_ => _.value === value).label : undefined;
  }
  displayFnRoutOfAddministration(value?: any) {
    return value ? this.listRouteOfAdmistration.find(_ => _.value === value).label : undefined;
  }
  displayFnDosageForm(value?: any) {
    if (value) {
      let data = this.listOfDosageForm.find(_ => _.id === value);
      this.saveDrugForm.controls.unitOfUse.setValue(data.unitOfUse === "0" ? 'No' : 'Yes');
      this.saveDrugForm.controls.unitDose.setValue(data.unitDose === "1" ? 'No' : 'Yes');
      return data.description;
    }
    else return undefined;
  }
  displayFnUnit(value?: any) {
    return value ? this.listofUnit.find(_ => _.value === value).label : undefined;
  }
  displayFnManufacturer(value?: any) {
    return value ? this.listofManufacture.find(_ => _.value === value).label : undefined;
  }

  closeDialog(response: boolean) {
    this.dialogRef.close({ event: 'close', data: response })
  }
  getCoating() {
    this.ngxLoader.start();
    this.rpoService
      .getCoating()
      .subscribe(
        (result) => {
          this.listOfCoating = result.data;
          this.ngxLoader.stop();

        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getClassificationShape() {
    this.ngxLoader.start();
    this.rpoService
      .getClassificationShape()
      .subscribe(
        (result) => {
          this.listOfClassificationShape = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getSchedule() {
    this.ngxLoader.start();
    this.rpoService
      .getSchedule()
      .subscribe(
        (result) => {
          this.listOfSchedule = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getColor(search) {
    this.isLoading = true;
    this.rpoService
      .getColor(search)
      .subscribe(
        (result) => {
          this.listOfColor = result.data;
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getUnit(search) {
    this.isLoading = true;
    this.rpoService
      .getUnit(search)
      .subscribe(
        (result) => {
          this.listofUnit = result.data;
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getManufacture(search) {
    this.isLoading = true;
    this.rpoService
      .getManufacture(search)
      .subscribe(
        (result) => {
          this.listofManufacture = result.data;
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }


  getScoring() {
    this.ngxLoader.start();
    this.rpoService
      .getScoring()
      .subscribe(
        (result) => {
          this.listOfScoaring = result.data;
          this.ngxLoader.stop();

        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getDosageForm(search) {
    this.isLoading = true;
    this.rpoService
      .getDosageForm(search)
      .subscribe(
        (result) => {
          this.listOfDosageForm = result.data;
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getOTC() {
    this.ngxLoader.start();
    this.rpoService
      .getOtc()
      .subscribe(
        (result) => {
          this.listOfOtc = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getPillType() {
    this.ngxLoader.start();
    this.rpoService
      .getPillType()
      .subscribe(
        (result) => {
          this.listOfPillType = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getCommonDrugStatus() {
    this.ngxLoader.start();
    this.rpoService
      .getCommonDrugStatus()
      .subscribe(
        (result) => {
          this.listOfDrugStatus = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getRouteOfAdministration(searchText) {
    this.isLoading = true;
    this.rpoService
      .getRouteOfAdministration(searchText)
      .subscribe(
        (result) => {

          this.listRouteOfAdmistration = result.data;
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getNiosh() {
    this.rpoService
      .getNiosh()
      .subscribe(
        (result) => {
          this.listOfNiosh = result.data;
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getPackageType(value) {
    this.isLoading = true;
    this.rpoService
      .getPackageType(value)
      .subscribe(
        (result) => {
          this.listOfPackageType = result.data;
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getCountryList() {
    this.ngxLoader.start();
    this.rpoService
      .getCountryList()
      .subscribe(
        (result) => {
          this.countryList = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getDrugClassificationType() {
    this.ngxLoader.start();
    this.rpoService
      .getDrugClassificationType()
      .subscribe(
        (result) => {
          this.listOfDrugClassificationType = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }


  save() {
    // if(!this.saveDrugForm.valid){
    //   return
    // }
    const formValue = this.saveDrugForm.value;
    this.createDrugModel = {
      antibiotic: formValue.antibiotic,
      barcode: formValue.barcode,
      barcode2d: formValue.barcode2d,
      classificationShapeId: formValue.classificationShape,
      coating: formValue.coating,
      color: formValue.color,
      diagonal: formValue.diagonal,
      dosageForm: formValue.dosageForm,
      drugId: formValue.drugId,
      drugIdType: formValue.drugIdType,
      drugStatus: formValue.drugStatus == null ? 0 : formValue.drugStatus,
      genericName: formValue.genericName,
      imprint1: formValue.imprint1,
      imprint2: formValue.imprint2,
      length: formValue.length,
      manufacturer: formValue.manufacturer,
      niosh: formValue.niosh,
      otc: formValue.otc,
      packageSize: formValue.packageSize,
      packageType: formValue.packageType,
      pieceWeight: formValue.pieceWeight,
      pillType: formValue.pillType,
      qtyClassified: formValue.qtyClassified,
      qtyWeighted: formValue.qtyWeighted,
      routeOfAdministration: formValue.routeOfAdministration,
      schedule: formValue.schedule,
      scoring: formValue.scoring,
      storageConditions: formValue.storageConditions,
      strength: formValue.strength,
      thickness: formValue.thickness,
      thirtyDramCapacity: formValue.thirtyDramCapacity,
      unit: formValue.unit,
      unitDose: formValue.unitDose,
      unitOfUse: formValue.unitOfUse,
      width: formValue.width,
      countryCodeId: formValue.country,
      drugNumberTypeId: formValue.drugClassificationType,
      images: [{ serialImage: this.frontImage, viewId: 1 }, { serialImage: this.backImage, viewId: 4 }, { serialImage: this.sideImage, viewId: 2 }, { serialImage: this.topImage, viewId: 3 }]
    };
    this.ngxLoader.start();
    this.rpoService
      .saveDrug(this.createDrugModel)
      .subscribe(
        (result) => {
          this.ngxLoader.stop();
          if (result.statusCode === 200) {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.done,
              2
            );
            this.clearFormData();
          } else {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.error,
              2
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  clearFormData() {
    this.saveDrugForm.reset();
    this.closeDialog(true);
  }

}
