import { CustomerAddDrugCellLocationSynmed, CustomerDrugCellLocationSynmed, DrugInfoSynmed, EtiquetteExport, EtiquetteListExport, ListOfAddDrugLocationSynmed, ListOfDrugLocationSynmed, NotOralSolidList, SynmedExport } from '../../models/drugInfo-synmed';

import { ExportFileInfo } from './../../models/export-file-info';
import { ExcelExportService } from './../../services/excel-export-service.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonEntity, CustomerDrugInfo, DrugCellLog, DrugSystem } from '../../models/customer-drug-info';
import { IMToolService } from '../../services/im-tool.service';
import { ImToolAtpService } from '../../services/im-tool-atp.service';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
import * as XLSX from "xlsx";
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from "src/app/config/app-constants";
import { ImportWizardCddbComponent } from '../import-wizard-cddb/import-wizard-cddb.component';
import { ImportWizardComponent } from '../import-wizard/import-wizard.component';
import { ImportWizardDrugQualifierComponent } from '../import-wizard-drug-qualifier/import-wizard-drug-qualifier.component';
import { ImportWizardDcrDrugComponent } from '../import-wizard-dcr-drug/import-wizard-dcr-drug.component';
import { ImportWizardDrugMovingComponent } from '../import-wizard-drug-moving/import-wizard-drug-moving.component';
import { MoveDrug } from '../../models/move-drugs';

@Component({
  selector: 'app-synmed-drug-list',
  templateUrl: './synmed-drug-list.component.html',
  styleUrls: ['./synmed-drug-list.component.scss']
})
export class SynmedDrugListComponent implements OnInit {

  analysisDrugList: DrugInfoSynmed[] = [];
  newDrugList: DrugInfoSynmed[] = [];
  excludedDrugList: DrugInfoSynmed[] = [];
  notOralDrugList: NotOralSolidList[] = [];
  synmedExportList: SynmedExport[] = [];
  synmedErrorExportList: SynmedExport[] = [];
  synmedGroupDrugsExportList: SynmedExport[] = [];
  synmedNonCertifiedExportList: SynmedExport[] = [];
  etiquetteExportList: EtiquetteExport[] = [];
  etiquetteList: EtiquetteListExport[] = [];
  tDrugDataExportList: string[] = [];

  fileInfo: ExportFileInfo;
  searchText: string = '';
  drugList: CustomerDrugInfo;
  drugSystemList: DrugSystem[] = [];
  celLogList: DrugCellLog[] = [];
  drugCellSize: CommonEntity[] = [];
  //drugCellLocationUpdateList: ListOfDrugLocationSynmed;
  drugCellLocationRemoveList: ListOfDrugLocationSynmed;
  drugCellLocationAddList: ListOfAddDrugLocationSynmed;
  drugCellLocationList: CustomerDrugCellLocationSynmed[] = [];
  addCellLocList: CustomerAddDrugCellLocationSynmed[] = [];
  //#region  Table datasourse
  dataSource = new MatTableDataSource<DrugInfoSynmed>();
  dataSourceOral = new MatTableDataSource<DrugInfoSynmed>();
  dataSourceExcluded = new MatTableDataSource<DrugInfoSynmed>();
  dataSourceNotAbailable = new MatTableDataSource<DrugInfoSynmed>();
  //#endregion
  @ViewChild(MatTable, { static: true }) drugTabletable: MatTable<any>;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("table", { read: MatSort, static: true }) sort: MatSort;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild("table3", { read: MatSort, static: true }) sort3: MatSort;
  @ViewChild("table4", { read: MatSort, static: true }) sort4: MatSort;

  @ViewChild("table5") table5: ElementRef;
  @ViewChild("table6") table6: ElementRef;
  @Output() sortChange: EventEmitter<MatSort> = new EventEmitter<MatSort>();

  selectedDrugSystem: number = 1;
  xmlAnalysisFormatData: any;
  orderOrUsage: string = '# of Orders';
  phllDispLable: string = 'Pills Disp';
  analysisData: number;
  ndcOfMultippleLocation = new Array<string>();
  // Selected Row Color Change key
  selectedRow;

  isShowCellLocOnMouseHover: boolean = false;
  deviceId: number;
  deviceTypeId:number;
  customerName: string = "";
  targetInstallDate: string = "";
  subDeviceTypeName: string = "";
  deviceName: string = "";


  isStrengthAnalysisShow: boolean = false;
  isMnemonicAnalysisShow: boolean = false;
  islidTypeShow: boolean = false;
  isStrengthOralShow: boolean = false;
  isStrengthNewShow: boolean = false;
  isStrengthExcludeShow: boolean = false;
  isFragileShow: boolean = false;
  isVolcanicShow: boolean = false;

  isTdrugOpen: boolean = false;
  //#region variables
  remainingLargeLocation: number = 0;
  remainingSmallLocation: number = 0;
  remainingLargeContLargePipetteTrue: number = 0;
  remainingLargeContLargePipetteFalse: number = 0;
  remainingLargeContSmallPipetteTrue: number = 0;
  remainingLargeContSmallPipetteFalse: number = 0;
  remainingLargeContBothPipetteTrue: number = 0;
  remainingLargeContBothPipetteFalse: number = 0;
  remainingSmallContSmallPipetteTrue: number = 0;
  remainingSmallContSmallPipetteFalse: number = 0;

  country: string = "";
  machineNo: string = "";
  synmedRobotNo: string = "";
  modelNo: string = "";
  etuqueteModelTitle: string = "";

  //#region Table colums Array
  displayedColumns: string[] = [
    "checkbox",
    "topOrder",
    "ndc",
    "customerDrugId",
    "customerMnemonic",
    "lidType",
    "cddbName",
    "customerDrugName",
    "strength",
    "usage",
    "location",
    "drawer",
    "pipette",
    "containerSize",
    "massIndex",
    "dropHeight",
    "largeDropHeight",
    "lideHole",
    "volcanic",
    "fragile",
    "toggle",
    // "drugStatus",
    "containerType",
  
    'action'
  ];

  displayedColumnsCommon: string[] = [
    "checkbox",
    "topOrder",
    "ndc",
    "customerDrugId",
    "cddbName",
    "customerDrugName",
    "strength",
    "usage",
    "location",
    "drawer",
    "pipette",
    "containerSize",
    "massIndex",
    "lideHole",
    "volcanic",
    "fragile",
    "toggle",
  
    // "drugStatus",
    'action'
  ];

  displayedColumnsNotOral: string[] = [
    "checkbox",
    "topOrder",
    "ndc",
    "customerDrugId",
    "cddbName",
    "customerDrugName",
    "strength",
    "usage",
    "location",
    "dosageForm",
    "drawer",
    "pipette",
    "containerSize",
    "massIndex",
    "lideHole",
    "volcanic",
    "fragile",
    "toggle",
    
    // "drugStatus",
    'action'
  ];
  displayedColumnsExcluded: string[] = [
    "checkbox",
    "topOrder",
    "excludedReason",
    "ndc",
    "customerDrugId",
    "cddbName",
    "customerDrugName",
    "strength",
    "usage",
    "location",
    "drawer",
    "pipette",
    "containerSize",
    "massIndex",
    "lideHole",
    "volcanic",
    "fragile",
    "toggle",
  
    // "drugStatus",
    "action"
  ];



  //#endregion
  customerId: string;

  // current which tab selected
  tabSelected: number = 0;
  currentFilterSize: number = 0;

  // All selected checkbox propertities
  isAnyDrugSelected: boolean = false;
  moveDrug: MoveDrug = {} as MoveDrug;
  //isScrollButtonShow:boolean=false;

  @HostListener("window:resize")
  onResize() {
    if (window.innerWidth > 900) {
    }
  }
  constructor(
    private dialog: MatDialog,
    private imtoolService: IMToolService,
    private imtoolAtpService: ImToolAtpService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private activateRoute: ActivatedRoute,
    private toastService: ToastService,
    private exportService: ExcelExportService
  ) {

  }

  ngOnInit(): void {

    this.drugSystemList.push(
      {
        id: 1,
        value: 2,
      },
      {
        id: 2,
        value: 5,
      }
    );

    this.activateRoute.paramMap.subscribe((params) => {
      this.customerId = params.get("customerid");
    });
    this.getAvailableCellLocation(this.customerId);
    this.getRecommendedDrugDataSynmed(this.customerId);
    this.analysisData = this.dataSource.data.length;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSourceNotAbailable.sort = this.sort1;
    this.dataSourceExcluded.sort = this.sort2;
    this.dataSourceOral.sort = this.sort3;
  }

  onAllUserPaginateChange() {
    const matTable = document.getElementById('matTable');
    matTable.scrollIntoView();
  }

  allSelectedDrugUnchecked() {
    if (this.drugCellLocationList.length > 0 || this.addCellLocList.length > 0) {
      for (let i = 0; i < this.drugCellLocationList.length; i++) {
        let index = this.dataSource.data.findIndex(
          (x) => x.fileUploadDrugId == this.drugCellLocationList[i].fileUploadDrugId
        );
        this.dataSource.data[index].checked = false;
      }

      for (let i = 0; i < this.addCellLocList.length; i++) {
        let index = this.dataSource.data.findIndex(
          (x) => x.fileUploadDrugId == this.addCellLocList[i].fileUploadDrugId
        );
        this.dataSource.data[index].checked = false;
      }
      this.drugCellLocationList = [];
      this.addCellLocList = [];
    }
  }

  drugCellLocationChecked(row: any, isChecked: boolean) {
    if (isChecked) {
      if (!row.canisterLocation) {
        this.addCellLocList.push({
          fileUploadDrugId: row.fileUploadDrugId,
          location: 0,
          containerType: '',
          cellLoc: row.canisterLocation,
          drugName: row.customerDrugName,
          processedDrugId: row.processedDrugId
        });
      }
      else {
        this.drugCellLocationList.push({
          fileUploadDrugId: row.fileUploadDrugId,
          location: row.canisterLocation,
          containerType: row.containerType,
          drugName: row.customerDrugName,
          processedDrugId: row.processedDrugId
        });

      }
      this.isAnyDrugSelected = true;
    }
    else {
      let index = this.drugCellLocationList.findIndex(
        (x) => x.fileUploadDrugId == row.fileUploadDrugId
      );

      let indexAdd = this.drugCellLocationList.findIndex(
        (x) => x.fileUploadDrugId == row.fileUploadDrugId
      );
      this.addCellLocList.splice(indexAdd, 1)
      this.drugCellLocationList.splice(index, 1);
      if (this.drugCellLocationList.length == 0 && this.addCellLocList.length == 0) {
        this.isAnyDrugSelected = false;
      }
    }
  }

  getAvailableCellLocation(customerId) {
    const AVAILABLECELLLOCATION = 'Availablecelllocation'
    this.ngxLoader.start(AVAILABLECELLLOCATION)
    this.imtoolAtpService.getAvailableCellLocationAtp(customerId).subscribe(
      (result) => {
        result.data?.forEach(x => {
          this.celLogList.push({
            id: x.cellLoc,
            value: x.cellLoc,
          });
        });
        this.ngxLoader.stop(AVAILABLECELLLOCATION)
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(AVAILABLECELLLOCATION)
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  drugCellLocationChanged(row: any) {

    if (!this.isAlreadyCellLocationUsed(row)) {
      let locationAdd: number = 0;
      let contType: string = '';
      let parts = row.canisterLocation.split("-");
      for (let i = 0; i < this.addCellLocList.length; i++) {
        if (this.addCellLocList[i].fileUploadDrugId == row.fileUploadDrugId) {
          locationAdd = parts[0];
          contType = parts[1];
          this.addCellLocList[i].location = locationAdd;
          this.addCellLocList[i].containerType = contType;
          this.addCellLocList[i].cellLoc = row.canisterLocation;
          break;
        }
      }
    }
  }

  isAlreadyCellLocationUsed(row: any): boolean {
    for (let i = 0; i < this.addCellLocList.length; i++) {
      if (this.addCellLocList[i].cellLoc == row.canisterLocation) {
        this.toastService.openToast(
          `drug (${this.addCellLocList[i].drugName}) already used this cell location`,
          constants.ToastPanelType.warning,
          2
        );
        this.replaceTheOldCellLocation(row);
        return true;
      }
    }
    return false;
  }

  openAleftWizardForValidateDrug(row: any) {

    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: 'Unoccupied Space Warning',
        bodyMessage: 'Are you sure you want to add a regular cell drug to a Super cell location',
        bodyMessage2: "",
        cancelBtnText: "Cancel",
        confirmBtnText: "Ok",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result.data) {
        this.replaceTheOldCellLocation(row);
      }
    });
  }

  replaceTheOldCellLocation(row: any) {
    let index = this.dataSource.data.findIndex(
      (x) => x.ndc == row.ndc
    );
    this.dataSource.data[index].canisterLocation = row.cellLocOld;
    index = this.drugCellLocationList.findIndex((x) => x.fileUploadDrugId == row.fileUploadDrugId)
    this.drugCellLocationList[index].location = row.cellLocOld;
  }

  getCurrentTabSelect(tab: any) {
    this.updateMainDataSourceArray(this.tabSelected);
    this.tabSelected = tab.index;
    this.addCellLocList = [];
    this.drugCellLocationList = []
  }
  updateMainDataSourceArray(tab: number) {
    if (tab === 0) {
      this.concatTwoArray().forEach(x => {
        let index = this.dataSource.data.findIndex(y => y.processedDrugId == x)
        this.dataSource.data[index].checked = false;
        this.isAnyDrugSelected = false;
      });
    }
    else if (tab === 1) {
      this.concatTwoArray().forEach(x => {
        let index = this.dataSourceNotAbailable.data.findIndex(y => y.processedDrugId == x)
        this.dataSourceNotAbailable.data[index].checked = false;
      });
    }
    else if (tab === 2) {
      this.concatTwoArray().forEach(x => {
        let index = this.dataSourceExcluded.data.findIndex(y => y.processedDrugId == x)
        this.dataSourceExcluded.data[index].checked = false;
      });
    }

    else if (tab === 3) {
      this.concatTwoArray().forEach(x => {
        let index = this.dataSourceOral.data.findIndex(y => y.processedDrugId == x)
        this.dataSourceOral.data[index].checked = false;
      });
    }

  }

  concatTwoArray(): number[] {
    const drugIdsFromDrugCellLocationList = this.drugCellLocationList
      .map((x) => x?.processedDrugId) // Use optional chaining to handle null values
      .filter((x) => x !== null);   // Filter out null values

    const drugIdsFromAddCellLocList = this.addCellLocList
      .map((x) => x?.processedDrugId) // Use optional chaining to handle null values
      .filter((x) => x !== null);   // Filter out null values
    return drugIdsFromDrugCellLocationList.concat(drugIdsFromAddCellLocList);
  }

  downloadFile(data: number) {
    if (data == 1) {
      this.excelExportFromSynmedTable();
    }
    else if (data == 2) {
      this.replenishmentExport();
    }
    else if (data == 3) {
      this.etiquettesExportFromXML();
    }
    else if (data == 4) {
      this.niceLabelCanisterExcelFileExport();
    }
    else if (data == 5) {
      this.doubleContainerExcelFileExport();
    }
    else if (data == 6) {
      this.nonCertifiedExcelFileExport();
    }
    else if (data == 7) {
      this.internalExcelFileExport();
    }
    else if (data == 8) {
      this.errorExcelFileExport();
    }
    else if (data == 9) {
      this.tDrugCsvFileExport();
    }
    else if (data == 10) {
      this.downloadXML();
    }
    else if (data == 11) {
      this.newDrugToClassifyExport();
    }
    this.changeStatusToExport();
  }

  OBJtoXML(obj) {
    var xml = '';
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
      if (obj[prop] instanceof Array) {
        for (var array in obj[prop]) {
          xml += "<" + prop + ">";
          xml += this.OBJtoXML(new Object(obj[prop][array]));
          xml += "</" + prop + ">";
        }
      } else if (typeof obj[prop] == "object") {
        xml += this.OBJtoXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');

    var blob = new Blob([xml], { type: "text/xml" });
    return xml
  }

  downloadXML() {
    // analysis drug list
    var fileContents, fileName;

    fileContents = this.OBJtoXML(this.xmlAnalysisFormatData);
    fileName = `AnalysisDruglist-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
      this.getCurrentDate()
     }.xml`;
    var pp = document.createElement('a');
    pp.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(fileContents));
    pp.setAttribute('download', fileName);
    pp.click();

  }

  excelExportFromSynmedTable() {
    const EXPORTSYNMEDDATA_KEY = 'Exportsystemdatakey'
    this.ngxLoader.start(EXPORTSYNMEDDATA_KEY)
    let analysisDrugData: any[] = [];
    this.analysisDrugList.forEach(x => {
      analysisDrugData.push({
        'Top Order': x.topOrder,
        'NDC': x.ndc,
        'Customer Drug Id': x.customerMnemonic !== null && x.customerMnemonic !== '' ? x.customerMnemonic : x.customerDrugId,
        'CDDB Name': x.cddbName,
        'Customer Drug Name': x.customerDrugName,
        'Strength': x.strength,
        'Location': x.canisterLocation,
        'Drawer': x.drawer,
        'Pipette': x.pipette,
        'Container Size ': x.containerSize,
        'Mass Index': x.massIndex,
        'Lide Hole': x.lideHole,
        'Volcanic': x.volcanic,
        'Fragile': x.fragile,
        'Toggle': x.toggle,
        'Small Drop Height': x.dropHeight,
        'Large Drop Height': x.largeDropHeight,
        'Usage': x.usage,
        // 'Drug Status' : x.drugStatus

      })
    })

    let newDrugData: any[] = [];
    this.newDrugList.forEach(x => {
      newDrugData.push({
        'Top Order': x.topOrder,
        'NDC': x.ndc,
        'Customer Drug Id': x.customerDrugId,
        'CDDB Name': x.cddbName,
        'Customer Drug Name': x.customerDrugName,
        'Strength': x.strength,
        'Drawer': x.drawer,
        'Pipette': x.pipette,
        'Container Size ': x.containerSize,
        'Mass Index': x.massIndex,
        'Lide Hole': x.lideHole,
        'Volcanic': x.volcanic,
        'Fragile': x.fragile,
        'Toggle': x.toggle,
        'Drug Status': x.drugStatus,
        'Usage': x.usage,
        'Location':''

      })
    })

    let excludedDrugData: any[] = [];
    this.excludedDrugList.forEach(x => {
      excludedDrugData.push({
        'Top Order': x.topOrder,
        'Excluded Reason': x.excludedReason,
        'NDC': x.ndc,
        'Customer Drug Id': x.customerDrugId,
        'CDDB Name': x.cddbName,
        'Customer Drug Name': x.customerDrugName,
        'Strength': x.strength,
        'Drawer': x.drawer,
        'Pipette': x.pipette,
        'Container Size ': x.containerSize,
        'Mass Index': x.massIndex,
        'Lide Hole': x.lideHole,
        'Volcanic': x.volcanic,
        'Fragile': x.fragile,
        'Toggle': x.toggle,
        'Drug Status': x.drugStatus,
        'Usage': x.usage,
        'Location':''
      })
    })

    let notOralDrugData: any[] = [];
    this.notOralDrugList.forEach(x => {
      notOralDrugData.push({
        'Top Order': x.topOrder,
        'NDC': x.ndc,
        'Dosage Form': x.dosageForm,
        'Customer Drug Id': x.customerDrugId,
        'CDDB Name': x.cddbName,
        'Customer Drug Name': x.customerDrugName,
        'Strength': x.strength,
        'Drawer': x.drawer,
        'Pipette': x.pipette,
        'Container Size ': x.containerSize,
        'Mass Index': x.massIndex,
        'Lide Hole': x.lideHole,
        'Volcanic': x.volcanic,
        'Fragile': x.fragile,
        'Toggle': x.toggle,
        'Drug Status': x.drugStatus,
        'Usage': x.usage,
        'Location':''
      })
    })

    this.fileInfo = {
      fileName: `Druglist-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates:
        [
          {
            data: analysisDrugData,
            sheetName: 'Analysis'
          },
          {
            data: newDrugData,
            sheetName: 'New Drugs'
          },
          {
            data: excludedDrugData,
            sheetName: 'Excluded'
          },
          {
            data: notOralDrugData,
            sheetName: 'Not Oral Solid'
          }
        ]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
    this.ngxLoader.stop(EXPORTSYNMEDDATA_KEY)
  }

  niceLabelCanisterExcelFileExport() {
    let data: any[] = [];
    let bigLidsData: any[] = [];
    let samllLidsData: any[] = [];
    this.synmedExportList.forEach(x => {
      data.push({
        'Container No': x.containerNo,
        'Barcode': (x.barcode && x.barcode.length > 0) ? (this.modelNo == 'U'? 'M' + this.machineNo + '-' + x.barcode : x.barcode) : '',
        'Primary Name': x.primaryName,
        'ID​': x.localDrugId,
        'Concentration​': x.strength,
        'Lid​': x.couvercle,
        'Description​': x.description,
        'Manufacturer​': x.manufacturer,
        'Size​': x.size,
        'Local ID': x.localDrugId,
        'Robot No': x.robotNumber,
        'Language Model': x.languageModel,
      })
      
      if (x.size === 'G') {
        bigLidsData.push({
            'Container No': x.containerNo,
            'Barcode': (x.barcode && x.barcode.length > 0) ? (this.modelNo == 'U' ? 'M' + this.machineNo + '-' + x.barcode : x.barcode) : '',
            'Primary Name': x.primaryName,
            'ID​': x.localDrugId,
            'Concentration​': x.strength,
            'Lid​': x.couvercle,
            'Description​': x.description,
            'Manufacturer​': x.manufacturer,
            'Size​': x.size,
            'Local ID': x.localDrugId,
            'Robot No': x.robotNumber,
            'Language Model': x.languageModel,
        });
    } else if (x.size === 'P') {
        samllLidsData.push({
            'Container No': x.containerNo,
            'Barcode': (x.barcode && x.barcode.length > 0) ? (this.modelNo == 'U' ? 'M' + this.machineNo + '-' + x.barcode : x.barcode) : '',
            'Primary Name': x.primaryName,
            'ID​': x.localDrugId,
            'Concentration​': x.strength,
            'Lid​': x.couvercle,
            'Description​': x.description,
            'Manufacturer​': x.manufacturer,
            'Size​': x.size,
            'Local ID': x.localDrugId,
            'Robot No': x.robotNumber,
            'Language Model': x.languageModel,
        });
      }
    })

    this.fileInfo = {
      fileName: `Nicelabel_Canister_labels-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()}.xlsx`,
      templates: [{
        data: data,
        sheetName: `Nicelabel_Canister_labels`
      },
      {
        data: bigLidsData,
        sheetName: `Big Lids`
      },
      {
        data: samllLidsData,
        sheetName: `Small Lids`
      }]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }

  doubleContainerExcelFileExport() {
    let data: any[] = [];
    if (this.synmedGroupDrugsExportList.length == 0) {
      data.push({
        'Rang': '',
        'No Contenant': '',
        'Qté contenants': '',
        'Tige​': '',
        'Couvercle​': '',
        'Taille​': '',
        'Local ID': '',
        'Nom primaire': '',
        'Nom secondaire': '',
        'Concentration​': '',
        'Description​': '',
        'Manufacturier​': '',
        'DIN​': '',
        '1 ​': '',
        'Code UPC': '',
        '3 ': '',
        '4 ': '',
        '5 ': '',
        '6 ': '',
        '7 ': '',
        '8 ': '',
        '9 ': '',
        '10 ': '',
        '11 ': '',
        '12 ': '',
        'Forme​': '',
        'Fragile​': '',
        'Mass​': '',
        'Toggle': '',
      })
    }
    else {
      this.synmedGroupDrugsExportList.forEach(x => {
        data.push({
          'Rang': x.rang,
          'No Contenant': x.containerNo && x.containerNo > 0 ? `${String(x.containerNo).padStart(3, '0')}` : '^',
          'Qté contenants': x.qtecontenants,
          'Tige​': x.tige,
          'Couvercle​': x.couvercle,
          'Taille​': x.taille,
          'Local ID': x.localId,
          'Nom primaire': x.nomPrimaire,
          'Nom secondaire': x.nomSecondaire,
          'Concentration​': x.strength,
          'Description​': x.description,
          'Manufacturier​': x.manufacturer,
          'DIN​': x.din,
          '1 ​': x.one,
          'Code UPC': x.barcode,
          '3 ': x.three,
          '4 ': x.four,
          '5 ': x.five,
          '6 ': x.six,
          '7 ': x.seven,
          '8 ': x.eight,
          '9 ': x.nine,
          '10 ': x.ten,
          '11 ': x.lotFromCustomer,
          '12 ': x.expirationDateFromCustomer,
          'Forme​': x.forme,
          'Fragile​': x.fragile,
          'Mass​': x.mass,
          'Toggle': x.toggle,
        })
      })
    }


    this.fileInfo = {
      fileName: `Double_Containers-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates: [{
        data: data,
        sheetName: `Double_Containers`
      }]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }

  nonCertifiedExcelFileExport() {
    let data: any[] = [];
    if (this.synmedNonCertifiedExportList.length == 0) {
      data.push({
        'Rang': '',
        'No Contenant': '',
        'Qté contenants': '',
        'Tige​': '',
        'Couvercle​': '',
        'Taille​': '',
        'Local ID': '',
        'Nom primaire': '',
        'Nom secondaire': '',
        'Concentration​': '',
        'Description​': '',
        'Manufacturier​': '',
        'DIN​': '',
        '1 ​': '',
        'Code UpC': '',
        '3 ': '',
        '4 ': '',
        '5 ': '',
        '6 ': '',
        '7 ': '',
        '8 ': '',
        '9 ': '',
        '10 ': '',
        '11 ': '',
        '12 ': '',
        'Forme​': '',
        'Fragile​': '',
        'Mass​': '',
        'Toggle': '',
      })
    }
    else {
      this.synmedNonCertifiedExportList.forEach(x => {
        data.push({
          'Rang': x.rang,
          'No Contenant': x.containerNo && x.containerNo > 0 ? `${String(x.containerNo).padStart(3, '0')}` : '^',
          'Qté contenants': x.qtecontenants,
          'Tige​': x.tige,
          'Couvercle​': x.couvercle,
          'Taille​': x.taille,
          'Local ID': x.localId,
          'Nom primaire': x.nomPrimaire,
          'Nom secondaire': x.nomSecondaire,
          'Concentration​': x.strength,
          'Description​': x.description,
          'Manufacturier​': x.manufacturer,
          'DIN​': x.din,
          '1 ​': x.one,
          'Code UpC': x.barcode,
          '3 ': x.three,
          '4 ': x.four,
          '5 ': x.five,
          '6 ': x.six,
          '7 ': x.seven,
          '8 ': x.eight,
          '9 ': x.nine,
          '10 ': x.ten,
          '11 ': x.eleven,
          '12 ': x.twelve,
          'Forme​': x.forme,
          'Fragile​': x.fragile,
          'Mass​': x.mass,
          'Toggle': x.toggle,
        })
      })
    }


    this.fileInfo = {
      fileName: `NON-Certified_Drugs-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates: [{
        data: data,
        sheetName: `NON-Certified_Drugs`
      }]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }

  errorExcelFileExport() {
    let data: any[] = [];
    if (this.synmedErrorExportList.length == 0) {
      data.push({
        'Rang': '',
        'No Contenant': '',
        'Qté contenants': '',
        'Tige​': '',
        'Couvercle​': '',
        'Taille​': '',
        'Local ID': '',
        'Nom primaire': '',
        'Nom secondaire': '',
        'Concentration​': '',
        'Description​': '',
        'Manufacturier​': '',
        'DIN​': '',
        '1 ​': '',
        'Code UPC': '',
        '3 ': '',
        '4 ': '',
        '5 ': '',
        '6 ': '',
        '7 ': '',
        '8 ': '',
        '9 ': '',
        '10 ': '',
        '11 ': '',
        '12 ': '',
        'Forme​': '',
        'Fragile​': '',
        'Mass​': '',
        'Toggle': '',
      })
    }
    else {
      this.synmedErrorExportList.forEach(x => {
        data.push({
          'Rang': x.rang,
          'No Contenant': x.containerNo && x.containerNo > 0 ? `${String(x.containerNo).padStart(3, '0')}` : '^',
          'Qté contenants': x.qtecontenants,
          'Tige​': x.tige,
          'Couvercle​': x.couvercle,
          'Taille​': x.taille,
          'Local ID': x.localDrugId,
          'Nom primaire': x.nomPrimaire,
          'Nom secondaire': x.nomSecondaire,
          'Concentration​': x.strength,
          'Description​': x.description,
          'Manufacturier​': x.manufacturer,
          'DIN​': x.din,
          '1​ ': x.one,
          'Code UpC': x.barcode,
          '3 ': x.three,
          '4 ': x.four,
          '5 ': x.five,
          '6 ': x.six,
          '7 ': x.seven,
          '8 ': x.eight,
          '9 ': x.nine,
          '10 ': x.ten,
          '11 ': x.lotFromCustomer,
          '12 ': x.expirationDateFromCustomer,
          'Forme​': x.forme,
          'Fragile​': x.fragile,
          'Mass​': x.mass,
          'Toggle': x.toggle,
        })
      })
    }

    this.fileInfo = {
      fileName: `Implementation Exports Errors-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates: [{
        data: data,
        sheetName: `Errors`
      }]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }

  internalExcelFileExport() {
    let data: any[] = [];
    this.synmedExportList.forEach(x => {
      data.push({
        'Local Drug ID': x.localId,
        'Primary Drug Name': x.nomPrimaire,
        'Secondary Drug Name': x.nomSecondaire,
        'Strength': x.strength,
        'Description​': x.description,
        'Manufacturier​': x.manufacturer,
        'DIN​': x.din,
        '1 ​': x.one,
        'Tige​': x.tige,
        'Code UpC': x.barcode,
        '3 ': x.three,
        '4 ': x.four,
        '5 ': x.five,
        '6 ': x.six,
        '7 ': x.seven,
        '8 ': x.eight,
        '9 ': x.nine,
        '10 ': x.ten,
        '11 ': x.lotFromCustomer,
        '12 ': x.expirationDateFromCustomer,
        'Forme​': x.forme,
        'Fragile​': x.fragile,
        'Mass​': x.mass,
        'Drop Height': x.dropHeight,
        'Toggle': x.toggle,
        'Package_order_Number': x.packageOrderNo,
        'Display name': x.displayName,
        'Not In Blister': x.notInBlister,
        'Lid_hole_lookup_value': x.lidHoleLookupValue,
        'Lid_Volcanic': x.lidVolcanic,
        'Generic_Code': x.genericCode

      })

    })
    this.fileInfo = {
      fileName: `Internals-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates: [{
        data: data,
        sheetName: `Internals`
      }]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }

  replineshmentExcelFileExport() {
    let data: any[] = [];
    this.synmedExportList.forEach(x => {
      data.push({
        'Din/NDC': x.din,
        'Container No': x.containerNo,
        'Primary Drug Name': x.nomPrimaire,
        'Strength': x.strength,
        'Description​': x.description,
        'Manufacturer​': x.manufacturer,
        'Container Size (S=Small, B=Big)': x.containerSize==='Large'?'B':x.containerSize==='Small'?'S':'',
        'Insert(V ou W)': x.insert,
        'Lid (W=2 holwa, C=Conic, S=Std)': x.couvercle,
        'Lot no': x.lotFromCustomer,
        'Expiration Date (mm-dd-yyyy)': x.expirationDateFromCustomer,
        'Qty': x.qtyOfPills,
        'UPC Code (Please scan barcode)': x.barcode,

      })

    })
    this.fileInfo = {
      fileName: `Replenishment-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates: [{
        data: data,
        sheetName: `Replenishment`
      }]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }

  newDrugToClassifyExport() {
    let data: any[] = [];
    this.newDrugList.forEach(x => {
      data.push({
        'Date': '',
        'DIN': x.ndc,
        'SynRPO_Primary Drug Name': x.cddbName,
        'Dosage Form': '',
        'Strength': x.strength,
        'Unit of Measure': x.usage,
        'Manufacturer': x.manufacturer,
        'Color': '',
        'Shape': '',
        'Imprint 1': '',
        'Imprint 2': '',
        'Lid_Hole_Size': x.lideHole,
        'Length mm': '',
        'Width mm': '',
        'Thickness mm': '',
        'T2 mm (Ridge of a Caplet)': '',
        '10 Digit UPC Code': '',
        '100 ct Pill Weight (mg)': '',
        'Single Pill Weight (mg)': '',
        '30 Drams Capacity': '',
        'Source': '',
        'Comment': '',


      })

    })
    this.fileInfo = {
      fileName: `New_Drugs_To_Classify-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates: [{
        data: data,
        sheetName: `New Drugs To Classify`
      }]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }
  downloadPreparation_etiquettes() {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table6.nativeElement
    );
    const ws2: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table5.nativeElement, { raw: true }
    );
    //     XLSX.utils.sheet_add_aoa(ws1, [["Firstname"]], { origin: "A1"
    //  });
    const alignmentCenter = { horizontal: "center", vertical: "center", wrapText: true };

    const ThinBorder = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" }
    };
    const LightBlue = {
      fgColor: { rgb: "BDD7EE" }
    };
    const fillAlignmentBorder = {
      fill: LightBlue,
      alignment: alignmentCenter,
      border: ThinBorder
    };
    ws1["A1"].w = fillAlignmentBorder;
    XLSX.utils.book_append_sheet(wb, ws1, "Liste");
    XLSX.utils.book_append_sheet(wb, ws2, "Couvercles");
    XLSX.writeFile(
      wb,
      `Preparation_etiquettes-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`
    );
  }

  tDrugCsvFileExport() {
    this.exportService.exportToCsvFromString(this.tDrugDataExportList, `t_drug-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
      this.getCurrentDate()}.csv`);
  }


  defaultSortSet(columnName: string, dir: any) {
    const sortState: Sort = { active: columnName, direction: dir };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  changeStatusToExport() {
    const CHANGESTATUSTOEXPORT_KEY = 'Changestatustoexportkey'
    this.ngxLoader.start(CHANGESTATUSTOEXPORT_KEY);
    this.imtoolService.changeStatusToExport(this.customerId).subscribe(
      (result) => {
        this.ngxLoader.stop(CHANGESTATUSTOEXPORT_KEY);
        if (result.statusCode == 200) {
          this.toastService.openToast(
            `Downloaded Successfully. ${result.message}`,
            constants.ToastPanelType.done,
            2
          );
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(CHANGESTATUSTOEXPORT_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }
  getRecommendedDrugDataSynmed(customerId) {
    const SYNMEDDATALOAD = 'Synmeddataload'
    this.ngxLoader.start(SYNMEDDATALOAD);
    this.imtoolService.getRecommendedDrugDataSynmed(customerId).subscribe(
      (response) => {
        let result = response.data

        if (result == null) {
          this.ngxLoader.stop(SYNMEDDATALOAD);
          this.toastService.openToast(
            response.message,
            constants.ToastPanelType.error,
            2
          );
          return
        };
        this.orderOrUsage = result.orderOrUsage == true ? '# of Orders' : 'Pills Disp';
        this.phllDispLable = result.orderOrUsage == true ? 'Pills Disp' : '# of Orders';
        this.xmlAnalysisFormatData = result.cellConfigurationXml;
        this.customerName = result.customerName;
        this.targetInstallDate = result.targetInstallDate;
        this.subDeviceTypeName = result.deviceTypeName;
        this.deviceId = result.deviceId;
        this.deviceTypeId = result.deviceTypeId;
        this.deviceName = result.deviceName;

        this.country = result.countryCode;
        this.remainingLargeLocation = result.remainingLargeLocation;
        this.remainingSmallLocation = result.remainingSmallLocation;
        this.remainingLargeContLargePipetteTrue = result.remainingLargeContLargePipetteTrue;
        this.remainingLargeContLargePipetteFalse = result.remainingLargeContLargePipetteFalse;
        this.remainingLargeContSmallPipetteTrue = result.remainingLargeContSmallPipetteTrue;
        this.remainingLargeContSmallPipetteFalse = result.remainingLargeContSmallPipetteFalse;
        this.remainingLargeContBothPipetteTrue = result.remainingLargeContBothPipetteTrue;
        this.remainingLargeContBothPipetteFalse = result.remainingLargeContBothPipetteFalse;
        this.remainingSmallContSmallPipetteTrue = result.remainingSmallContSmallPipetteTrue;
        this.remainingSmallContSmallPipetteFalse = result.remainingSmallContSmallPipetteFalse;
        this.machineNo = result.machineNumber;
        this.synmedRobotNo = result.synmedRobotNumber;
        this.modelNo = result.deviceTypeId == 16 ? 'U' : result.deviceTypeId == 17 ? 'X' : '';
        this.etuqueteModelTitle = result.deviceTypeId == 16 ? 'UR' : result.deviceTypeId == 17 ? 'XR' : '';

        this.analysisDrugList = [];
        this.newDrugList = [];
        this.excludedDrugList = [];
        this.notOralDrugList = [];

        let rowSerial = 1
        result.drugs?.forEach((x) => {
          this.analysisDrugList.push({
            checked: false,
            topOrder: rowSerial++, //x.analysisSl,
            ndc: x.nationalDrugCode,
            customerDrugId: x.customerDrugId,
            customerMnemonic: x.customerMnemonic,
            lidType:x.lidType,
            cddbName: x.cddbDrugName,
            customerDrugName: x.customerDrugName,
            strength: x.strength,
            canisterLocation: x.location,
            drawer: x.drawerNo,
            pipette: x.pipetteSize,
            containerSize: x.containerSize,
            massIndex: x.massIndex,
            lideHole: x.lidHoleSize,
            volcanic: x.lidVolcanic,
            fragile: x.fragileFlag,
            toggle: x.toggleFlag,
            drugStatus: x.synMedStatus,
            fileUploadDrugId: x.processedDrugId, // old code x.fileUploadDrugId,
            excludedReason: x.excludedReason,
            containerType: x.containerType,
            dropHeight: x.dropHeight,
            largeDropHeight: x.largeDropHeight,
            usage: x.usage,
            processedDrugId: x.processedDrugId,
            manufacturer: x.manufacturer
          });
        });
        rowSerial = 1;
        result.notAvailableDrugs?.forEach((x) => {
          this.newDrugList.push({
            checked: false,
            topOrder: rowSerial++, //x.newDrugSl,
            ndc: x.nationalDrugCode,
            customerDrugId: x.customerDrugId,
            customerMnemonic: x.customerMnemonic,
            cddbName: x.cddbDrugName,
            customerDrugName: x.customerDrugName,
            strength: x.strength,
            canisterLocation: x.location,
            drawer: x.drawerNo,
            pipette: x.pipetteSize,
            containerSize: x.containerSize,
            massIndex: x.massIndex,
            lideHole: x.lidHoleSize,
            volcanic: x.lidVolcanic,
            fragile: x.fragileFlag,
            toggle: x.toggleFlag,
            drugStatus: x.synMedStatus,
            fileUploadDrugId: x.processedDrugId, // old code x.fileUploadDrugId,
            excludedReason: x.excludedReason,
            containerType: x.containerType,
            dropHeight: x.dropHeight,
            largeDropHeight: x.largeDropHeight,
            usage: x.usage,
            processedDrugId: x.processedDrugId,
            manufacturer: x.manufacturer
          });
        });
        rowSerial = 1;
        result.excludedDrugs?.forEach((x) => {
          this.excludedDrugList.push({
            checked: false,
            topOrder: rowSerial++, //x.excludedSl,
            ndc: x.nationalDrugCode,
            customerDrugId: x.customerDrugId,
            customerMnemonic: x.customerMnemonic,
            cddbName: x.cddbDrugName,
            customerDrugName: x.customerDrugName,
            strength: x.strength,
            canisterLocation: x.location,
            drawer: x.drawerNo,
            pipette: x.pipetteSize,
            containerSize: x.containerSize,
            massIndex: x.massIndex,
            lideHole: x.lidHoleSize,
            volcanic: x.lidVolcanic,
            fragile: x.fragileFlag,
            toggle: x.toggleFlag,
            drugStatus: x.synMedStatus,
            fileUploadDrugId: x.processedDrugId, // old code x.fileUploadDrugId,
            excludedReason: x.excludedReason,
            containerType: x.containerType,
            dropHeight: x.dropHeight,
            largeDropHeight: x.largeDropHeight,
            usage: x.usage,
            processedDrugId: x.processedDrugId,
            manufacturer: x.manufacturer
          })
        })
        rowSerial = 1;
        result.notOralDrugs?.forEach((x) => {
          this.notOralDrugList.push({
            checked: false,
            topOrder: rowSerial++,//x.notOralSolidSl,
            ndc: x.nationalDrugCode,
            customerDrugId: x.customerDrugId,
            customerMnemonic: x.customerMnemonic,
            cddbName: x.cddbDrugName,
            customerDrugName: x.customerDrugName,
            strength: x.strength,
            canisterLocation: x.location,
            drawer: x.drawerNo,
            pipette: x.pipetteSize,
            containerSize: x.containerSize,
            massIndex: x.massIndex,
            lideHole: x.lidHoleSize,
            volcanic: x.lidVolcanic,
            fragile: x.fragileFlag,
            toggle: x.toggleFlag,
            drugStatus: x.synMedStatus,
            fileUploadDrugId: x.processedDrugId, // old code x.fileUploadDrugId,
            excludedReason: x.excludedReason,
            containerType: x.containerType,
            dropHeight: x.dropHeight,
            dosageForm: x.dosageForm,
            largeDropHeight: x.largeDropHeight,
            usage: x.usage,
            processedDrugId: x.processedDrugId,
            manufacturer: x.manufacturer,
          })
        })

        result.synmedExport?.forEach((x) => {
          this.synmedExportList.push({
            containerNo: x.containerNo,
            barcode: x.barcode,
            primaryName: x.primaryName,
            lid: x.lid,
            description: x.description,
            manufacturer: x.manufacturer,
            size: x.size,
            localDrugId: x.localDrugId,
            robotNumber: x.robotNumber,
            languageModel: x.languageModel,
            din: x.din,
            lotNo: x.lotNo,
            strength: x.strength,
            insert: x.insert,
            expirationDate: x.expirationDate,
            qtyOfPills: x.qtyOfPills,
            packageOrderNo: x.packageOrderNo,
            displayName: x.displayName,
            notInBlister: x.notInBlister,
            lidHoleLookupValue: x.lidHoleLookupValue,
            lidVolcanic: x.lidVolcanic,
            genericCode: x.genericCode,
            rang: x.rang,
            noContenant: x.noContenant,
            qtecontenants: x.qtecontenants,
            tige: x.tige,
            couvercle: x.couvercle,
            localId: x.localDrugId,
            taille: x.taille,
            nomPrimaire: x.nomPrimaire,
            nomSecondaire: x.nomSecondaire,
            one: x.one,
            two: x.two,
            three: x.three,
            four: x.four,
            five: x.five,
            six: x.six,
            seven: x.seven,
            eight: x.eight,
            nine: x.nine,
            ten: x.ten,
            eleven: x.eleven,
            twelve: x.twelve,
            forme: x.forme,
            fragile: x.fragile,
            mass: x.mass,
            toggle: x.toggle,
            containerSize: x.containerSize,
            dropHeight: x.dropHeight,
            lotFromCustomer: x.lotFromCustomer,
            expirationDateFromCustomer: x.expirationDateFromCustomer,
            qtyInCanister: x.qtyInCanister
          })
        })

        result.synmedEtiquettesExport?.forEach((x) => {
          this.etiquetteExportList.push({
            itemOne: x.itemOne,
            itemTwo: x.itemTwo,
            one: x.one,
            two: x.two,
            three: x.three,
            four: x.four,
            five: x.five,
            six: x.six,
            seven: x.seven,
            eight: x.eight,
            nine: x.nine,
            ten: x.ten,
            eleven: x.eleven,
            twelve: x.twelve,
            thirteen: x.thirteen,
            forteen: x.forteen,
            fifteen: x.fifteen,
            sixteen: x.sixteen,
            seventeen: x.seventeen,
            eighteen: x.eighteen,
            nineteen: x.nineteen,
            twenty: x.twenty,
            twentyOne: x.twentyOne,
            twentyTwo: x.twentyTwo,
            twentyThree: x.twentyThree,
            totalGeneral: x.totalGeneral,
          })
        })

        result.etiquettesListExports?.forEach((x) => {
          this.etiquetteList.push({
            troir: x.troir,
            location: x.location,
            medId: x.medId,
            primaryMedName: x.primaryMedName,
            couvercles: x.couvercles,
            contenant: x.contenant,
          })
        })

        result?.synmedErrorExport?.forEach((x) => {
          this.synmedErrorExportList.push({
            containerNo: x.containerNo,
            barcode: x.barcode,
            primaryName: x.primaryName,
            lid: x.lid,
            description: x.description,
            manufacturer: x.manufacturer,
            size: x.size,
            localDrugId: x.localIdOrMnemonic,
            robotNumber: x.robotNumber,
            languageModel: x.languageModel,
            din: x.din,
            lotNo: x.lotNo,
            strength: x.strength,
            insert: x.insert,
            expirationDate: x.expirationDate,
            qtyOfPills: x.qtyOfPills,
            packageOrderNo: x.packageOrderNo,
            displayName: x.displayName,
            notInBlister: x.notInBlister,
            lidHoleLookupValue: x.lidHoleLookupValue,
            lidVolcanic: x.lidVolcanic,
            genericCode: x.genericCode,
            rang: x.rang,
            noContenant: x.noContenant,
            qtecontenants: x.qtecontenants,
            tige: x.tige,
            couvercle: x.couvercle,
            localId: x.localDrugId,
            taille: x.taille,
            nomPrimaire: x.nomPrimaire,
            nomSecondaire: x.nomSecondaire,
            one: x.one,
            two: x.two,
            three: x.three,
            four: x.four,
            five: x.five,
            six: x.six,
            seven: x.seven,
            eight: x.eight,
            nine: x.nine,
            ten: x.ten,
            eleven: x.eleven,
            twelve: x.twelve,
            forme: x.forme,
            fragile: x.fragile,
            mass: x.mass,
            toggle: x.toggle,
            containerSize: x.containerSize,
            lotFromCustomer: x.lotFromCustomer,
            expirationDateFromCustomer: x.expirationDateFromCustomer,
            qtyInCanister: x.qtyInCanister
          })
        })

        result?.synmedGroupDrugsExport?.forEach((x) => {
          this.synmedGroupDrugsExportList.push({
            containerNo: x.containerNo,
            barcode: x.barcode,
            primaryName: x.primaryName,
            lid: x.lid,
            description: x.description,
            manufacturer: x.manufacturer,
            size: x.size,
            localDrugId: x.localDrugId,
            robotNumber: x.robotNumber,
            languageModel: x.languageModel,
            din: x.din,
            lotNo: x.lotNo,
            strength: x.strength,
            insert: x.insert,
            expirationDate: x.expirationDate,
            qtyOfPills: x.qtyOfPills,
            packageOrderNo: x.packageOrderNo,
            displayName: x.displayName,
            notInBlister: x.notInBlister,
            lidHoleLookupValue: x.lidHoleLookupValue,
            lidVolcanic: x.lidVolcanic,
            genericCode: x.genericCode,
            rang: x.rang,
            noContenant: x.noContenant,
            qtecontenants: x.qtecontenants,
            tige: x.tige,
            couvercle: x.couvercle,
            localId: x.localIdOrMnemonic,
            taille: x.taille,
            nomPrimaire: x.nomPrimaire,
            nomSecondaire: x.nomSecondaire,
            one: x.one,
            two: x.two,
            three: x.three,
            four: x.four,
            five: x.five,
            six: x.six,
            seven: x.seven,
            eight: x.eight,
            nine: x.nine,
            ten: x.ten,
            eleven: x.eleven,
            twelve: x.twelve,
            forme: x.forme,
            fragile: x.fragile,
            mass: x.mass,
            toggle: x.toggle,
            containerSize: x.containerSize,
            lotFromCustomer: x.lotFromCustomer,
            expirationDateFromCustomer: x.expirationDateFromCustomer,
            qtyInCanister: x.qtyInCanister
          })
        })

        result?.synmedNonCertifiedExport?.forEach((x) => {
          this.synmedNonCertifiedExportList.push({
            containerNo: x.containerNo,
            barcode: x.barcode,
            primaryName: x.primaryName,
            lid: x.lid,
            description: x.description,
            manufacturer: x.manufacturer,
            size: x.size,
            localDrugId: x.localDrugId,
            robotNumber: x.robotNumber,
            languageModel: x.languageModel,
            din: x.din,
            lotNo: x.lotNo,
            strength: x.strength,
            insert: x.insert,
            expirationDate: x.expirationDate,
            qtyOfPills: x.qtyOfPills,
            packageOrderNo: x.packageOrderNo,
            displayName: x.displayName,
            notInBlister: x.notInBlister,
            lidHoleLookupValue: x.lidHoleLookupValue,
            lidVolcanic: x.lidVolcanic,
            genericCode: x.genericCode,
            rang: x.rang,
            noContenant: x.noContenant,
            qtecontenants: x.qtecontenants,
            tige: x.tige,
            couvercle: x.couvercle,
            localId: x.localDrugId,
            taille: x.taille,
            nomPrimaire: x.nomPrimaire,
            nomSecondaire: x.nomSecondaire,
            one: x.one,
            two: x.two,
            three: x.three,
            four: x.four,
            five: x.five,
            six: x.six,
            seven: x.seven,
            eight: x.eight,
            nine: x.nine,
            ten: x.ten,
            eleven: x.eleven,
            twelve: x.twelve,
            forme: x.forme,
            fragile: x.fragile,
            mass: x.mass,
            toggle: x.toggle,
            containerSize: x.containerSize,
            lotFromCustomer: x.lotFromCustomer,
            expirationDateFromCustomer: x.expirationDateFromCustomer,
            qtyInCanister: x.qtyInCanister
          })
        })

        this.dataSource.data = this.analysisDrugList;
        this.dataSourceNotAbailable.data = this.newDrugList;
        this.dataSourceExcluded.data = this.excludedDrugList;
        this.dataSourceOral.data = this.notOralDrugList;
        this.ngxLoader.stop(SYNMEDDATALOAD);
        console.log("isTDrugOpen bb:",this.isTdrugOpen);
        this.getTdrugDataSynmed(this.customerId);
        console.log("isTDrugOpen :",this.isTdrugOpen);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(SYNMEDDATALOAD);
        console.log(error)
        this.toastService.openToast(error.error.message ?? error.message, constants.ToastPanelType.error, 2);
      }
    );
  }

  getTdrugDataSynmed(customerId) {
    const TDRUGSYNMED_KEY = 'Tdrugsynmedkey'
    this.imtoolService.getTdrugDataSynmed(customerId).subscribe(
      (response) => {
        const data = response;
        this.tDrugDataExportList = data;
        this.isTdrugOpen = true;
      },
      (error: HttpErrorResponse) => {
        console.log(error)
        this.ngxLoader.stop(TDRUGSYNMED_KEY);
        this.toastService.openToast(error.error.message ?? error.message, constants.ToastPanelType.error, 2);
      }
    );
  }


  removeDrugCellLocation() {
    const REMOVEDRUGCELLLCOATION = 'Removecellocation'
    this.ngxLoader.start(REMOVEDRUGCELLLCOATION);
    this.drugCellLocationRemoveList = {
      customerId: this.customerId,
      removeDrugs: this.drugCellLocationList,
    };
    this.imtoolService.drugRemoveSynmed(this.drugCellLocationRemoveList).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );
          window.setTimeout(() => {
            window.location.reload();
          }, 2000);
          this.ngxLoader.stop(REMOVEDRUGCELLLCOATION);
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop();
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(REMOVEDRUGCELLLCOATION);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  isCellLocationNotEmpty(): boolean {
    return this.addCellLocList.some(function (el) {
      return el.location === 0 || el.location === null;
    });
  }

  addDrugCellLocation() {

    if (this.isCellLocationNotEmpty()) {
      this.toastService.openToast(
        "Please select a cell location to Add!",
        constants.ToastPanelType.error,
        2
      );
    }
    else {
      const ADDDRUGCELLLOCATON_KEY = 'Adddrugcellocationkey'
      this.ngxLoader.start(ADDDRUGCELLLOCATON_KEY);
      this.drugCellLocationAddList = {
        customerId: this.customerId,
        addDrugs: this.addCellLocList,
      };
      this.imtoolService.drugAddSynmed(this.drugCellLocationAddList).subscribe(
        (result) => {
          if (result.statusCode == 200) {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.done,
              2
            );
            window.setTimeout(() => {
              window.location.reload();
            }, 2000);
            this.ngxLoader.stop(ADDDRUGCELLLOCATON_KEY);
          } else {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.error,
              2
            );
            this.ngxLoader.stop(ADDDRUGCELLLOCATON_KEY);
          }
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(ADDDRUGCELLLOCATON_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
    }

  }


  openAlertWindowForDrugRemove() {
    var bodyMessage = "Are you sure, you want to remove this drug?";
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Remove Drug ?",
        bodyMessage: bodyMessage,
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Remove",
        confirmButtonColor: "btn-light-red",
        cancelButtonColor: "btn-light-blue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.removeDrugCellLocation();
      } else {
        console.log("not export", result);
      }
    });
  }

  openAlertWindowForDrugAdd() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Add Drug?",
        bodyMessage: "Are you sure you want to add this drug?",
        bodyMessage2: this.addCellLocList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Add",
        confirmButtonColor: "btn-light-red",
        cancelButtonColor: "btn-light-blue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.addDrugCellLocation();
      } else {
        console.log("not export", result);
      }
    });
  }




  openImportWizardCDDB() {
    const dialogRef = this.dialog.open(ImportWizardCddbComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customerId: this.customerId,
        deviceId: this.deviceId
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.getRecommendedDrugDataSynmed(this.customerId);
      } else {
        console.log("No change implement", result);
      }
    });
  }


  public doFilter = (value: string) => {
    if (this.tabSelected === 0)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 1)
      this.dataSourceNotAbailable.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 2)
      this.dataSourceExcluded.filter = value.trim().toLocaleLowerCase();
    else this.dataSourceOral.filter = value.trim().toLocaleLowerCase();
  };


  openAlertWindow() {
    let title = "Confirm export"
    let message1 = "Are you sure you wish to export?"

    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: title,
        bodyMessage: message1,
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Export",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        //this.ExportTOExcel();
      } else {
        console.log("not export", result);
      }
    });
  }

  redirectCustomerList() {
    this.router.navigate(["/im-tool"]);
  }
  openImportWizard() {
    const dialogSiteRef = this.dialog.open(ImportWizardComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customer: {
          sl: 1,
          customerId: this.customerId,
          customerName: this.customerName,
          caseNumber: '',
          customerNumber: '',
          deviceType: '',
          subDeviceTypeName: '',
          lastEdited: '',
          status: '',
          statusDescription: '',
          targetInstallDate: this.targetInstallDate,
        }, isparentnotassociated: 2
      },
    });
    dialogSiteRef.afterClosed().subscribe((result) => {

      this.getRecommendedDrugDataSynmed(this.customerId);

    });
  }
  openImportWizardQualifier() {
    const dialogSiteRef = this.dialog.open(ImportWizardDrugQualifierComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customerId: this.customerId,
        subDeviceId:this.deviceTypeId,
      }
    });
    dialogSiteRef.afterClosed().subscribe((result) => {

      this.getRecommendedDrugDataSynmed(this.customerId);

    });
  }

  openImportWizardDcrDrug() {
    const dialogSiteRef = this.dialog.open(ImportWizardDcrDrugComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customerId: this.customerId
      }
    });
    dialogSiteRef.afterClosed().subscribe((result) => {


    });
  }


  openImportWizardDrugMovingComponent() {

    const combinedDrugIds = this.concatTwoArray();

    const dialogRefMoving = this.dialog.open(ImportWizardDrugMovingComponent, {

      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        title: '',
        listOfData: [{ key: 1, value: 'Analysis', isSelected: this.tabSelected === 0 },
        { key: 2, value: 'New Drugs', isSelected: this.tabSelected === 1 },
        { key: 3, value: 'Excluded', isSelected: this.tabSelected === 2 },
        { key: 4, value: 'Not Oral Solid', isSelected: this.tabSelected === 3 },]
      }
    });
    dialogRefMoving.afterClosed().subscribe((result) => {


      if (result.data.isClose === true) {
        this.onUpload(result.data.selectedTab, combinedDrugIds)
      }



    });


  }
  onUpload(selectedTab, drugs) {
    if (selectedTab === 0) {
      return
    }
    const UPLOADDATA_KEY = 'Uploaddatakey'
    this.ngxLoader.start(UPLOADDATA_KEY);
    this.moveDrug = {
      customerId: this.customerId,
      deviceId: this.deviceId,
      processDrugType: selectedTab,
      drugId: drugs,
    }
    this.imtoolService.moveDrugs(this.moveDrug).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );

          this.ngxLoader.stop(UPLOADDATA_KEY);
          setTimeout(() => {
            location.reload();
          }, 2000)

        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop(UPLOADDATA_KEY);
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  replenishmentExport(){
    const uri = 'data:application/vnd.ms-excel;base64,';
    let row = 1;
    let tableRow = 2;
    let tableData = this.synmedExportList.map(x=>
      {
        let styleID ="S522";
        let containerSize= x.containerSize==='Large'?'B':x.containerSize==='Small'?'S':'';

        return `<Row ss:Index="${tableRow++}" ss:Height="15.75">
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.din}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="Number">${x.containerNo==null?0:x.containerNo}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.nomPrimaire}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.strength}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.description}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.manufacturer}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${containerSize}</Data>
        </Cell>
        <Cell ss:StyleID="S22" ><Data ss:Type="String">${x.insert}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.couvercle}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.lotFromCustomer}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.expirationDateFromCustomer==null?'':x.expirationDateFromCustomer}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.qtyOfPills && x.qtyOfPills>0?x.qtyOfPills.toString():''}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.qtyInCanister && x.qtyInCanister>0?x.qtyInCanister.toString():''}</Data>
        </Cell>
        <Cell ss:StyleID="S22"><Data ss:Type="String">${x.barcode}</Data>
        </Cell>
       </Row>`
      })
      let concatenatedTableData = tableData.join('');

    const template =`<?xml version="1.0" encoding="utf-8"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:x2="urn:schemas-microsoft-com:office:excel2" xmlns:html="http://www.w3.org/TR/REC-html40" xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882">
    <x:ExcelWorkbook>
     <x:ActiveSheet>0</x:ActiveSheet>
    </x:ExcelWorkbook>
    <o:DocumentProperties>
     <o:LastSaved>2024-03-05T10:09:47Z</o:LastSaved>
    </o:DocumentProperties>
    <Styles>
     <Style ss:ID="Default" ss:Name="Normal">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
     <Style ss:ID="S21">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Center" />
      <ss:Font ss:Bold="1" ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
     <Style ss:ID="S22">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
    </Styles>
    <Worksheet ss:Name="Replenishment">
     <ss:Names />
     <ss:Table ss:DefaultRowHeight="12.75" ss:DefaultColumnWidth="54" ss:ExpandedRowCount="${this.synmedExportList.length+1}" ss:ExpandedColumnCount="17">
      <Column ss:Index="1" ss:AutoFitWidth="0" ss:Width="83.25" />
      <Column ss:Index="2" ss:AutoFitWidth="0" ss:Width="90" />
      <Column ss:Index="3" ss:AutoFitWidth="0" ss:Width="204" />
      <Column ss:Index="4" ss:AutoFitWidth="0" ss:Width="92.25" />
      <Column ss:Index="5" ss:AutoFitWidth="0" ss:Width="144.75" />
      <Column ss:Index="6" ss:AutoFitWidth="0" ss:Width="161.25" />
      <Column ss:Index="7" ss:AutoFitWidth="0" ss:Width="216.75" />
      <Column ss:Index="8" ss:AutoFitWidth="0" ss:Width="114" />
      <Column ss:Index="9" ss:AutoFitWidth="0" ss:Width="173.25" />
      <Column ss:Index="11" ss:AutoFitWidth="0" ss:Width="189.75" />
      <Column ss:Index="12" ss:AutoFitWidth="0" ss:Width="54.75" />
      <Column ss:Index="13" ss:AutoFitWidth="0" ss:Width="58" />
      <Column ss:Index="14" ss:AutoFitWidth="0" ss:Width="192" />
      <Row ss:Index="1" ss:Height="27.75">
       <Cell ss:StyleID="S21"><Data ss:Type="String">Din/NDC</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Container No</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Primary Drug Name</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Strength</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Description</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Manufacturer</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Container Size (S=Small, B=Big)</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Insert (V ou W)</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Lid (W=2 holwa, C=Conic, S=Std)</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Lot no</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Expiration Date (mm-dd-yyyy)</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Qty</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">Qty Of Pills</Data>
       </Cell>
       <Cell ss:StyleID="S21"><Data ss:Type="String">UPC Code (Please scan barcode)</Data>
       </Cell>
      </Row>
    
      ${concatenatedTableData}
    
     </ss:Table>
     <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
      <PageSetup>
       <Header x:Margin="0.3" />
       <Footer x:Margin="0.3" />
       <PageMargins x:Top="0.75" x:Bottom="0.75" x:Left="0.7" x:Right="0.7" />
      </PageSetup>
      <Print>
       <PaperSizeIndex>9</PaperSizeIndex>
       <HorizontalResolution>600</HorizontalResolution>
       <VerticalResolution>600</VerticalResolution>
      </Print>
      <Panes>
       <Pane>
        <Number>3</Number>
        <RangeSelection>R1:R1</RangeSelection>
       </Pane>
      </Panes>
     </WorksheetOptions>
    </Worksheet>
   </Workbook>`

   const base64 = function(s:any) { return window.btoa(unescape(encodeURIComponent(s))) };
    const format = function(s:any, c:any) { return s.replace(/{(\w+)}/g, function(m:any, p:any) { return c[p]; }) };
    const link = document.createElement('a');
    link.download =`Replenishment-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
      this.getCurrentDate()}.xls`;
    link.href = uri + base64(template);
    link.click();
  }

  etiquettesExportFromXML(){
    const uri = 'data:application/vnd.ms-excel;base64,';
    let listRow=3;
    let listData= this.etiquetteList.map(x=>{
      return `<Row ss:Index="${listRow++}" ss:Height="15.6">
       <Cell ss:StyleID="S25"><Data ss:Type="Number">${x.troir==null?'':x.troir}</Data>
       </Cell>
       <Cell ss:StyleID="S26"><Data ss:Type="Number">${x.location==null?'':x.location}</Data>
       </Cell>
       <Cell ss:StyleID="S27"><Data ss:Type="String">${x.primaryMedName==null?'':x.primaryMedName}</Data>
       </Cell>
       <Cell ss:StyleID="S26"><Data ss:Type="String">${x.medId==null?'':x.medId}</Data>
       </Cell>
       <Cell ss:StyleID="S26"><Data ss:Type="String">${x.contenant==null?'':x.contenant}</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">${x.couvercles==null?'':x.couvercles}</Data>
       </Cell>
      </Row>`
    });
    let concatenateListData = listData.join('');
    const template=`<?xml version="1.0" encoding="utf-8"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:x2="urn:schemas-microsoft-com:office:excel2" xmlns:html="http://www.w3.org/TR/REC-html40" xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882">
    <x:ExcelWorkbook>
     <x:ActiveSheet>1</x:ActiveSheet>
    </x:ExcelWorkbook>
    <o:DocumentProperties>
     <o:Author>Masum Sbd</o:Author>
     <o:Created>2024-03-13T07:19:32Z</o:Created>
     <o:LastSaved>2024-03-20T06:19:13Z</o:LastSaved>
    </o:DocumentProperties>
    <Styles>
     <Style ss:ID="Default" ss:Name="Normal">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Arial" ss:Size="10" />
     </Style>
     <Style ss:ID="S21">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S22">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S23">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S24">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S25">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
     <Style ss:ID="S26">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
     <Style ss:ID="S27">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
     <Style ss:ID="S28">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
     <Style ss:ID="S29">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Bold="1" ss:Color="#000000" ss:FontName="Calibri" ss:Size="16" />
     </Style>
     <Style ss:ID="S30">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S31">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S32">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S33">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S34">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S35">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S36">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#C0C0C0" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="11" />
     </Style>
     <Style ss:ID="S37">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:Color="#000000" ss:FontName="Calibri" ss:Size="16" />
     </Style>
    </Styles>
    <Worksheet ss:Name="Liste">
     <ss:Names />
     <ss:Table ss:DefaultRowHeight="13.2" ss:DefaultColumnWidth="48" ss:ExpandedRowCount="${this.etiquetteList.length+2}" ss:ExpandedColumnCount="6">
      <Column ss:Index="1" ss:AutoFitWidth="0" ss:Width="40.5" />
      <Column ss:Index="2" ss:AutoFitWidth="0" ss:Width="41.25" />
      <Column ss:Index="3" ss:AutoFitWidth="0" ss:Width="246.75" />
      <Column ss:Index="4" ss:AutoFitWidth="0" ss:Width="93" />
      <Column ss:Index="5" ss:AutoFitWidth="0" ss:Width="56.25" />
      <Column ss:Index="6" ss:AutoFitWidth="0" ss:Width="60" />
      <Row ss:Index="1" ss:Height="21">
       <Cell ss:MergeAcross="5" ss:MergeDown="0" ss:StyleID="S29"><Data ss:Type="String">${this.modelNo}${this.synmedRobotNo}-${this.etuqueteModelTitle}${this.machineNo} Uniprix Beaucage, French-Canada</Data>
       </Cell>
      </Row>
      <Row ss:Index="2" ss:Height="14.4">
       <Cell ss:StyleID="S21"><Data ss:Type="String">Tiroir</Data>
       </Cell>
       <Cell ss:StyleID="S22"><Data ss:Type="String">No</Data>
       </Cell>
       <Cell ss:StyleID="S23"><Data ss:Type="String">Nom primaire</Data>
       </Cell>
       <Cell ss:StyleID="S22"><Data ss:Type="String"># Med ID</Data>
       </Cell>
       <Cell ss:StyleID="S22"><Data ss:Type="String">Contenant</Data>
       </Cell>
       <Cell ss:StyleID="S24"><Data ss:Type="String">Couvercles</Data>
       </Cell>
      </Row>

      ${concatenateListData}
      
     </ss:Table>
     <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
      <PageSetup>
       <Header x:Margin="0.3" />
       <Footer x:Margin="0.3" x:Data="&amp;L&#x000d;&amp;1#&amp;&quot;Calibri&quot;&amp;8&amp;K404041 BD Restricted" />
       <PageMargins x:Top="0.75" x:Bottom="0.75" x:Left="0.7" x:Right="0.7" />
      </PageSetup>
      <Print>
       <PaperSizeIndex>9</PaperSizeIndex>
       <HorizontalResolution>600</HorizontalResolution>
       <VerticalResolution>600</VerticalResolution>
      </Print>
      <Panes>
       <Pane>
        <Number>3</Number>
        <ActiveRow>9</ActiveRow>
        <ActiveCol>2</ActiveCol>
        <RangeSelection>R10C3</RangeSelection>
       </Pane>
      </Panes>
     </WorksheetOptions>
    </Worksheet>
    <Worksheet ss:Name="Couvercles">
     <ss:Names />
     <ss:Table ss:DefaultRowHeight="13.2" ss:DefaultColumnWidth="48" ss:ExpandedRowCount="${this.etiquetteExportList.length+2}" ss:ExpandedColumnCount="19">
      <Column ss:Index="2" ss:AutoFitWidth="0" ss:Width="70" />
      <Column ss:Index="19" ss:AutoFitWidth="0" ss:Width="62.25" />
      <Row ss:Index="1" ss:Height="21">
       <Cell ss:MergeAcross="18" ss:MergeDown="0" ss:StyleID="S37"><Data ss:Type="String">${this.modelNo}${this.synmedRobotNo}-${this.etuqueteModelTitle}${this.machineNo} Uniprix Beaucage, French-Canada</Data>
       </Cell>
      </Row>
      <Row ss:Index="2" ss:Height="14.4">
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S30"><Data ss:Type="String">Tiroir --&gt;</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">1</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">2</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">3</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">4</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">5</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">6</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">7</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">8</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">9</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">10</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">11</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">12</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">13</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">14</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">15</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="Number">16</Data>
       </Cell>
       <Cell ss:StyleID="S30"><Data ss:Type="String">Total général</Data>
       </Cell>
      </Row>
      
      ${this.generateXmlRows()}
     </ss:Table>
     <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
      <PageSetup>
       <Header x:Margin="0.3" />
       <Footer x:Margin="0.3" x:Data="&amp;L&#x000d;&amp;1#&amp;&quot;Calibri&quot;&amp;8&amp;K404041 BD Restricted" />
       <PageMargins x:Top="0.75" x:Bottom="0.75" x:Left="0.7" x:Right="0.7" />
      </PageSetup>
      <Print>
       <PaperSizeIndex>9</PaperSizeIndex>
       <HorizontalResolution>600</HorizontalResolution>
       <VerticalResolution>600</VerticalResolution>
      </Print>
      <Panes>
       <Pane>
        <Number>3</Number>
        <ActiveRow>12</ActiveRow>
        <ActiveCol>12</ActiveCol>
        <RangeSelection>R13C13</RangeSelection>
       </Pane>
      </Panes>
     </WorksheetOptions>
    </Worksheet>
   </Workbook>
     
    `
    const base64 = function(s:any) { return window.btoa(unescape(encodeURIComponent(s))) };
    const format = function(s:any, c:any) { return s.replace(/{(\w+)}/g, function(m:any, p:any) { return c[p]; }) };
    const link = document.createElement('a');
    link.download =`Preparation_etiquettes-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
      this.getCurrentDate()}.xls`;
    link.href = uri + base64(template);
    link.click();
  }

  generateXmlRows(): string {
    let xmlRows = '';
    let couvercleRow=3;
    this.etiquetteExportList.forEach((item) => {
      xmlRows += `<Row ss:Index="${couvercleRow++}" ss:Height="14.4">\n`;
      xmlRows += `<Cell ss:StyleID="S30"><Data ss:Type="String">${item.itemOne == null ? '' : item.itemOne}</Data></Cell>\n`;
      xmlRows += `<Cell ss:StyleID="S30"><Data ss:Type="String">${item.itemTwo == null ? '' : item.itemTwo}</Data></Cell>\n`;

      xmlRows += this.createCellWithData(item.one);
      xmlRows += this.createCellWithData(item.two);
      xmlRows += this.createCellWithData(item.three);
      xmlRows += this.createCellWithData(item.four);
      xmlRows += this.createCellWithData(item.five);
      xmlRows += this.createCellWithData(item.six);
      xmlRows += this.createCellWithData(item.seven);
      xmlRows += this.createCellWithData(item.eight);
      xmlRows += this.createCellWithData(item.nine);
      xmlRows += this.createCellWithData(item.ten);
      xmlRows += this.createCellWithData(item.eleven);
      xmlRows += this.createCellWithData(item.twelve);
      xmlRows += this.createCellWithData(item.thirteen);
      xmlRows += this.createCellWithData(item.forteen);
      xmlRows += this.createCellWithData(item.fifteen);
      xmlRows += this.createCellWithData(item.sixteen);
      xmlRows+=`<Cell ss:StyleID="S30"><Data ss:Type="Number">${item.totalGeneral}</Data></Cell>\n`
      xmlRows += '</Row>\n';
    });

    return xmlRows;
  }

  createCellWithData(data: number): string {
    if (data != null && data > 0) {
      return `<Cell ss:StyleID="S32"><Data ss:Type="Number">${data}</Data></Cell>\n`;
    } else {
      return '<Cell ss:StyleID="S32" />\n';
    }
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {  
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    return currentDate.toLocaleDateString('en-US',options);
  }
}


