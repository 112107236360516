// app constant kvp
export const appConst = {
  PHARMACY_MANAGER: 'PM',
  IMPLEMENTATION_MANAGER: 'IM',
  AVAILABLE: 'AVAILABLE',
  NOT_FOUND: 'NOT FOUND',
  NEEDS_CLASSIFICATION: 'NEEDS CLASSIFICATION',
  NOT_COMPATIBLE: 'NOT COMPATIBLE',
  MAX: 'MAX',
  Mini: 'Mini',
  C_II: 'C-II',
  C_III: 'C-III',
  C_IV: 'C-IV',
  C_V: 'C-V',
  PAPER: 'paper',
  INVENTORIES_PER_PAGE: 1000,
  INVENTORIES_MAX_DATA: 5000, // Realistic cut off value
};
export const orgType = {
  INTERNAL :'Internal',
  EXTERNAL:'External'
}
export const sessionKeys = {
  REMIND_LATER: 'remindLater',
  POPUP_RELOAD_TIME: 'popupReloadTime',
  FAMILY_NAME: 'family_name',
};

export const routePaths = {
  PROFILE: 'profile',
  HOME: '',

  // IM route paths
  DASHBOARD: 'dashboard',
  CUSTOMER_MANAGEMENT: 'customer-management',
  EDIT_CUSTOMER: 'customer-management/:id',
  FLEET_MANAGEMENT: 'fleet-management',
  PREPROVISIONED: 'pre-provisioned',
  PROVISIONED: 'provisioned',
  DRUG_MANAGEMENT: 'drug-management',
  IM_TOOL: 'im-tool',
  EXPORT_IM_TOOL: 'im-tool/export/:customerid',
  EXPORT_ATP_IM_TOOL: 'im-tool/atp-export/:customerid',
  EXPORT_SYNMED_IM_TOOL: 'im-tool/synmed-export/:customerid',

  // PM route paths
  PHARMACY: 'pharmacy',
  REPORT_SUBSCRIPTION: 'reportsubscription',
  REPORTS: 'reports/:reportId',
  ORDER_SETUP: 'ordersetup',
  ORDER_MODIFY: 'ordermodify',
  MASTER_BATCH: 'master-batch',
  SCRIPT_ROUTING: 'script-routing',
  STRATUS_SETUP: 'stratus-setup',
  INVENTORY_ATP: 'inventory/atp',
  INVENTORY_MAX: 'inventory/max',
  INVENTORY_PASS: 'inventory/pass',
};

export enum Menu {
  GearIconUserManagement = 'GearIcon-UserManagement',
  GearIconPermissionGroup = 'GearIcon-PermissionGroup',
  pharmacypeek = 'PharmacyPeek',
  AddNewOrganization = 'AddNewOrganization',
  AddNewSite = 'AddNewSite',
}

// Toast notification types for panel
export enum ToastPanelType {
  done = 'badge_success',
  warning = 'badge_warning',
  error = 'badge_alert',
  info = 'badge_info',
  basic = 'badge_basic'
}

// Exclude control in result page
export enum ExcludeControl {
  cii = 'CII',
  ciii = 'CIII',
  civ = 'CIV',
  cv = 'CV'
}

// Exclude package in result page
export enum ExcludePackage {
  none = 'None',
  thirty = '30',
  sixty = '60',
  ninety = '90',
  hundred = '100',
  oneTwenty = '120',
  twoFifty = '250',
  fiveHundred = '500',
  thousand = '1000',
}

// Files status
export enum FileStatus {
  process = 1,
  error = 2,
  archive = 3,
}

// empty file, mandatory column(NDC) in drug data
export enum FileValidationCode {
  emptyFile = 1,
  columnNotFound = 2,
}

// to find if the notification is popup or email
export enum Notification {
  popUp = 1,
  email = 2,
}
