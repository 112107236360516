import { HttpErrorResponse } from '@angular/common/http';
import { Component,  HostListener, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AddCddbDrugs, CddbDrugs } from '../../models/cddb-drugs';
import { IMToolService } from '../../services/im-tool.service';
import { ImToolAtpService } from '../../services/im-tool-atp.service';
import * as constants from 'src/app/config/app-constants';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-import-wizard-cddb',
  templateUrl: './import-wizard-cddb.component.html',
  styleUrls: ['./import-wizard-cddb.component.scss']
})
export class ImportWizardCddbComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  customerId:string = "";
  deviceId:number;
  cddbDrugList:CddbDrugs[]=[];
  cddBDrugModel:AddCddbDrugs;
  searchText:string="";
  dataSource =new MatTableDataSource<CddbDrugs>();
  selectedRow:any;
  isAnyDrugSelected:boolean= false;


  @HostListener("window:resize")
  onResize() {
    if (window.innerWidth > 900) {
    }
  }
  constructor(private imtoolService: IMToolService,
    private imtoolAtpService: ImToolAtpService,
    public dialogRef: MatDialogRef<ImportWizardCddbComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.customerId = data.customerId;
      this.deviceId = data.deviceId;
     }
 //#region Table colums Array
 displayedColumns: string[] = [
  "checkbox",
  "sl",
  "ndc",
  "name",
  "strength",
  "manufacturer",
];
//#endregion
//#region Pagination properties
totalRows: number = 0;
currentPage: number = 0;
pageSize: number = 50;
pageSizeOptions: number[] = [50, 100, 200, 500];

pageChanged(event: PageEvent) {
  this.pageSize = event.pageSize;
  this.currentPage = event.pageIndex;
  this.getCddbDrugsData(this.searchText);
}
//#endregion

//#region Sorting Properties
sortDir:string="asc";
sortColumnName:string="";
@HostListener("matSortChange", ["$event"])
sortChange(event) {
  this.sortColumnName = event.active;
  this.sortDir = event.direction;
  this.getCddbDrugsData(this.searchText);
}

//#endregion
  ngOnInit(): void {
  }

  closeDialog(response:boolean){
    this.dialogRef.close({event:'close', data:response })
  }

  allSelectedDrugUnchecked(){
    if (this.cddbDrugList.length>0){
      for (let i = 0; i < this.cddbDrugList.length; i++) {
        let index = this.dataSource.data.findIndex(
          (x) => x.ndc == this.cddbDrugList[i].ndc
        );
        this.dataSource.data[index].checked = false;
      }
      this.cddbDrugList=[];
    }
  }
  drugCellRowChecked(row: any, isChecked:boolean){
    if (isChecked) {
      this.cddbDrugList.push({
        sl: row.sl,
        ndc: row.ndc,
        name: row.name,
        strength: row.strength,
        manufacturer: row.manufacturer,
        checked: row.checked,
      });
      this.isAnyDrugSelected=true;
    } else {
      let index = this.cddbDrugList.findIndex(
        (x) => x.ndc == row.ndc
      );
      this.cddbDrugList.splice(index, 1);
      if(this.cddbDrugList.length==0){
        this.isAnyDrugSelected = false;
      }
    }
  }

  addFromCddb(deviceId:number){
    if(deviceId===1){
      this.savecddbDrugs();
    }
    else if(deviceId===3){
      this.savecddbDrugsAtp();
    }
    else if(deviceId===4){
      this.savecddbDrugsSynmed();
    }
  }

  savecddbDrugs(){
    this.ngxLoader.start();
    this.cddBDrugModel={
      customerId:this.customerId,
      drugs: this.cddbDrugList
    }
    this.imtoolService.addCddbDrugs(this.cddBDrugModel).subscribe((result)=>{
      this.ngxLoader.stop();

      if(result.statusCode==200){
        this.toastService.openToast(result.message, constants.ToastPanelType.done, 2);
        this.searchText = '';
        this.dataSource.data= [];

      }
      else{
            this.toastService.openToast(result.message, constants.ToastPanelType.error, 2);

      }
    },(error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }

  savecddbDrugsAtp(){
    this.ngxLoader.start();
    this.cddBDrugModel={
      customerId:this.customerId,
      drugs: this.cddbDrugList
    }
    this.imtoolAtpService.addCddbDrugsAtp(this.cddBDrugModel).subscribe((result)=>{
      this.ngxLoader.stop();

      if(result.statusCode==200){
        this.toastService.openToast(result.message, constants.ToastPanelType.done, 2);
        this.searchText = '';
        this.dataSource.data= [];

      }
      else{
            this.toastService.openToast(result.message, constants.ToastPanelType.error, 2);

      }
    },(error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }

  savecddbDrugsSynmed(){
    this.ngxLoader.start();
    this.cddBDrugModel={
      customerId:this.customerId,
      drugs: this.cddbDrugList
    }
    this.imtoolService.addCddbDrugsSynmed(this.cddBDrugModel).subscribe((result)=>{
      this.ngxLoader.stop();

      if(result.statusCode==200){
        this.toastService.openToast(result.message, constants.ToastPanelType.done, 2);
        this.searchText = '';
        this.dataSource.data= [];

      }
      else{
            this.toastService.openToast(result.message, constants.ToastPanelType.error, 2);

      }
    },(error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }

  getCddbDrugsData(searchValue:string){
    if(searchValue.length==0){
      this.dataSource.data= [];
    }
    if(searchValue.length>=3){
      this.ngxLoader.start();
      this.imtoolService.getCddbDrugsData(searchValue, this.currentPage == 0 ? 1 : this.currentPage, this.pageSize,
        this.sortDir==null? "asc": this.sortDir).subscribe((response)=>{
          let result = response.data
        this.dataSource.data= [];
        this.dataSource.data = result.data == undefined? [] : result.data;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = result.totalRows;
        });
        this.ngxLoader.stop();
      },(error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.dataSource.data= [];
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      })
    }
  }

}
