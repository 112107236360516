import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { getFleetConfig } from 'src/app/store/selectors/fm-configuration.selector';
import { State } from 'src/app/store/state';
import { FleetConfigurationService } from '../../services/fleet-configuration.service';
import { FleetManagerActions } from 'src/app/store/actions';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { ConfirmDialogModel, GenericConfirmationDialogComponent } from 'src/app/shared/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { OrganizationService } from 'src/app/internal-user/customer-management/services/organization.service';
import { Observable, map, of, startWith } from 'rxjs';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';
import { AssetService } from 'src/app/external-user/inventory/services/asset.service';
import { Asset } from 'src/app/external-user/inventory/models/asset';
import { AutoCompleteDropDownValidators } from 'src/app/shared/directives/validate-selectedvalue-in-collection';
import { FleetManagementService } from '../../services/fleet-management.service';
import { Packager } from '../../models/provisioned.model';
import { AutoCompleteField } from 'src/app/shared/models/auto-complete-field';

@Component({
  selector: 'app-system-configuration-dialog',
  templateUrl: './system-configuration-dialog.component.html',
  styleUrls: ['./system-configuration-dialog.component.scss']
})

export class SystemConfigurationDialogComponent implements OnInit {
  deploymentList: any[] = [];
  templateDetails: any = {};
  items: any[] = [];
  recordCount: number = 0;
  panelOpenState: boolean = false;  
  deploymentUserList: any[]=[];
  
  sites: Organization[];
  systems = new Map<string, Asset[]>();
  provisionedSystems: Packager[];
  filteredOrg: Observable<Organization[]>;
  filteredSites: Observable<Organization[]>[] = [];
  filteredSystems: Observable<Asset[]>[] = [];

  systemconfigForm = this.fb.group({
    deploymentTemplate: ['', Validators.required],
    customerId: ['', [Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(this.parentCompData?.organizations)]],
    filters: this.fb.array([])
  });
  
  get deploymentTemplate() { return this.systemconfigForm.get('deploymentTemplate'); }
  get customerId() { return this.systemconfigForm.get('customerId'); }

  constructor(
    public store: Store<State>,
    public fleetConfigService: FleetConfigurationService,
    public fleetMgmtService: FleetManagementService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<SystemConfigurationDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public parentCompData: any,    
    private fb: FormBuilder,
    public organizationService: OrganizationService,
    public assetService: AssetService,
  ) { }

  ngOnInit() {

    this.filteredOrg = this.systemconfigForm.get('customerId')?.valueChanges.pipe(
      startWith(''),
      map(value => this._filterOrg(value || '')),
    );

    this.deploymentTemplate.valueChanges.subscribe((templateName) => this.updateTemplate(templateName));

    this.store.select(getFleetConfig).subscribe(deploymentOptions => {
      if (!deploymentOptions) {
        return;
      }

      this.deploymentList = deploymentOptions.templates.map(template => {
        return {
          viewData: template,
          formData: template
        }
      });

      this.templateDetails = deploymentOptions.templateDetails = Object.keys(deploymentOptions.templateDetails).reduce((newObj, key) => {
        newObj[key] = deploymentOptions.templateDetails[key];
        return newObj;
      }, {});

      for (const key in this.templateDetails) {
        if (this.templateDetails.hasOwnProperty(key)) {    
          this.templateDetails[key].Username.forEach(username => {
            let userList = {
              deploymentType: key,
              userName: username
            }
            this.deploymentUserList.push(userList);
          });
        }
      }

      this.deploymentTemplate.setValue(this.deploymentList[0].formData, {
        onlySelf: true
      });
    });

    this.getProvisionedSystems();
  }

  private getProvisionedSystems() {
    this.fleetMgmtService.getProvisionedSystems()
      .subscribe({
        next: (systems) => {         
          this.provisionedSystems = systems; 
        }
      });
  }

  private _filterOrg(value: string): Organization[] {
    const filterValue = this.formatToLowerCase(value);    
    return this.parentCompData?.organizations?.filter(org => org.name.toLowerCase().includes(filterValue));    
  }

  private _filterSite(value): Organization[] {    
    let filterValue = this.formatToLowerCase(this.getSelectedValue(value, "customerNo"));
    return this.sites?.filter(org => org?.customerNo?.toLowerCase()?.includes(filterValue) || org?.name?.toLowerCase()?.includes(filterValue) || `${org?.name?.toLowerCase()} (${org?.customerNo?.toLowerCase()})`?.includes(filterValue));
  }

  private _filterSystems(value, index: number = -1): Asset[] {    
    let filterValue = this.formatToLowerCase(this.getSelectedValue(value, "machineNo"));
    var site = this.formatToLowerCase(this.getSelectedValue(this.systemFormArray?.controls[index]?.get('siteId')?.value, "customerNo"));
    return this.GetSystems(site)?.filter(sys => sys?.machineNo?.toLowerCase()?.includes(filterValue) || sys?.name?.toLowerCase()?.includes(filterValue)  || `${sys?.name?.toLowerCase()} (${sys?.machineNo?.toLowerCase()})`?.includes(filterValue));
  }

  getOrganization(name: string, index: number = -1) {
    var org = this.parentCompData?.organizations?.find(x => x.name?.toLowerCase() === name?.toLowerCase());    
    if(org != null) {      
      this.loadSites(org.organizationId, index);
    }
  }

  loadSites(orgId, index) {
    this.organizationService.getSitesByParentOrgId(orgId)
      .subscribe({next: (sites) => {  
        // Clear the site and systems on Organization Name change
         this.clearSiteAndSystemDetails();
        this.sites = sites.filter(site => site.customerNo != null && site.enabled === true);   
        this.resetSiteValidators();
      }, error: (e) => {
        // Clear the site and systems on Organization Name change
        this.clearSiteAndSystemDetails();
        this.resetSiteValidators();
      }});
  }

  displaySite = option => {
    return this.getSelectedValue(option, "customerNo");
  }

  displaySystem = option => {
    return this.getSelectedValue(option, "machineNo");
  }

  getSite(option: any, index: number = -1) {     
    let searchValue = this.formatToLowerCase(this.getSelectedValue(option, "customerNo"));
    let site = this.sites?.find(x => `${x.name} (${x.customerNo})`?.toLowerCase() === searchValue);
    
    if(site != null){      
      this.clearSystems(index);
      this.loadSystems(site, index);
    }
  }

  getSelectedValue(option, caseType) : string {
    if(typeof option === 'string') {
      return option;  
    } else {
      switch(caseType) {
        case "customerNo": {
            return option ? `${option?.name} (${option?.customerNo})` : '';             
        }
        case "machineNo": {
          return option ? `${option?.name} (${option?.machineNo})`: '';             
        }        
      }
    }
  }

  formatToLowerCase(option: string) : string {
    return option === null || option.length === 0 ? '' : option?.toLowerCase();
  }

  loadSystems(site, index) {
    let siteKey = `${site.name} (${site.customerNo})`?.toLowerCase();
    if (!this.systems.has(siteKey)) {
      this.assetService.getAssetByOrgId(site.organizationId).subscribe({next: (_assets) => {
        _assets = _assets?.filter(asset => asset.enabled === true);      
        _assets = this.disableProvisionedSystems(_assets);        
        this.systems?.set(siteKey, _assets ? _assets : null);
        this.resetSystemValidators(index, _assets);                                    
      }, error: (response) => { }});
    }
    else {
      this.resetSystemValidators(index, this.GetSystems(siteKey));                
    }
  }

  disableProvisionedSystems(assets) {
    assets?.forEach((element) => {
      const system = this.provisionedSystems?.find(system => system?.systemId?.toLowerCase() === element?.machineNo?.toLowerCase());
      if(system != null) {
        element.isProvisioned = true;
      }
      else {
        element.isProvisioned = false;
      }
    });
    return assets;    
  }

  clearSiteAndSystemDetails() {
    this.sites = [];
    this.systems.clear();
    this.systemFormArray.controls.forEach((element, index) => {
      this.filteredSites.splice(index, 1);      
      this.filteredSystems.splice(index, 1);
      
      var siteIdTouched = element.get('siteId').touched;
      var deviceIdTouched = element.get('deviceId').touched;

      element.get('siteId').reset();
      element.get('deviceId').reset();

      if(siteIdTouched) {
        element.get('siteId').markAsTouched();
      }

      if (deviceIdTouched) {
        element.get('deviceId').markAsTouched();
      }
    });
  }

  clear(ctrl){    
    ctrl.setValue('');
    this.clearSiteAndSystemDetails();
  }

  resetSiteValidators() {
    this.systemFormArray.controls.forEach((element, index) => {      
      element.get('siteId').clearValidators();      
      element.get('siteId').setValidators([Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(this.sites, AutoCompleteField.customerNo)]);      

      this.filteredSites[index] = this.systemFormArray?.controls[index]
                                .get('siteId')?.valueChanges.pipe(
                                startWith(''), 
                                map(value => this._filterSite(value || '')));
    });    
  }

  clearSystems(index) {          
    this.filteredSystems[index] = of([]);    
    var touched = this.systemFormArray?.controls[index]?.get('deviceId')?.touched;
    
    this.systemFormArray?.controls[index]?.get('deviceId')?.reset();

    if(touched) {
      this.systemFormArray?.controls[index]?.get('deviceId')?.markAsTouched(); 
    }
  }
  
  resetSystemValidators(index, systems) {
    this.systemFormArray?.controls[index]?.get('deviceId')?.clearValidators();                
      
      this.filteredSystems[index] = this.systemFormArray?.controls[index]?.get('deviceId')?.valueChanges
                  .pipe( startWith(''), map(value => this._filterSystems(value || '', index)));
                
      this.systemFormArray?.controls[index]?.get('deviceId')?.setValidators([Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(systems, AutoCompleteField.machineNo)]);     
  }

  GetSystems(item) {
    return this.systems.get(item);
  }
  
  addPanel() { 
    if (this.systemconfigForm.get('customerId')?.errors) {
      this.systemconfigForm.markAllAsTouched();
      this.systemFormArray.markAllAsTouched();
      this.clearSiteAndSystemDetails();
      return;
    }

    if(this.systemFormArray.invalid){
      this.systemFormArray.markAllAsTouched();      
      return;
    }

    if(this.items.some(item => item.title === this.systemconfigForm.get('deploymentTemplate')?.value))
      return false;    
    else {
      this.recordCount = this.systemFormArray.controls.length;
      if (this.recordCount > 14) {
        this.toastService.openToast('Deployment limit is set to 15', constants.ToastPanelType.error);
        return;
      }
      else {
        this.items.unshift({
          title: this.systemconfigForm.get('deploymentTemplate')?.value,
          rowcount: 0
        });
        this.addSystem(this.systemconfigForm.get('deploymentTemplate')?.value);
      }
    }
  }

  addSystem(systemName) {
    if (this.systemconfigForm.get('customerId')?.errors) {
      this.systemconfigForm.markAllAsTouched();
      this.systemFormArray.markAllAsTouched();
      this.clearSiteAndSystemDetails();
      return;
    }
    
    this.panelOpenState = true;
    this.recordCount = 0;
    this.recordCount = this.systemFormArray.controls.length;
    
    if (this.recordCount > 14) {
      this.toastService.openToast('Deployment limit is set to 15', constants.ToastPanelType.error);
      return;
    }
    else {
      this.systemFormArray.push(this.createSystemGroup(systemName));
      this.filteredSites[this.systemFormArray.length - 1] = this.systemFormArray?.controls[this.systemFormArray.length - 1]
                                .get('siteId')?.valueChanges.pipe(
                                startWith(''), 
                                map(value => this._filterSite(value || '')));

      this.filteredSystems[this.systemFormArray.length - 1] = this.systemFormArray?.controls[this.systemFormArray.length - 1]
                                .get('deviceId')?.valueChanges.pipe(
                                startWith(''), 
                                map(value => this._filterSystems(value || '', this.systemFormArray.length - 1)));

      let index = this.items.findIndex((x) => x.title == systemName);
      this.items[index].rowcount++;
    }
  }

  createSystemGroup(template) {
    return this.fb.group({
      template: [template],
      siteId: ['', [Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(this.sites, AutoCompleteField.customerNo)]],
      deviceId: ['', [Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(this.filteredSystems, AutoCompleteField.machineNo)]],
      ipaddress: ['', [Validators.required, Validators.pattern('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')]],
      user: ['', [Validators.required]]
    });
  }

  async valuesAdd(e: Event, deploymentName) {
    if (this.systemconfigForm.get('customerId')?.errors) {
      this.systemconfigForm.markAllAsTouched();
      this.systemFormArray.markAllAsTouched();
      this.getOrganization(this.systemconfigForm.get('customerId')?.value);
      return;
    }
    
    e.stopPropagation();

    if(this.systemFormArray.invalid) {
        this.systemFormArray.markAllAsTouched();      
        return;
    }
    
    if ((await this.checkForDuplicateIpWithinSiteAndProceed())) {
      this.addSystem(deploymentName);
    }
    
  }

  removeSystem(deploymentName, row) {
    let arrayValue = this.systemFormArray.controls[row].value;
    if (arrayValue.deviceId == null && arrayValue.ipaddress == null && arrayValue.siteId == null && arrayValue.user == null) {
      this.delete(deploymentName, row);
    }
    else {
      const message = "Are you sure you want to delete the selected system?"
      let matDialogRef = this.dialog.open(GenericConfirmationDialogComponent,
      {
        backdropClass: 'smDialog'          
      });
        
      matDialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult === true) {
          this.delete(deploymentName, row);
        }
      });
      let instance = matDialogRef.componentInstance;
      instance.title="Delete System";
      instance.message=message;
      instance.dialogRef = matDialogRef;
    }
  }

  get systemFormArray() {
    return (<FormArray>this.systemconfigForm.get('filters'));
  }

  delete(deploymentName, row) {
    let index = this.items.findIndex((x) => x.title == deploymentName)
    this.items[index].rowcount--;
    this.systemFormArray.removeAt(row);    
    if (this.items[index].rowcount == 0)
      this.items.splice(index, 1);
  }

  save() {
    
    if (this.systemconfigForm.get('customerId')?.errors) {
      this.systemconfigForm.markAllAsTouched();
      this.systemFormArray.markAllAsTouched();
      return;
    }
    else if((this.systemFormArray.controls.length == 0))
    {
      this.toastService.openToast("Please provide at least one system (SiteId, SystemID, HostName) to configure.", constants.ToastPanelType.error);
      return;
    }
    else if(this.systemconfigForm.invalid || this.systemFormArray.invalid)
    {
      this.systemconfigForm.markAllAsTouched();
      this.systemFormArray.markAllAsTouched();      
      return;
    }        

    var org = this.parentCompData?.organizations?.find(x => x.name?.toLowerCase() === this.systemconfigForm.get('customerId')?.value?.toLowerCase());
    let data =
    {
      EdgeDeviceID: this.parentCompData?.deviceConfigModel.edgeDeviceId,
      EdgeDeviceFriendlyName: '',
      CustomerId: org?.name,
      Systems: []
    }

    this.systemFormArray.controls.forEach((element, index) => {
      let siteKey = this.getSelectedValue(element.value.siteId, "customerNo").toLowerCase();
      let deviceKey = this.getSelectedValue(element.value.deviceId, "machineNo").toLowerCase();
      var site = this.sites?.find(x => `${x.name} (${x.customerNo})`.toLowerCase() === siteKey);
      var system = this.GetSystems(siteKey)?.find(sys => `${sys.name} (${sys.machineNo})`.toLowerCase() ===  deviceKey);
      let systemValue = {
        DeploymentType: element.value.template,
        RequestOptions: {
          SystemID: system?.machineNo,
          SystemFriendlyName: system?.name,
          SiteID: site?.customerNo,
          SiteName:site?.name,
          HostName: element.value.ipaddress,
          User: element.value.user
        },
      }
      data.Systems.push(systemValue);
    });    

    const loadingKey = 'SystemConfigurationSubmit';
    this.ngxLoader.start(loadingKey);

    this.fleetConfigService.postConfigTemplate(data).subscribe({
      next: () => {
        setTimeout(() => {
          this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());
          this.ngxLoader.stop(loadingKey);
          this.dialogRef.close('true');
        }, 5000);
        this.toastService.openToast('Systems configured successfully.', constants.ToastPanelType.done);
      },
      error: (error: any) => {
        this.ngxLoader.stop(loadingKey);
        if (error.status === 500) {
          this.toastService.openToast('Unable to configure systems!', constants.ToastPanelType.error);
        }
        else {
          this.toastService.openToast(error.error, constants.ToastPanelType.error);
        }
      }
    });
  }

  updateTemplate(templateName): void {
    let templateOptions = this.templateDetails[templateName];
  }

  checkForDuplicateIpWithinSiteAndProceed(): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    const currentIndex = this.systemFormArray.length - 1;
    const currentFormGroup = this.systemFormArray?.controls[currentIndex];

    const site = currentFormGroup.get('siteId')?.value;
    const ipAddress = currentFormGroup.get('ipaddress')?.value;

    // Clear duplicateIp error if any before checking 
    currentFormGroup.get('ipaddress')?.setErrors(null);

    // Check for duplicate IPs at the same site within the current configuration
    const duplicateIps = this.systemFormArray.controls.some((control, index) => {
      if (index !== currentIndex) { // Exclude the current form group
        const siteValue = control.get('siteId')?.value;
        const ipValue = control.get('ipaddress')?.value;
        return siteValue?.customerNo === site?.customerNo && ipValue === ipAddress;
      }
      return false;
    });

    if (duplicateIps) {
      const message = `IP ${ipAddress} is already in use at the site ${site.name}. Are you sure you want to proceed?`;
      this.showDuplicateIpDialog(message).then((dialogResult) => {
        if (!dialogResult) {
          // User chose not to proceed, set custom validation error for duplicate IP
          currentFormGroup.get('ipaddress')?.setErrors({ duplicateIp: true });
        }
        resolve(dialogResult);
      });
    } else {
      // Proceed with checking for duplicate IPs on different device. Fetch from backend
      this.fleetMgmtService.getSystemsInSiteByIp(site.customerNo, ipAddress).subscribe(
        (response) => {
          if (response.length >= 1) {
            const message = `IP ${ipAddress} is already in use at the site ${site.name}. Are you sure you want to proceed?`;
            this.showDuplicateIpDialog(message).then((dialogResult) => {
              if (!dialogResult) {
                // User chose not to proceed, set custom validation error for duplicate IP
                currentFormGroup.get('ipaddress')?.setErrors({ duplicateIp: true });
              }
              resolve(dialogResult);
            });
          } else {
            resolve(true); // No duplicate IP found, proceed
          }
        }
      );
    }
  });
}

  
  showDuplicateIpDialog(message: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let matDialogRef = this.dialog.open(GenericConfirmationDialogComponent, {
        backdropClass: 'smDialog'
      });
  
      matDialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult === true);
      });
  
      let instance = matDialogRef.componentInstance;
      instance.title = "Duplicate IP Address";
      instance.message = message;
      instance.dialogRef = matDialogRef;
    });
  }

  getSystemIdErrorMessage(index): string | null {
    var site = this.formatToLowerCase(this.getSelectedValue(this.systemFormArray?.controls[index]?.get('siteId')?.value, "customerNo"));
    var length = this.GetSystems(site)?.length;
        
    if ((length == null || length == 0) && this.getSelectedValue(this.systemFormArray?.controls[index]?.get('deviceId')?.value, "machineNo").trim() == ''){
      return 'No Serial Number to provision';
    } else if (this.systemFormArray.controls[index].get('deviceId').hasError('required')){
      return 'Serial Number required';
    }
    else if (this.systemFormArray.controls[index].get('deviceId')?.hasError('ShowValidatonError'))  {
      return 'Serial Number is invalid';
    }
    else {
      return null; // Return null if no error message is applicable
    }
  }
}
