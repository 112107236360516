/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { FleetManagementComponent } from './components/fleet-management.component';
import { routePaths } from '../../config/app-constants';
import { PreProvisionedComponent } from './components/pre-provisioned/pre-provisioned.component';
import { ProvisionedComponent } from './components/provisioned/provisioned.component';

const routes: Routes = [
  {
    path: routePaths.FLEET_MANAGEMENT,
    component: FleetManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pre-provisioned',
    component: PreProvisionedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'provisioned',
    component: ProvisionedComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FleetManagementRoutingModule { }
