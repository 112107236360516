import { InventoryEffect } from './inventory.effect';
import { FleetManagerEffect } from './fleet-manager.effect';
// import { PharmacyPeekWidgetsEffect } from './pharmacy-peek-widgets.effect';


export const StoreEffects: any[] = [
    InventoryEffect,
    FleetManagerEffect,
    // PharmacyPeekWidgetsEffect
];
