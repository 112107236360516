import { Component, OnInit } from '@angular/core';
import { Provisioned } from '../../models/provisioned.model';
import { GenericConfirmationDialogComponent } from 'src/app/shared/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FleetConfigurationService } from '../../services/fleet-configuration.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { FleetManagerActions } from 'src/app/store/actions';

@Component({
  selector: 'app-edge-appliance-details',
  templateUrl: './edge-appliance-details.component.html',
  styleUrls: ['./edge-appliance-details.component.scss']
})
export class EdgeApplianceDetailsComponent implements OnInit {
  
  parentCompData: Provisioned;
  title: string;
  edgeDeviceId:string;
  status:string;
  lastKnownOnlineTime: string;    

  constructor(
    private fleetConfigService: FleetConfigurationService
    , public store: Store<State>
    , private toastService: ToastService      
    , public dialog: MatDialog
    , private ngxLoader: NgxUiLoaderService) { }

  ngOnInit() { 
    this.edgeDeviceId = this.parentCompData?.edgeDeviceId;
    this.status = this.parentCompData?.connectionState;
    this.lastKnownOnlineTime = this.parentCompData?.lastOnlineTime;
    this.title = this.parentCompData?.organizationUI;
  }

  onDisable() {    
    const message = "Are you sure? Disabling an Edge Appliance will stop data being transferred and data will be lost after 72 hours."
    const dialogRef = this.dialog.open(GenericConfirmationDialogComponent,
    {       
      width: '250px',
      disableClose: true
    });
    let instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.title = "Disable edge device";
    instance.message = message;

    dialogRef.afterClosed().subscribe(dialogResult => {      
      if (dialogResult === true) 
      {
        const ngxLoaderKey = 'DisableDevice';    
        this.ngxLoader.start(ngxLoaderKey);
        this.fleetConfigService.disableDevice(this.edgeDeviceId).subscribe(
          (result) => {  
            this.status = "Disabled";
            this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());
            this.ngxLoader.stop(ngxLoaderKey);
            this.toastService.openToast('Disabled edge device successfully.', constants.ToastPanelType.done);
            console.log(result);
          },
          (error) => {
            this.toastService.stopLoadingAndShowError(error,
              'Failed to disable edge device!', ngxLoaderKey);        
          });
      }
    });
  }
}