<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">
  <div mat-dialog-title id="drug-details-header">
    <div class="flex justify-start content-center items-center flex-row">

      <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
        <h3>Add New Drug Wizard</h3>
      </div>
      <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
        <mat-icon (click)="this.dialogRef.close()" class="cross-wizard">
          <img src="/assets/img/icons/ico.16.clear.svg" alt>
        </mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="saveDrugForm" style="overflow: scroll;">
    <div style="padding: 1rem;  overflow: scroll;" class="flex flex-col">
      <div class="flex flex-row gap-[23px]">
        <mat-card>
          <mat-card-title class="flex justify-center content-stretch items-stretch">Front Image </mat-card-title>
          <mat-card-content class="flex justify-center content-stretch items-stretch">
            <div class="file-upload">
              <div class="selected-file">
                <img [src]="getBase64ImageSrc(frontImage)??defaultImage" alt="drug image">
              </div>
              <div *ngIf="frontImage!= null" style="text-align: center;">
                <button class="btn btn-active" (click)="selectedFrontFileRemove()">
                  <mat-icon>clear</mat-icon>Remove</button>
              </div>
              <div class="choose_file_syn" *ngIf="frontImage == null">
                <label for="choose_file_syn">
                  <input type="file" id="choose_file_syn" accept="image/png, image/gif, image/jpeg"
                    (change)="onFileSelected($event, 1)">
                  <span>SELECT ADD IMAGE</span>
                </label>
                <!-- <div fxLayout="column" (fileDropped)="onFileDropped($event)" fxLayoutAlign="start center">
                <div>
                    <img src="../../../../assets/img/icons/ico.24.export.svg" alt="">

                    <p>DRAG AND DROP A IMAGE</p>
                </div>
                <div fxFlex="25" fxLayoutAlign="center" style="padding: 15px 0px 0px 0px;">
                    <b> OR</b>
                </div>
                <div>
                  <label for="choose_file_syn">
                    <input type="file" id="choose_file_syn" accept=".png, .svg"  (change)="onFileSelected($event)" #fileUploadSynMed>
                    <span>SELECT ADD IMAGE</span>
                  </label>
                </div>

            </div> -->
              </div>

            </div>

          </mat-card-content>

        </mat-card>
        <mat-card>
          <mat-card-title class="flex justify-center content-stretch items-stretch">Back Image </mat-card-title>
          <mat-card-content class="flex justify-center content-stretch items-stretch">
            <div class="file-upload">
              <div class="selected-file">
                <img [src]="getBase64ImageSrc(backImage)??defaultImage" alt="drug image">
              </div>
              <div *ngIf="backImage!= null" style="text-align: center;">
                <button class="btn btn-active" (click)="selectedBackFileRemove()">
                  <mat-icon>clear</mat-icon>Remove</button>
              </div>
              <div class="choose_file_back" *ngIf="backImage == null">
                <label for="choose_file_back">
                  <input type="file" id="choose_file_back" accept="image/png, image/gif, image/jpeg"
                    (change)="onFileSelected($event, 2)">
                  <span>SELECT ADD IMAGE</span>
                </label>
                <!-- <div fxLayout="column" (fileDropped)="onFileDropped($event)" fxLayoutAlign="start center">
                <div>
                    <img src="../../../../assets/img/icons/ico.24.export.svg" alt="">

                    <p>DRAG AND DROP A IMAGE</p>
                </div>
                <div fxFlex="25" fxLayoutAlign="center" style="padding: 15px 0px 0px 0px;">
                    <b> OR</b>
                </div>
                <div>
                  <label for="choose_file_syn">
                    <input type="file" id="choose_file_syn" accept=".png, .svg"  (change)="onFileSelected($event)" #fileUploadSynMed>
                    <span>SELECT ADD IMAGE</span>
                  </label>
                </div>

            </div> -->
              </div>

            </div>

          </mat-card-content>

        </mat-card>
        <mat-card>
          <mat-card-title class="flex justify-center content-stretch items-stretch">Side Image </mat-card-title>
          <mat-card-content class="flex justify-center content-stretch items-stretch">
            <div class="file-upload">
              <div class="selected-file">
                <img [src]="getBase64ImageSrc(sideImage)??defaultImage" alt="drug image">
              </div>
              <div *ngIf="sideImage!= null" style="text-align: center;">
                <button class="btn btn-active" (click)="selectedSideFileRemove()">
                  <mat-icon>clear</mat-icon>Remove</button>
              </div>
              <div class="choose_file_side" *ngIf="sideImage == null">
                <label for="choose_file_side">
                  <input type="file" id="choose_file_side" accept="image/png, image/gif, image/jpeg"
                    (change)="onFileSelected($event,3)">
                  <span>SELECT ADD IMAGE</span>
                </label>
                <!-- <div fxLayout="column" (fileDropped)="onFileDropped($event)" fxLayoutAlign="start center">
                <div>
                    <img src="../../../../assets/img/icons/ico.24.export.svg" alt="">

                    <p>DRAG AND DROP A IMAGE</p>
                </div>
                <div fxFlex="25" fxLayoutAlign="center" style="padding: 15px 0px 0px 0px;">
                    <b> OR</b>
                </div>
                <div>
                  <label for="choose_file_syn">
                    <input type="file" id="choose_file_syn" accept=".png, .svg"  (change)="onFileSelected($event)" #fileUploadSynMed>
                    <span>SELECT ADD IMAGE</span>
                  </label>
                </div>

            </div> -->
              </div>

            </div>

          </mat-card-content>

        </mat-card>
        <mat-card>
          <mat-card-title class="flex justify-center content-stretch items-stretch">Top Image </mat-card-title>
          <mat-card-content class="flex justify-center content-stretch items-stretch">
            <div class="file-upload">
              <div class="selected-file">
                <img [src]="getBase64ImageSrc(topImage)??defaultImage" alt="drug image">
              </div>
              <div *ngIf="topImage!= null" style="text-align: center;">
                <button class="btn btn-active" (click)="selectedTopFileRemove()">
                  <mat-icon>clear</mat-icon>Remove</button>
              </div>
              <div class="choose_file_top" *ngIf="topImage == null">
                <label for="choose_file_top">
                  <input type="file" id="choose_file_top" accept="image/png, image/gif, image/jpeg"
                    (change)="onFileSelected($event,4)">
                  <span>SELECT ADD IMAGE</span>
                </label>
                <!-- <div fxLayout="column" (fileDropped)="onFileDropped($event)" fxLayoutAlign="start center">
                <div>
                    <img src="../../../../assets/img/icons/ico.24.export.svg" alt="">

                    <p>DRAG AND DROP A IMAGE</p>
                </div>
                <div fxFlex="25" fxLayoutAlign="center" style="padding: 15px 0px 0px 0px;">
                    <b> OR</b>
                </div>
                <div>
                  <label for="choose_file_syn">
                    <input type="file" id="choose_file_syn" accept=".png, .svg"  (change)="onFileSelected($event)" #fileUploadSynMed>
                    <span>SELECT ADD IMAGE</span>
                  </label>
                </div>

            </div> -->
              </div>

            </div>

          </mat-card-content>

        </mat-card>
      </div>
      <div class="drug-left-side">
        <h5>Characteristics</h5>
        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col basis-[30%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Generic Name</span>
            </div>

            <input formControlName="genericName" type="text">
            <div
              *ngIf="saveDrugForm.controls.genericName.hasError('required') && saveDrugForm.controls.genericName.touched">
              <mat-error *ngIf="saveDrugForm.controls.genericName.hasError('required')">Generic
                Name is required</mat-error>
            </div>
          </div>
          <!-- <div class="flex flex-col basis-[10%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Strength</span>
            </div>
            <input formControlName="strength" type="text">
            <div *ngIf="saveDrugForm.controls.strength.hasError('required') && saveDrugForm.controls.strength.touched">
              <mat-error *ngIf="saveDrugForm.controls.strength.hasError('required')">Strength
                is required</mat-error>
            </div>
          </div> -->
          <div class="flex flex-col basis-[10%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Strength</span>
            </div>
            <input formControlName="strength" type="text">
            <div *ngIf="saveDrugForm.controls.strength.touched">
              <mat-error *ngIf="saveDrugForm.controls.strength.errors?.required">Strength is required</mat-error>
              <mat-error *ngIf="!saveDrugForm.controls.strength.errors?.required && saveDrugForm.controls.strength.errors?.positiveRealNumber">
                Must be 1-9, decimal or combo (e.g., 1 / 2). No other symbols.
              </mat-error>
            </div>
          </div>

          <div class="flex flex-col basis-[10%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Unit</span>
            </div>

            <mat-form-field>
              <input type="text" aria-label="Number" matInput [formControl]="unit" [matAutocomplete]="autoUnit">
              <mat-autocomplete #autoUnit="matAutocomplete" [displayWith]="displayFnUnit.bind(this)">
                <mat-option *ngFor="let data of listofUnit" [value]="data.value">
                  {{data.label}}
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div *ngIf="saveDrugForm.controls.unit.hasError('required') && saveDrugForm.controls.unit.touched">
              <mat-error *ngIf="saveDrugForm.controls.unit.hasError('required')">Unit
                is required</mat-error>

            </div>
          </div>
          <div class="flex flex-col basis-[15%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Drug Number</span>
            </div>
            <input formControlName="drugId" type="text">
            <div *ngIf="saveDrugForm.controls.drugId.hasError('required') && saveDrugForm.controls.drugId.touched">
              <mat-error *ngIf="saveDrugForm.controls.drugId.hasError('required')">Drug
                Number is required</mat-error>
              <mat-error *ngIf="saveDrugForm.controls.drugId.hasError('maxlength')">Drug Number
                must be 11 Digit</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[15%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Drug Number Type</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="drugIdType" ngDefaultControl>
                <mat-option value="NDC 11 Digits">Drug Number 11 Digits</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.drugIdType.hasError('required') && saveDrugForm.controls.drugIdType.touched">
              <mat-error *ngIf="saveDrugForm.controls.drugIdType.hasError('required')">Drug
                Number Type is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[11%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Schedule</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="schedule" ngDefaultControl>
                <mat-option *ngFor="let data of listOfSchedule" [value]="data.label">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.schedule.hasError('required') && saveDrugForm.controls.schedule.touched">
              <mat-error *ngIf="saveDrugForm.controls.schedule.hasError('required')">Schedule
                is required</mat-error>
            </div>
          </div>
        </div>
        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col basis-[30%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Manufacturer</span>
            </div>
            <mat-form-field>
              <input type="text" aria-label="Number" matInput [formControl]="manufacturer"
                [matAutocomplete]="autoManufacturer">

              <mat-autocomplete #autoManufacturer="matAutocomplete" [displayWith]="displayFnManufacturer.bind(this)">
                <mat-option *ngFor="let data of listofManufacture" [value]="data.value">
                  {{data.label}}
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.manufacturer.hasError('required') && saveDrugForm.controls.manufacturer.touched">
              <mat-error *ngIf="saveDrugForm.controls.manufacturer.hasError('required')">Manufacturer
                is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[21%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Route Of Administration</span>
            </div>
            <mat-form-field>
              <input type="text" aria-label="Number" matInput [formControl]="routeOfAdministration"
                [matAutocomplete]="autoRoutOfAdministration">
              <mat-autocomplete #autoRoutOfAdministration="matAutocomplete"
                [displayWith]="displayFnRoutOfAddministration.bind(this)">
                <mat-option *ngFor="let data of listRouteOfAdmistration" [value]="data.value">
                  {{data.label}}
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.routeOfAdministration.hasError('required') && saveDrugForm.controls.routeOfAdministration.touched">
              <mat-error *ngIf="saveDrugForm.controls.routeOfAdministration.hasError('required')">Route
                Of Administration is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[10%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">OTC</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="otc" ngDefaultControl>
                <mat-option *ngFor="let data of listOfOtc" [value]="data.value">{{data?.value}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.otc.hasError('required') && saveDrugForm.controls.otc.touched">
              <mat-error *ngIf="saveDrugForm.controls.otc.hasError('required')">OTC
                is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Dosage Form</span>
            </div>
            <mat-form-field>
              <input type="text" aria-label="Number" matInput [formControl]="dosageForm"
                [matAutocomplete]="autodosageForm">
              <mat-autocomplete #autodosageForm="matAutocomplete" [displayWith]="displayFnDosageForm.bind(this)">
                <mat-option *ngFor="let data of listOfDosageForm" [value]="data.id">
                  {{data.description}}
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.dosageForm.hasError('required') && saveDrugForm.controls.dosageForm.touched">
              <mat-error *ngIf="saveDrugForm.controls.dosageForm.hasError('required')">Dosage
                Form is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[11%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Niosh</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="niosh" ngDefaultControl>
                <mat-option *ngFor="let data of listOfNiosh" [value]="data.value">{{data?.value}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.niosh.hasError('required') && saveDrugForm.controls.niosh.touched">
              <mat-error *ngIf="saveDrugForm.controls.niosh.hasError('required')">Niosh
                Form is required</mat-error>
            </div>
          </div>

        </div>

        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col basis-[30%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Storage Conditions</span>
            </div>
            <textarea formControlName="storageConditions" rows="5"></textarea>
            <div
              *ngIf="saveDrugForm.controls.storageConditions.hasError('required') && saveDrugForm.controls.storageConditions.touched">
              <mat-error *ngIf="saveDrugForm.controls.storageConditions.hasError('required')">Storage
                Condition is required</mat-error>
            </div>
          </div>
          <div class="basis-[65%]">
            <div style="margin-bottom: .8rem;" class="flex flex-row gap-[1rem]">
              <div class="flex flex-col basis-1/4">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Unit of Use</span>
                </div>

                <input formControlName="unitOfUse" [readonly]="true" type="text">
                <div
                  *ngIf="saveDrugForm.controls.unitOfUse.hasError('required') && saveDrugForm.controls.unitOfUse.touched">
                  <mat-error *ngIf="saveDrugForm.controls.unitOfUse.hasError('required')">Unit
                    of use is required</mat-error>
                </div>
              </div>
              <div class="flex flex-col basis-1/4">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Unit Dose</span>
                </div>
                <input formControlName="unitDose" [readonly]="true" type="text">
                <div
                  *ngIf="saveDrugForm.controls.unitDose.hasError('required') && saveDrugForm.controls.unitDose.touched">
                  <mat-error *ngIf="saveDrugForm.controls.unitDose.hasError('required')">Unit
                    dose is required</mat-error>
                </div>
              </div>
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Capacity</span>
                </div>
                <input type="text" aria-label="Number" matInput [formControl]="packageSize">
                <div
                  *ngIf="saveDrugForm.controls.packageSize.hasError('required') && saveDrugForm.controls.packageSize.touched">
                  <mat-error *ngIf="saveDrugForm.controls.packageSize.hasError('required')">Capacity
                    is required</mat-error>
                </div>
              </div>

              <div class="flex flex-col basis-1/4">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Package Type</span>
                </div>
                <mat-form-field>
                  <input type="text" aria-label="Number" matInput [formControl]="packageType"
                    [matAutocomplete]="autoPackageType">
                  <mat-autocomplete #autoPackageType="matAutocomplete" [displayWith]="displayFnPacakgeType.bind(this)">
                    <mat-option *ngFor="let data of listOfPackageType" [value]="data.value">
                      {{data.label}}
                    </mat-option>
                    <mat-option *ngIf="isLoading" class="is-loading">
                      <mat-spinner diameter="25"></mat-spinner>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <div
                  *ngIf="saveDrugForm.controls.packageType.hasError('required') && saveDrugForm.controls.packageType.touched">
                  <mat-error *ngIf="saveDrugForm.controls.packageType.hasError('required')">Package
                    type is required</mat-error>
                </div>
              </div>

              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Coating</span>
                </div>
                <mat-form-field>
                  <mat-select formControlName="coating" ngDefaultControl>
                    <mat-option *ngFor="let data of listOfCoating" [value]="data.key">{{data?.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="saveDrugForm.controls.coating.hasError('required') && saveDrugForm.controls.coating.touched">
                  <mat-error *ngIf="saveDrugForm.controls.coating.hasError('required')">Coating
                    is required</mat-error>
                </div>
              </div>

            </div>
            <div style="margin-bottom: .8rem;" class="flex flex-row gap-[1rem]">
              <div class="flex flex-col basis-1/4">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">30 Dram Capacity</span>
                </div>
                <input formControlName="thirtyDramCapacity" type="number" />
                <div
                  *ngIf="saveDrugForm.controls.thirtyDramCapacity.hasError('required') && saveDrugForm.controls.thirtyDramCapacity.touched">
                  <mat-error *ngIf="saveDrugForm.controls.thirtyDramCapacity.hasError('required')">Thirty
                    Dram Capacity is required</mat-error>
                </div>

              </div>
              <div class="flex flex-col basis-1/4">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Color</span>
                </div>
                <mat-form-field>
                  <input type="text" aria-label="Number" matInput [formControl]="color" [matAutocomplete]="autoColor">
                  <mat-autocomplete #autoColor="matAutocomplete" [displayWith]="displayFnColor.bind(this)">
                    <mat-option *ngFor="let data of listOfColor" [value]="data.value">
                      {{data.label}}
                    </mat-option>
                    <mat-option *ngIf="isLoading" class="is-loading">
                      <mat-spinner diameter="25"></mat-spinner>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <div *ngIf="saveDrugForm.controls.color.hasError('required') && saveDrugForm.controls.color.touched">
                  <mat-error *ngIf="saveDrugForm.controls.color.hasError('required')">Color
                    is required</mat-error>
                </div>
              </div>
              <div class="flex flex-col basis-1/4">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Imprint 1</span>
                </div>
                <input formControlName="imprint1" type="text" />
                <div
                  *ngIf="saveDrugForm.controls.imprint1.hasError('required') && saveDrugForm.controls.imprint1.touched">
                  <mat-error *ngIf="saveDrugForm.controls.imprint1.hasError('required')">Imprint
                    1 is required</mat-error>
                </div>
              </div>
              <div class="flex flex-col basis-1/4">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Imprint 2</span>
                </div>
                <input formControlName="imprint2" type="text" />
                <div
                  *ngIf="saveDrugForm.controls.imprint2.hasError('required') && saveDrugForm.controls.imprint2.touched">
                  <mat-error *ngIf="saveDrugForm.controls.imprint2.hasError('required')">Imprint
                    2 is required</mat-error>
                </div>
              </div>
              <div class="flex flex-col basis-1/4">
                <div class="flex flex-row">
                  <span style="color:red">*</span>
                  <span class="label">Scoring</span>
                </div>
                <mat-form-field>
                  <mat-select formControlName="scoring" ngDefaultControl>
                    <mat-option *ngFor="let data of listOfScoaring" [value]="data.value">{{data?.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="saveDrugForm.controls.scoring.hasError('required') && saveDrugForm.controls.scoring.touched">
                  <mat-error *ngIf="saveDrugForm.controls.scoring.hasError('required')">Scoring
                    is required</mat-error>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col basis-[30%]">

            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Barcode</span>
            </div>
            <input formControlName="barcode" type="text">
            <div *ngIf="saveDrugForm.controls.barcode.hasError('required') && saveDrugForm.controls.barcode.touched">
              <mat-error *ngIf="saveDrugForm.controls.barcode.hasError('required')">Barcode
                is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[12.5%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">2D Barcode</span>
            </div>

            <input formControlName="barcode2d" type="text">
            <div
              *ngIf="saveDrugForm.controls.barcode2d.hasError('required') && saveDrugForm.controls.barcode2d.touched">
              <mat-error *ngIf="saveDrugForm.controls.barcode2d.hasError('required')">Barcode2d
                is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[12%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Pill Type</span>
            </div>

            <mat-form-field>
              <mat-select formControlName="pillType" ngDefaultControl>
                <mat-option *ngFor="let data of listOfPillType" [value]="data.label">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.pillType.hasError('required') && saveDrugForm.controls.pillType.touched">
              <mat-error *ngIf="saveDrugForm.controls.pillType.hasError('required')">Pill
                type is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[12%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">AntiBiotic</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="antibiotic" ngDefaultControl>
                <mat-option [value]="true">YES</mat-option>
                <mat-option [value]="false">NO</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.antibiotic.hasError('required') && saveDrugForm.controls.antibiotic.touched">
              <mat-error *ngIf="saveDrugForm.controls.antibiotic.hasError('required')">Antibiotic
                is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[12%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Country</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="country" ngDefaultControl>
                <mat-option *ngFor="let data of countryList" [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.country.hasError('required') && saveDrugForm.controls.country.touched">
              <mat-error *ngIf="saveDrugForm.controls.country.hasError('required')">Country
                is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-[12.5%]">
            <div class="flex flex-row">
              <span style="color:red">*</span>
              <span class="label">Drug Classification Type</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="drugClassificationType" ngDefaultControl>
                <mat-option *ngFor="let data of listOfDrugClassificationType"
                  [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.drugClassificationType.hasError('required') && saveDrugForm.controls.drugClassificationType.touched">
              <mat-error *ngIf="saveDrugForm.controls.drugClassificationType.hasError('required')">Drug classification
                type
                is required</mat-error>
            </div>
          </div>
        </div>

        <h5>Attributes</h5>
        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col basis-[30%]">
            <span class="label">Length</span>
            <input formControlName="length" type="number">
          </div>
          <div class="flex flex-col basis-[10%]">
            <span class="label">Width</span>
            <input formControlName="width" type="number">
          </div>
          <div class="flex flex-col basis-[10%]">
            <span class="label">Thickness</span>
            <input formControlName="thickness" type="number">
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Piece Weight</span>
            <input formControlName="pieceWeight" type="number">
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Diagonal</span>
            <input formControlName="diagonal" type="number">
          </div>

        </div>
        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col basis-[30%]">
            <span class="label">Classification Shape</span>

            <mat-form-field>
              <mat-select formControlName="classificationShape" ngDefaultControl>
                <mat-option *ngFor="let data of listOfClassificationShape"
                  [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex flex-col basis-[21%]">
            <span class="label">Qty Classified</span>
            <input formControlName="qtyClassified" type="text">
          </div>
          <div class="flex flex-col basis-[10%]">
            <span class="label">Qty Weighted</span>
            <input formControlName="qtyWeighted" type="text">
          </div>
          <div class="flex flex-col basis-1/5">
            <span class="label">Drug Status</span>
            <mat-form-field>
              <mat-select formControlName="drugStatus" ngDefaultControl>
                <mat-option *ngFor="let data of listOfDrugStatus" [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
      </div>

    </div>
  </form>

  <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
    <button mat-button class="btn-blue-default btn-md" (click)="dialogRef.close()" id="CancelNewUser"
      aria-label="Cancel" type="button">Cancel</button>
    <button mat-button aria-label="Save Drug"
      [ngClass]="{'btn-primary':saveDrugForm?.valid, 'btn-disabled':!saveDrugForm?.valid}"
      [disabled]="!saveDrugForm?.valid" id="Next" (click)="save()" class="btn-md" type="button">Save Drug </button>
  </mat-dialog-actions>

</div>
