import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SystemConfigurationDialogComponent } from 'src/app/internal-user/fleet-management/components/system-configuration/system-configuration-dialog.component';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { getPreProvision } from 'src/app/store/selectors/fleet-manager.selector';
import { FleetManagementService } from '../../services/fleet-management.service';
import { AuthService } from '../../../../auth/auth.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FleetConfigurationService } from '../../services/fleet-configuration.service';
import { FleetManagerActions } from 'src/app/store/actions';
import { SearchBarComponent } from 'src/app/shared/components/search-bar/search-bar.component';
import { DeviceConfigModel } from '../../models/iotDeviceDetails.model';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';

export interface PeriodicElement {
  agentype: string;
  idno: number;
}

@Component({
  selector: 'app-pre-provisioned',
  templateUrl: './pre-provisioned.component.html',
  styleUrls: ['./pre-provisioned.component.scss']
})
export class PreProvisionedComponent implements AfterViewInit {

  @Input() organizations: any[];
  idName = 'ID Number';
  agentType = 'Agent Type';
  connected = 'Connected';
  lastUpdated = 'Last Updated'
  displayedColumns: string[] = ['idName', 'agentType', 'connected', 'lastUpdated']; //, 'configure'
  dataSource: MatTableDataSource<any>;
  unprovisioned: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SearchBarComponent, { static: true }) childComponent: SearchBarComponent;
  filterText = '';

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    public fleetManagementService: FleetManagementService,
    public fleetConfigService: FleetConfigurationService,
    public auth: AuthService,
    public toastService: ToastService,
    public store: Store<State>) {
    this.store.select(getPreProvision).subscribe(res => {      
      if (res) {        
        const Data: any = res;
        this.unprovisioned = Data.sort((a, b) => {
          if (a.edgeDeviceId.toLowerCase() > b.edgeDeviceId.toLowerCase())
            {return 1;}
          if (a.edgeDeviceId.toLowerCase() < b.edgeDeviceId.toLowerCase())
            {return -1;}
          return 0;
        });
        this.dataSource = new MatTableDataSource(this.unprovisioned);
      }
    });
  }

  ngOnInit() {
    this.fleetConfigService.getConfigTemplates().subscribe(
      (response) => {
        this.store.dispatch(FleetManagerActions.getFTConfiguration({ data: response }));
      });
  }

  openSystemConfigDialog(deviceConfig: DeviceConfigModel) {
    this.dialog.open(SystemConfigurationDialogComponent,
      {
        disableClose: true,         
        backdropClass: 'lgDialog', 
        data: { deviceConfigModel: deviceConfig, organizations: this.organizations }
      });
  }

  ngAfterViewInit() {
  }

  announceSortChange(sortState: Sort) {
    //sorting
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    let data: any;
    if (this.dataSource?.data) {
      data = this.dataSource.data;
    } else {
      data = this.dataSource;
    }
        
    const sortData = data.sort((a, b) => {
      switch (this.sort.active) {
        case 'idName':
          return this.stringCompare(a.edgeDeviceId, b.edgeDeviceId, sortState.direction);
        case 'connected':
          return this.stringCompare(a.connectionState, b.connectionState, sortState.direction);          
        case 'lastUpdated':
          return this.dateCompare(new Date(a.connectionStateUpdatedTime), new Date(b.connectionStateUpdatedTime), sortState.direction);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource(sortData);    
  }

  stringCompare(a, b, direction) {
    if (a && b) {
      if(direction == 'desc') {    
        if (a.toLowerCase() < b.toLowerCase()) { return 1; }
        if (a.toLowerCase() > b.toLowerCase()) { return -1; }
      } else {
        if (a.toLowerCase() > b.toLowerCase()) { return 1; }
        if (a.toLowerCase() < b.toLowerCase()) { return -1; }
      }
    }   
    return 0;
  }

  dateCompare(a, b, direction) {
    if (a && b) {
      if(direction == 'desc') {    
        if (a < b) { return 1; }
        if (a > b) { return -1; }
      } else {
        if (a > b) { return 1; }
        if (a < b) { return -1; }
      }      
    }
    return 0;
  }
  
  filterData(filter) {
     this.filterText = filter;
    
     const filterLowerCase = filter.toLowerCase();
     this.filterText = filter;

     const data= this.unprovisioned;
 
     let filteredData = data;
     if (filterLowerCase != '') {    
 
       filteredData = filteredData.filter((item) => (
           item.edgeDeviceId.toLowerCase().includes(filterLowerCase) 
         ));
     }
     this.dataSource = new MatTableDataSource(filteredData);    
  }

}