import { Configuration } from 'ng-material-multilevel-menu';
import { NavItem } from '../shared/ng-material-multilevel-menu/app.model';

export const navItems: NavItem[] = [
  {
    label: 'Dashboard',
    imageIcon: '/assets/img/menu-icons/dashboard-black.svg',
    link: '/dashboard',
    menuId: 'Mnudashboard',
    menuDesktopId: 'D-Mnudashboard',
  },
  {
    label: 'Inventory ATP',
    imageIcon: '/assets/img/icons/ico.24.inventory.svg',
    link: '/inventory/atp',
    menuId: 'MnuInventoryAtp',
    menuDesktopId: 'D-MnuInventoryAtp',
  },
  {
    label: 'Inventory MAX',
    imageIcon: '/assets/img/icons/ico.24.inventory.svg',
    link: '/inventory/max',
    menuId: 'MnuInventoryMax',
    menuDesktopId: 'D-MnuInventoryMax',
  },
  {
    label: 'Inventory PASS',
    imageIcon: '/assets/img/icons/ico.24.inventory.svg',
    link: '/inventory/pass',
    menuId: 'MnuInventoryPass',
    menuDesktopId: 'D-MnuInventoryPass',
  },
  {
    label: 'Customer Mgmt',
    imageIcon: '/assets/img/menu-icons/configure.svg',
    link: '/customer-management',
    menuId: 'MnuCustomerManagement',
    menuDesktopId: 'D-MnuCustomerManagement',
  },
  {
    label: 'Fleet Mgmt',
    imageIcon: '/assets/img/menu-icons/ico.24.global.svg',
    link: '/fleet-management',
    menuId: 'MnuFleetManagement',
    menuDesktopId: 'D-MnuFleetManagement',
  },
  {
    label: 'Script Mgmt',
    imageIcon: '/assets/img/menu-icons/batch.svg',
    link: '/script-routing',
    menuId: 'Mnuscript-routing',
    menuDesktopId: 'D-Mnuscript-routing',
  },
  {
    label: 'Reports',
    imageIcon: '/assets/img/menu-icons/reports.svg',
    link: '/reportsubscription',
    menuId: 'Mnureportsubscription',
    menuDesktopId: 'D-Mnureportsubscription',
  },
  {
    label: 'DrugDB Mgmt',
    imageIcon: '../assets/img/menu-icons/file-upload.svg',
    link: '/drug-management',
    menuId: 'Mnudrugmanagement',
    menuDesktopId: 'D-Mnudrugmanagement',
  },
  {
    label: 'Implementaion Mgmt',
    imageIcon: '../assets/img/menu-icons/dashboard-white.svg',
    link: '/im-tool',
    menuId: 'MnuImTool',
    menuDesktopId: 'D-MnuImTool',
  },
];

export const navConfig: Configuration = {
  paddingAtStart: true,
  interfaceWithRoute: true,
  classname: 'desktop_nav',
  listBackgroundColor: `transparent`,
  fontColor: `#999999`,
  backgroundColor: `transparent`,
  selectedListFontColor: `#18186F`,
  highlightOnSelect: true,
  collapseOnSelect: true,
  rtlLayout: false,
  customTemplate: true,
};
