<app-card title="">
    <div title class="inventory_ctrl xs:flex xs:justify-start xs:content-start xs:items-start flex flex-row justify-between content-center items-center">
        <h5 class="ucase m-0 text-primaryblue xs:basis-full basis-1/2" tabindex="0">PROJECT MANAGEMENT TOOL V4.1.2</h5>

    </div>
    <section style="overflow: scroll; height: 85vh;">
        <div class="sticky_search">
            <div class="p-16 bg-white">
                <div class="flex flex-row justify-between content-center items-center xs:flex xs:flex-col">


                    <div class="search-wrapper basis-1/5" style="padding: 9px">
                        <div class="search-container">
                            <app-icon icon="search" class="icon_search"></app-icon>
                            <input appNoHtmlTags class="search-input" inputId="searchcustomers-input" (keyup)="doFilter($event.target.value, true)"  minLengthToSearch="1" placeholder="Search"[ngModel]="searchText"  id="search_customers">
                            <app-icon icon="clear" *ngIf="searchText != null && searchText.length>0" (click)="doFilter('', true)" id="data-clear" class="pointer"></app-icon>
                        </div>
                    </div>


                    <div class="project-stuats-div flex justify-start content-center items-center basis-[30%]">

                        <span class="flex justify-center content-stretch items-stretch basis-[30.3%]">Project Status</span>
                        <mat-form-field floatLabel="never" class="flex justify-start content-stretch items-stretch sm:basis-1/2 basis-1/2">
                            <mat-select matInput [value]="selectedProjectStatus" (selectionChange)="doFilter($event.value, false)">
                                <mat-option *ngFor="let status of projectStatus" [value]="status.id">{{status.value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="project-stuats-div flex justify-start content-center items-center basis-2/5">
                      <span class="flex justify-center content-stretch items-stretch basis-[30.3%]">Salesforce Status</span>
                      <mat-form-field floatLabel="never" class="flex justify-start content-stretch items-stretch sm:basis-1/2 basis-[50.3%]">
                          <mat-select [formControl]="salesforceStatus" multiple>
                          <mat-option *ngFor="let status of salesforceStatusList" [value]="status.value">{{status.key}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <button id="btn_load_data" class="btn-blue btn-sm basis-[20.3%]" style="margin-top: .5rem;" *ngIf="this.salesforceStatus.value.length>0"  (click)="getCustomerData()">
                        Load
                      </button>
                    </div>
                     <div class="project-stuats-div flex justify-end content-center items-center basis-2/5">
                        <!--
                        <span fxFlex="30.3%" fxLayoutAlign="center">Team Assist</span>
                        <mat-form-field floatLabel="never" fxFlex="50.3%" fxFlex.sm="60%">
                            <mat-select [value]="selectedProjectStatus">
                                <mat-option *ngFor="let data of teamAssists" [value]="data.id">{{data.value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        -->
                    </div> 


                </div>
            </div>
        </div>






        <div class="mat-elevation-z8" style="margin-bottom: 5px;">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)">
                 <!-- SL Name Column -->
                 <ng-container matColumnDef="sl">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> SL </th>
                    <td mat-cell *matCellDef="let element"> {{element.sl}} </td>
                </ng-container>
                <!-- Customer Name Column -->
                <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
                </ng-container>
                <!-- Case Number Column -->
                <ng-container matColumnDef="caseNumber">
                    <th mat-header-cell *matHeaderCellDef> Case Number </th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.caseDetailsLink?.length===0">{{element.caseNumber}} </span>
                      <a [href]="element.caseDetailsLink" target="_blank" *ngIf="element.caseDetailsLink.length>0">{{element.caseNumber}} </a>
                      </td>
                </ng-container>
                <!-- Customer Number Column -->
                <ng-container matColumnDef="customerNumber">
                    <th mat-header-cell *matHeaderCellDef> Customer Number </th>
                    <td mat-cell *matCellDef="let element"> {{element.customerNumber}} </td>
                </ng-container>
                <!-- Symbol Column -->
                <ng-container matColumnDef="subDeviceTypeName">
                    <th mat-header-cell *matHeaderCellDef> Device Type </th>
                    <td mat-cell *matCellDef="let element"> {{element.subDeviceTypeName}} </td>
                </ng-container>

                <!-- Last Edited Date Column -->
                <ng-container matColumnDef="lastEdited">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Edited </th>
                    <td mat-cell *matCellDef="let element"> {{element.lastEdited}} </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="statusDescription">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.statusDescription}} </td>
                </ng-container>

                <!-- Target Install Date Column -->
                <ng-container matColumnDef="targetInstallDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Target Install Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.targetInstallDate}} </td>
                </ng-container>

                <!-- Action Details -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element" style="text-align: right;">

                        <button id="add_site" class="btn-blue btn-sm add_site" *ngIf="element.status.id==2 || element.status.id==1" type="submit" (click)="openAddSite(element)">
                            IMPORT
                          </button>
                        <mat-icon [matMenuTriggerFor]="userMenu" (click)="$event.stopPropagation()" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px; padding-left: 8px;">
                            <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                        </mat-icon>
                        <mat-icon (click)="redirectPageBasedOnStatus(element)" style="margin-bottom: 2px;">
                            <img src="/assets/img/icons/ico.12.arwrgt.svg" alt="">
                        </mat-icon>
                        <mat-menu #userMenu="matMenu" backdropClass="custom_drop" xPosition="before">
                            <button mat-menu-item (click)="openAddSite(element)">Edit Import File</button>
                            <button [disabled]="element.status.id!==5" (click)="redirectPageBasedOnStatus(element)" mat-menu-item id="inactivate">Export</button>
                            <button mat-menu-item id="reactivate" (click)="openAlertWindow(element)">Remove</button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row (click)="getRow(row); selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <table>
                <tr *ngIf="dataSource.data.length==0">
                    <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                        No Records Found!
                    </td>
                </tr>
            </table>
            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">

            </mat-paginator>
        </div>


    </section>
</app-card>
