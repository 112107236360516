import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastService } from '../../../../shared/services/toast.service';
import * as constants from '../../../../config/app-constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Organization } from '../../models/organization';
import { OrganizationService } from '../../services/organization.service';
import { Observable, throwError } from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AddOrganizationDialogComponent } from 'src/app/internal-user/customer-management/components/add-organization/add-organization-dialog.component';
import { AddSiteDialogComponent } from 'src/app/internal-user/customer-management/components/add-site/add-site-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Role } from '../../../../shared/models/auth/role';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Menu } from '../../../../config/app-constants';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-setup-customer-management',
  templateUrl: './setup-customer-management.component.html',
  styleUrls: ['./setup-customer-management.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class SetupCustomerManagementComponent implements OnInit {

  [x: string]: any;

  isTableExpanded = true;

  dataOrgList = new MatTableDataSource();
  displayedOrgColumnsList: string[] = ['orgName', 'organizationId', 'addsite', 'arrow'];

  @ViewChild('formDirective', { static: false }) private formDirective: NgForm;

  formGroup: FormGroup = new FormGroup({
    parentOrgSalesforceId: new FormControl(''),
    parentOrgName: new FormControl('', [Validators.required]),
    siteSalesforceId: new FormControl(''),
    orgName: new FormControl('', [Validators.required]),
    userFirstName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z]+')]),
    userLastName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z]+')]),
    userEmail: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
    roleName: new FormControl(''),
  });

  reportsTableColumns: string[] = ['parentOrgName', 'salesForceId', 'addnewsite', 'link']; // 'siteName', 'status',
  organizations: any[];
  orgList: string;

  filteredOptions: Observable<Organization[]>;
  sfParentOrg: any[];
  sfSites: any[];
  isAU = false;
  enableAddNewOrg = false;
  enableAddNewSite = false;
  filterText = '';

  constructor(
    private organizationService: OrganizationService,
    private authService: AuthService,
    private toastService: ToastService,
    private ngxLoader: NgxUiLoaderService,
    public dialog: MatDialog,
  ) { }


  ngOnInit() {

    const ngxLoaderKey = 'setup-customer-management-load';
    this.ngxLoader.start(ngxLoaderKey);
    this.loadCustomers();

    this.authService.profile$.subscribe((profile) => {
      this.isAU = profile.hasRole(Role.admin);
      this.enableAddNewOrg = profile.menuPermissions.includes(Menu.AddNewOrganization);
      this.enableAddNewSite = profile.menuPermissions.includes(Menu.AddNewSite);
    }, (response) => this.toastService.stopLoadingAndShowError(response,
      'Error while load user profile!', ngxLoaderKey));

  }

  loadCustomers() {
    this.organizationService.getActiveOrganizationswithParent()
      .subscribe((organizations) => {

        this.dataOrgList.data = organizations;
        this.organizations = organizations;
        this.orgList = JSON.stringify(organizations);
        this.ngxLoader.stop('setup-customer-management-load');
        this.ExpandRows();
      },
        // (response) => {
        //   if (response.status === 404) {
        //     this.ngxLoader.stop('setup-customer-management-load');

        //     this.orgList='';
        //     this.toastService.openToast('No Organization found!', constants.ToastPanelType.warning);
        //   } else {
        //   this.ngxLoader.stop('setup-customer-management-load');
        //   this.orgList='';
        //   this.toastService.openToast('Error while loading Organizations!', constants.ToastPanelType.error);
        //   }
        //}
        catchError((error: HttpErrorResponse) => {
          this.orgList = '';
          if (error.status === 404) {
            this.toastService.openToast('No Organization found!', constants.ToastPanelType.warning);
          }
          else {
            this.toastService.openToast('Error while loading Organizations!', constants.ToastPanelType.error);
          }
          this.ngxLoader.stop('setup-customer-management-load');
          let errorMessage: string;
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
          }

          this.loggerService.logError('getActiveOrganizationswithParent: Error: ', errorMessage);
          return throwError(error);
        })
      );
  }

  ExpandRows() {

    this.dataOrgList.data.forEach((row: any) => {
      row.isExpanded = this.isTableExpanded;
    });
  }

  filterCustomers(filter = '') {
    const filterLowerCase = filter.toLowerCase();
    this.filterText = filter;

    let filteredData = JSON.parse(this.orgList);
    if (filterLowerCase != '') {
      filteredData = filteredData.map((item) => {
        item.sites = item.sites.length > 0 && item.sites.filter((child) => (
          child.name.toLowerCase().includes(filterLowerCase) || (child.customerNo != null && child.customerNo.toLowerCase().includes(filterLowerCase))
          || item.orgName.toLowerCase().includes(filterLowerCase)
        ));
        return item;
      });

      filteredData = filteredData.filter((item) => (
          item.orgName.toLowerCase().includes(filterLowerCase) ||
          item.sites.length > 0
        ));
    }
    this.dataOrgList.data = filteredData;
    this.organizations = filteredData;

    this.ExpandRows();
  }

  openAddOrganization() {
    const dialogOrgRef = this.dialog.open(AddOrganizationDialogComponent, { disableClose: true, backdropClass: ['smDialog'] });
    return dialogOrgRef.afterClosed().subscribe(res => {
      if (res === 'success')
        {this.loadCustomers();}
    });
  }

  openAddSite(isparentnotassociated, parentName, parentID) {
    const dialogSiteRef = this.dialog.open(AddSiteDialogComponent, { disableClose: true, backdropClass: ['mdDialog'], data: { isparentnotassociated, parentName, parentID } });
    return dialogSiteRef.afterClosed().subscribe(res => {
      if (res === 'success')
        {this.loadCustomers();}
      else if (res === 'openorg')
        {this.openAddOrganization();}
    });
  }
}
