import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Drug } from '../../models/drug';
import { RpoToolService } from '../../services/rpo-tool.service';
import * as constants from 'src/app/config/app-constants';
import { Router } from '@angular/router';
import { routePaths } from 'src/app/config/app-constants';
import { Dropdwon } from 'src/app/internal-user/im-tool/models/column-name';
@Component({
  selector: 'app-import-wizard-search-drug',
  templateUrl: './import-wizard-search-drug.component.html',
  styleUrls: ['./import-wizard-search-drug.component.scss']
})
export class ImportWizardSearchDrugComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ['name', 'ndc', 'countryCode', 'system', 'strength', 'manufacturer', 'priority', 'requestor', 'age','action'];
  selectedRow:any;
  dataSource= new MatTableDataSource<Drug>();
  listOfCountry:Dropdwon[] =[];
  searchText:string=''
     //#region Pagination properties
     totalRows: number = 0;
     currentPage: number = 1;
     pageSize: number = 5;
     pageSizeOptions: number[] = [5, 10, 20, 40, 50];
     countryId:number = 0;
     //#endregion
  constructor(
    public dialogRef: MatDialogRef<ImportWizardSearchDrugComponent>,
    public dialog: MatDialog,
    private rpoService:RpoToolService,
    private ngxLoader: NgxUiLoaderService,
    private route: Router,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

      this.searchText = data.searchText;
      this.countryId = data.countryId;

     if(this.searchText?.length>0)
        this.getDrugData();
      this.getCountry();    
     
    }
  ngOnInit(): void {
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getDrugData();
  }
  search(){
    this.getDrugData();
  }
  getDrugData(){
    let searchDrugKey = 'SearchDrugkey'
    this.ngxLoader.start(searchDrugKey);
    this.rpoService.getSearchDrugData(
      this.searchText,
      this.countryId,
      this.currentPage == 0 ? 1 : this.currentPage,
       this.pageSize
    ).subscribe((result)=>{

     this.dataSource.data = result.data.drugs
     setTimeout(() => {
      this.paginator.pageIndex = this.currentPage;
      this.paginator.length = result.data.totalRows;
    });
    this.ngxLoader.stop(searchDrugKey);
    },
    (error: HttpErrorResponse) => {
      this.ngxLoader.stop(searchDrugKey);
      console.log(error);
      this.toastService.openToast(
        error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }
  getCountry(){
    this.ngxLoader.start();
    this.rpoService.getCountry().subscribe((result)=>{
      this.ngxLoader.stop();
      this.listOfCountry = result.data;
    },
    (error: HttpErrorResponse) => {
      this.ngxLoader.stop();
      console.log(error);
      this.toastService.openToast(
        error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }
  redirectToDetailsPage(rowId:any){
    this.route.navigate([`/${routePaths.DRUG_MANAGEMENT}/drug-details`, rowId]);
    this.dialogRef.close();
  }
}
