import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IOTDeviceDetails } from '../models/iotDeviceDetails.model';
import { DeploymentParameters } from '../models/deploymentParameters.model';

@Injectable({
  providedIn: 'root',
})
export class FleetManagementService extends DataService<IOTDeviceDetails> {

  httpClient: HttpClient;

  cdcModuleId = 'sql-cdc-module';

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.iotDeviceMgmtService}/`, http);
    this.httpClient = http;
  }

  getRestartCDCModule(deviceID): Observable<any> {
    return this.http.get<any[]>(`${this.url}${deviceID}/Modules/${this.cdcModuleId}/Restart`);
  }

  getRestartModules(deviceID): Observable<any> {
    return this.http.get<any[]>(`${this.url}${deviceID}/Modules/Restart`);
  }

  getRestartDevice(deviceID): Observable<any> {
    return this.http.get<any[]>(`${this.url}${deviceID}/Restart`);
  }

  getProvisionedSystems(): Observable<any> {
    return this.http.get<any>(`${this.url}systems/provisioned`);
  }

  getDeviceInventory(): Observable<IOTDeviceDetails> {
    return this.http.get<any>(`${this.url}`);
  }

  getDeploymentParameters(deviceId): Observable<DeploymentParameters> {
    return this.http.get<DeploymentParameters>(`${this.url}${deviceId}/Modules/${this.cdcModuleId}/deploymentParameters`);
  }

  getDeviceLog(deviceID, logOptions): Observable<any> {
    return this.http.post<any>(`${this.url}${deviceID}/Modules/logs`,logOptions, {responseType: 'blob' as 'json'});
  }

  deprovisionDevice(deviceId) {
    return this.http.delete<any>(`${this.url}${deviceId}`);
  }
  
  getSystemssByEdgeDeviceId(edgeDeviceId): Observable<any> {
    return this.http.get<any>(`${this.url}${edgeDeviceId}`);
  }

  getSystemsInSiteByIp(siteId: string, ipAddress: string): Observable<any> {
    return this.http.get<any>(`${this.url}systems/${siteId}/${ipAddress}`)
  }
}
