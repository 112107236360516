import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { OrgUsersAndRoles } from '../models/org-users-with-roles';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { OrgUser } from '../models/org-user';
import { OrgUserRoles } from '../models/org-user-roles';

@Injectable({
  providedIn: 'root'
})
export class UserService extends DataService<User> {

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.authorizationService}users/`, http);
  }

  getUsersAndRolesForOrg(orgId: string, orgType: string, gearIcon: boolean): Observable<OrgUsersAndRoles[]> {
    if(orgType === 'Internal'&& gearIcon)
    return this.http.get<any[]>(`${this.url}InternalUserRole/${orgId}`);
    else
    return this.http.get<any[]>(`${this.url}UserRole/${orgId}`);
  }

  getUsersForOrg(orgId: string): Observable<User[]> {
    return this.http.get<any[]>(`${this.url}Org/${orgId}`);
  }

  createUserWithRole(resource: OrgUser, orgType: string, gearIcon: boolean) {
    if(orgType === 'Internal'&& gearIcon)
      return this.create(resource, `${this.url}CreateInternalUserWithRole`);
    else
      return this.create(resource, `${this.url}CreateUserWithRole`);
  }

  updateUserRole(resource: OrgUserRoles, orgType: string, gearIcon: boolean) {
    if(orgType === 'Internal'&& gearIcon)
      return this.http.put(`${environment.apiUrl.authorizationService}userRoles/ChangeInternalRole`, resource);
    else
      return this.http.put(`${environment.apiUrl.authorizationService}userRoles/ChangeRole`, resource);
  }

  deactivateUser(userId: string, orgType: string, gearIcon: boolean) {
    if(orgType === 'Internal'&& gearIcon)
      return this.http.put(`${this.url}DeactivateInternalUser/${userId}`, null);
    else
      return this.http.put(`${this.url}DeactivateUser/${userId}`, null);
  }

  activateUser(userId: string, orgType: string, gearIcon: boolean) {
    if(orgType === 'Internal'&& gearIcon)
      return this.http.put(`${this.url}ActivateInternalUser/${userId}`, null);
    else
      return this.http.put(`${this.url}ActivateUser/${userId}`, null);
  }

  resendInvitation(userId: string) {
    return this.http.put(`${this.url}ResendUserInvitation/${userId}`, null);
  }

  resetPassword(userId: string) {
    return this.http.put(`${this.url}ResetPassword/${userId}`, null);
  }
}
