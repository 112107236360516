<app-card title="">
    <div title class="flex justify-between content-start items-start">

        <mat-icon (click)="redirectCustomerList()" style="margin-bottom: 6px;">
            <img src="/assets/img/icons/ico.16.arwlft.svg" alt="">
        </mat-icon>
        <h5 style="padding-top: 5px;" class="ucase m-0 text-primaryblue xs:basis-full basis-[81%]" tabindex="0">
            {{customerName}} <span *ngIf="subDeviceTypeName !== null">({{subDeviceTypeName}}-{{machineNo}}-{{country}}-
                {{targetInstallDate}})</span></h5>

        <div class="basis-[7%]">
          <button id="btn_dcr_drugs" class="bg-red btn-sm" (click)="openImportWizardDcrDrug()">
             DCR Drugs
          </button>
      </div>
        <div class="basis-[10%]">
            <button id="btn_drug_qualifier" class="bg-red btn-sm" (click)="openImportWizardQualifier()">
                Drug Qualifier
            </button>
        </div>
        <div class="basis-[10%]">
          <button id="btn_change_device" class="bg-red btn-sm" (click)="openImportWizard()">
              Change Device
          </button>
      </div>
      <div class="flex justify-between content-center items-center flex-row">
        <mat-icon (click)="onAllUserPaginateChange()" style="padding-right: 14px; ">
            <img style="padding: 4px;" src="/assets/img/icons/ico.24.scrolltotop.svg" alt="">
        </mat-icon>
    </div>
        <mat-form-field floatLabel="never" class="basis-[11%]">
            <mat-select style="margin-top: 0rem !important;" matInput [value]="0" 
                (selectionChange)="downloadFile($event.value)">
                <mat-option [disabled]="true" [value]="0" style="text-align: center; background-color: rgb(202 198 198);">
                    <span style="font-weight: bolder;">Export</span>
                </mat-option>
                <mat-option [value]="1">Export XLSX</mat-option>
                <mat-option [value]="2">Export Replenishmen.xlsx</mat-option>
                <mat-option [value]="3">Export Preparation_etiquettes.xlsx</mat-option>
                <mat-option [value]="4">Export Nicelabel_Canister_labels.xlsx</mat-option>
                <mat-option [value]="5">Export Double_Containers.xlsx</mat-option>
                <mat-option [value]="6">Export NON-Certified_Drugs.xlsx</mat-option>
                <mat-option [value]="7">Export Internals.xlsx</mat-option>
                <mat-option [value]="8">Export Errors.xlsx</mat-option>
                <mat-option *ngIf="isTdrugOpen" [value]="9">Export TDrug-Data.csv</mat-option>
                <mat-option [value]="11">Export New_Drugs_To_Classify.xlsx</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <section class="table-section">
        <div class="sticky_search">
            <div class="bg-white" style="padding-bottom: 6px; padding-top: 6px;">
                <div class="flex justify-start content-center items-center xs:flex xs:flex-col sm:flex sm:flex-row">

                    <div class="search-wrapper" style="padding: 9px">
                        <div class="search-container">
                            <app-icon icon="search" class="icon_search"></app-icon>
                            <input appNoHtmlTags class="search-input" inputId="searchcustomers-input" 
                                (keyup)="doFilter($event.target.value)" minLengthToSearch="1" placeholder="Search" 
                                [ngModel]="searchText" id="search_customers">
                            <app-icon icon="clear" *ngIf="searchText.length>0" (click)="doFilter('')" id="data-clear" 
                                class="pointer"></app-icon>
                        </div>
                    </div>

                    <div class="project-stuats-div flex justify-between content-start items-start basis-1/4">
                        <button id="btn_add" 
                            [ngClass]="{'btn-light':addCellLocList.length>0 && this.tabSelected === 0, 'mat-button-disabled':addCellLocList.length==0}" 
                            [disabled]="addCellLocList.length==0 || this.tabSelected !== 0" (click)="openAlertWindowForDrugAdd()" class=" btn-sm add_site">
                            Add
                        </button>
                        <button id="remove_celllocation" 
                            [ngClass]="{'btn-primary':drugCellLocationList.length>0 && this.tabSelected === 0, 'mat-button-disabled':drugCellLocationList.length==0}" 
                            [disabled]="drugCellLocationList.length==0 || this.tabSelected !== 0" (click)="openAlertWindowForDrugRemove()" 
                            class=" btn-sm add_site" >
                            Remove
                        </button>
                        <button id="move_celllocation" 
                        [ngClass]="{'btn-danger':drugCellLocationList.length>0 || addCellLocList.length>0, 'mat-button-disabled':drugCellLocationList.length==0 && addCellLocList.length === 0}" 
                        [disabled]="drugCellLocationList.length==0 && addCellLocList.length == 0" (click)="openImportWizardDrugMovingComponent()" 
                        class=" btn-sm add_site" >
                        Move
                    </button>
                        <button id="btn_cddb_add" (click)="openImportWizardCDDB()" class="btn-light btn-sm add_site">
                            CDDB Add
                        </button>
                    </div>

                    <div *ngIf="deviceTypeId === 16" class="cell-count-div flex justify-between content-start items-start basis-[19%]">

                        <button class="mat-button-disabled  btn-sm"> <span>Remaining Large Location :{{remainingLargeLocation}} </span></button>

                    </div>

                    <div *ngIf="deviceTypeId === 17" class="cell-count-div flex justify-between content-start items-start basis-[19%]">
                        <button class="mat-button-disabled  btn-sm"  mat-raised-button
                         
                        [matTooltip]="'  Large Pipette Toggle False  : ' + remainingLargeContLargePipetteFalse + '\n' +
                                        'Large Pipette Toggle True   : ' + remainingLargeContLargePipetteTrue +'\n' +
                                        'Small Pipette Toggle False  : ' + remainingLargeContSmallPipetteFalse + '\n' +
                                        'Small Pipette Toggle True   : ' + remainingLargeContSmallPipetteTrue + '\n' +
                                        'Both Pipette Toggle False   : ' + remainingLargeContBothPipetteFalse +'\n' +
                                        'Both Pipette Toggle True    : ' + remainingLargeContBothPipetteTrue "

                        matTooltipPosition="below"
                        matTooltipClass="custom-tooltip"
                        aria-label="Button that displays a multi-line tooltip when focused or hovered over"> 
                        <span>Remaining Large Location :{{remainingLargeLocation}} </span></button>

                    </div>

                    <div *ngIf="deviceTypeId === 16" class="super-cell-count-div flex justify-between content-start items-start basis-[18%]">

                        <button class="mat-button-disabled  btn-sm"> <span>Remaining Small Location :{{remainingSmallLocation}}</span></button>

                    </div>
                    
                    <div *ngIf="deviceTypeId === 17" class="super-cell-count-div flex justify-between content-start items-start basis-[18%]">

                        <button class="mat-button-disabled  btn-sm"  mat-raised-button
                        [matTooltip]="'Small Pipette Toggle False : ' + remainingSmallContSmallPipetteFalse + '\n' +
                                     'Small Pipette Toggle True : ' + remainingSmallContSmallPipetteTrue "
                        matTooltipPosition="below"
                        matTooltipClass="custom-tooltip"
                        aria-label="Button that displays a multi-line tooltip when focused or hovered over">
                         <span>Remaining Small Location :{{remainingSmallLocation}}</span></button>

                    </div>

                    <div class="super-cell-count-div flex justify-between content-start items-start basis-[17%]">

                        <button class="mat-button-disabled  btn-sm"> <span>Country :{{country}}</span></button>

                    </div>
                </div>
            </div>
        </div>
        <div class="setup_scroll">
            <mat-tab-group (selectedTabChange)="getCurrentTabSelect($event)">
                <mat-tab label="Analysis({{dataSource.data.length}})">
                    <div class="mat-elevation-z8">
                        <table mat-table #table [dataSource]="dataSource" id="matTable" matSort>

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected" 
                                        [(ngModel)]="isAnyDrugSelected"></mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" 
                                        [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order</th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>

                            <!-- ndc Name Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC</th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                            <!-- customerDrugId column-->
                            <ng-container matColumnDef="customerDrugId">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugId}} </td>
                            </ng-container>

                            <!-- customerMnemonic column-->
                            <ng-container matColumnDef="customerMnemonic">
                                <th [hidden]="!isMnemonicAnalysisShow" mat-header-cell *matHeaderCellDef mat-sort-header> Customer Mnemonic </th>
                                <td [hidden]="!isMnemonicAnalysisShow" mat-cell *matCellDef="let element"> {{element.customerMnemonic}} </td>
                            </ng-container>
                            <!-- lidType column-->
                            <ng-container matColumnDef="lidType">
                                <th [hidden]="!islidTypeShow" mat-header-cell *matHeaderCellDef mat-sort-header> Lid Type </th>
                                <td [hidden]="!islidTypeShow" mat-cell *matCellDef="let element"> {{element.lidType}} </td>
                            </ng-container>
                            <!-- cddbName Column -->
                            <ng-container matColumnDef="cddbName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.cddbName}} </td>
                            </ng-container>


                            <!-- customerDrugName Column -->
                            <ng-container matColumnDef="customerDrugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Name</th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugName}} </td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th [hidden]="!isStrengthAnalysisShow" mat-header-cell *matHeaderCellDef mat-sort-header>Strength </th>
                                <td [hidden]="!isStrengthAnalysisShow"  mat-cell *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>

                            <!-- location Column -->
                            <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                                <td mat-cell *matCellDef="let element">
                                    <span 
                                        *ngIf="selectedRow != element && element.checked">{{element.canisterLocation}}</span>
                                    <span 
                                        *ngIf="selectedRow != element && !element.checked">{{element.canisterLocation}}</span>

                                    <mat-form-field *ngIf="selectedRow == element">
                                        <mat-select [disabled]="!element.checked || element.canisterLocation>0" 
                                            (selectionChange)="drugCellLocationChanged(element)" 
                                            [(ngModel)]="element.canisterLocation">
                                            <mat-option *ngFor="let row of celLogList" 
                                                [value]="row.value">{{row.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>


                            <!-- drawer Column -->
                            <ng-container matColumnDef="drawer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer</th>
                                <td mat-cell *matCellDef="let element"> {{element.drawer}} </td>
                            </ng-container>

                            <!-- pipette Column -->
                            <ng-container matColumnDef="pipette">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Pipette</th>
                                <td mat-cell *matCellDef="let element"> {{element.pipette}} </td>
                            </ng-container>

                            <!-- containerSize column-->
                            <ng-container matColumnDef="containerSize">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Container Size </th>
                                <td mat-cell *matCellDef="let element"> {{element.containerSize}} </td>
                            </ng-container>

                            <!-- massIndex Column -->
                            <ng-container matColumnDef="massIndex">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mass Index </th>
                                <td mat-cell *matCellDef="let element"> {{element.massIndex}} </td>
                            </ng-container>

                            <!-- dropHeight Column -->
                            <ng-container matColumnDef="dropHeight">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Small Drop Height </th>
                                <td mat-cell *matCellDef="let element"> {{element.dropHeight}} </td>
                            </ng-container>

                              <!-- dropHeight Column -->
                              <ng-container matColumnDef="largeDropHeight">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Large Drop Height </th>
                                <td mat-cell *matCellDef="let element"> {{element.largeDropHeight}} </td>
                            </ng-container>

                            <!-- lideHole Column -->
                            <ng-container matColumnDef="lideHole">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Lide Hole</th>
                                <td mat-cell *matCellDef="let element"> {{element.lideHole}} </td>
                            </ng-container>

                            <!-- volcanic Column -->
                            <ng-container matColumnDef="volcanic">
                                <th [hidden]="!isVolcanicShow" mat-header-cell *matHeaderCellDef mat-sort-header>Volcanic </th>
                                <td [hidden]="!isVolcanicShow" mat-cell *matCellDef="let element"> {{element.volcanic}} </td>
                            </ng-container>

                            <!-- fragile Column -->
                            <ng-container matColumnDef="fragile">
                                <th [hidden]="!isFragileShow" mat-header-cell *matHeaderCellDef mat-sort-header>Fragile</th>
                                <td [hidden]="!isFragileShow" mat-cell *matCellDef="let element"> {{element.fragile}} </td>
                            </ng-container>

                            <!-- toggle Column -->
                            <ng-container matColumnDef="toggle">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Toggle </th>
                                <td mat-cell *matCellDef="let element"> {{element.toggle}} </td>
                            </ng-container>


                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                            <!-- containerType Name Column -->
                            <ng-container matColumnDef="containerType">
                                <th mat-header-cell [hidden]="true" *matHeaderCellDef mat-sort-header> Container Type</th>
                                <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.containerType}} </td>
                            </ng-container>

                              <!-- usage Column -->
                              <ng-container matColumnDef="usage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Usage</th>
                                <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
                            </ng-container>

                            <!-- Action Details -->
                            <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef>
                                  <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                      <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                  </mat-icon>
                                  <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="before">

                                      <mat-checkbox [(ngModel)]="isStrengthAnalysisShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Strength
                                    </mat-checkbox>

                                    <mat-checkbox [(ngModel)]="isMnemonicAnalysisShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Customer Mnemonic
                                    </mat-checkbox>
                                    <mat-checkbox [(ngModel)]="islidTypeShow" class="table-dyanamic-column" style="border-bottom: 0px solid black;" fxLayout="row">
                                        Lid Type
                                    </mat-checkbox>
                                  </mat-menu>
                              </th>
                              <td mat-cell *matCellDef="let element" style="text-align: right;">
                                  <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                      <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                  </mat-icon>
                              </td>
                          </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" 
                                (mouseenter)="selectedRow = row" mat-row 
                                *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSource.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="New Drugs({{dataSourceNotAbailable.data.length}})">
                    <div class="mat-elevation-z8" #TABLE1>
                        <table mat-table #table1 [dataSource]="dataSourceNotAbailable" matSort>

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <!-- <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected"
                                        [(ngModel)]="isAnyDrugSelected"></mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" 
                                        [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order</th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>

                            <!-- ndc Name Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC</th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                            <!-- customerDrugId column-->
                            <ng-container matColumnDef="customerDrugId">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugId}} </td>
                            </ng-container>

                            <!-- cddbName Column -->
                            <ng-container matColumnDef="cddbName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.cddbName}} </td>
                            </ng-container>


                            <!-- customerDrugName Column -->
                            <ng-container matColumnDef="customerDrugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Name</th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugName}} </td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th [hidden]="!isStrengthNewShow" mat-header-cell *matHeaderCellDef mat-sort-header>Strength </th>
                                <td [hidden]="!isStrengthNewShow" mat-cell *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>

                            <!-- drawer Column -->
                            <ng-container matColumnDef="drawer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer</th>
                                <td mat-cell *matCellDef="let element"> {{element.drawer}} </td>
                            </ng-container>

                            <!-- pipette Column -->
                            <ng-container matColumnDef="pipette">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Pipette</th>
                                <td mat-cell *matCellDef="let element"> {{element.pipette}} </td>
                            </ng-container>

                            <!-- containerSize column-->
                            <ng-container matColumnDef="containerSize">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Container Size </th>
                                <td mat-cell *matCellDef="let element"> {{element.containerSize}} </td>
                            </ng-container>

                            <!-- massIndex Column -->
                            <ng-container matColumnDef="massIndex">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mass Index </th>
                                <td mat-cell *matCellDef="let element"> {{element.massIndex}} </td>
                            </ng-container>


                            <!-- lideHole Column -->
                            <ng-container matColumnDef="lideHole">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Lide Hole</th>
                                <td mat-cell *matCellDef="let element"> {{element.lideHole}} </td>
                            </ng-container>

                            <!-- volcanic Column -->
                            <ng-container matColumnDef="volcanic">
                                <th [hidden]="!isVolcanicShow" mat-header-cell *matHeaderCellDef mat-sort-header>Volcanic </th>
                                <td [hidden]="!isVolcanicShow" mat-cell *matCellDef="let element"> {{element.volcanic}} </td>
                            </ng-container>

                            <!-- fragile Column -->
                            <ng-container matColumnDef="fragile">
                                <th [hidden]="!isFragileShow" mat-header-cell *matHeaderCellDef mat-sort-header>Fragile</th>
                                <td [hidden]="!isFragileShow" mat-cell *matCellDef="let element"> {{element.fragile}} </td>
                            </ng-container>

                            <!-- toggle Column -->
                            <ng-container matColumnDef="toggle">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Toggle </th>
                                <td mat-cell *matCellDef="let element"> {{element.toggle}} </td>
                            </ng-container>


                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                             <!-- usage Column -->
                             <ng-container matColumnDef="usage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Usage</th>
                                <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
                            </ng-container>
                             <!-- location Column -->
                             <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Location</th>
                                <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                            </ng-container>

                              <!-- Action Details -->
                              <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                        <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="before">

                                        <mat-checkbox [(ngModel)]="isStrengthNewShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                          Strength
                                      </mat-checkbox>


                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsCommon"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" 
                                (mouseenter)="selectedRow = row" mat-row 
                                *matRowDef="let row; columns: displayedColumnsCommon;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSource.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Excluded({{dataSourceExcluded.data.length}})">
                    <div class="mat-elevation-z8" #TABLE2>
                        <table mat-table #table2 class="mat-elevation-z8" [dataSource]="dataSourceExcluded" matSort>

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <!-- <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected"
                                        [(ngModel)]="isAnyDrugSelected"></mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" 
                                        [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order</th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>

                            <!-- excludedReason Column -->
                            <ng-container matColumnDef="excludedReason">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Excluded Reason</th>
                                <td mat-cell *matCellDef="let element"> {{element.excludedReason}} </td>
                            </ng-container>

                            <!-- ndc Name Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC</th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                            <!-- customerDrugId column-->
                            <ng-container matColumnDef="customerDrugId">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugId}} </td>
                            </ng-container>

                            <!-- cddbName Column -->
                            <ng-container matColumnDef="cddbName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.cddbName}} </td>
                            </ng-container>


                            <!-- customerDrugName Column -->
                            <ng-container matColumnDef="customerDrugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Name</th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugName}} </td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th [hidden]="!isStrengthExcludeShow" mat-header-cell *matHeaderCellDef mat-sort-header>Strength </th>
                                <td [hidden]="!isStrengthExcludeShow"  mat-cell *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>

                            <!-- drawer Column -->
                            <ng-container matColumnDef="drawer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer</th>
                                <td mat-cell *matCellDef="let element"> {{element.drawer}} </td>
                            </ng-container>

                            <!-- pipette Column -->
                            <ng-container matColumnDef="pipette">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Pipette</th>
                                <td mat-cell *matCellDef="let element"> {{element.pipette}} </td>
                            </ng-container>

                            <!-- containerSize column-->
                            <ng-container matColumnDef="containerSize">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Container Size </th>
                                <td mat-cell *matCellDef="let element"> {{element.containerSize}} </td>
                            </ng-container>

                            <!-- massIndex Column -->
                            <ng-container matColumnDef="massIndex">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mass Index </th>
                                <td mat-cell *matCellDef="let element"> {{element.massIndex}} </td>
                            </ng-container>

                            <!-- lideHole Column -->
                            <ng-container matColumnDef="lideHole">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Lide Hole</th>
                                <td mat-cell *matCellDef="let element"> {{element.lideHole}} </td>
                            </ng-container>

                            <!-- volcanic Column -->
                            <ng-container matColumnDef="volcanic">
                                <th [hidden]="!isVolcanicShow" mat-header-cell *matHeaderCellDef mat-sort-header>Volcanic </th>
                                <td [hidden]="!isVolcanicShow" mat-cell *matCellDef="let element"> {{element.volcanic}} </td>
                            </ng-container>

                            <!-- fragile Column -->
                            <ng-container matColumnDef="fragile">
                                <th [hidden]="!isFragileShow" mat-header-cell *matHeaderCellDef mat-sort-header>Fragile</th>
                                <td [hidden]="!isFragileShow" mat-cell *matCellDef="let element"> {{element.fragile}} </td>
                            </ng-container>

                            <!-- toggle Column -->
                            <ng-container matColumnDef="toggle">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Toggle </th>
                                <td mat-cell *matCellDef="let element"> {{element.toggle}} </td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                             <!-- usage Column -->
                             <ng-container matColumnDef="usage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Usage</th>
                                <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
                            </ng-container>
  <!-- location Column -->
  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Location</th>
    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
</ng-container>
                          <!-- Action Details -->
                          <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                    <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                </mat-icon>
                                <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="before">

                                    <mat-checkbox [(ngModel)]="isStrengthExcludeShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Strength
                                  </mat-checkbox>


                                </mat-menu>
                            </th>
                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                    <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                </mat-icon>
                            </td>
                        </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsExcluded"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" 
                                (mouseenter)="selectedRow = row" mat-row 
                                *matRowDef="let row; columns: displayedColumnsExcluded;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSource.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Not Oral Solid({{dataSourceOral.data.length}})">
                    <div class="mat-elevation-z8" #TABLE3>
                        <table mat-table #table3 [dataSource]="dataSourceOral" matSort>

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <!-- <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected"
                                        [(ngModel)]="isAnyDrugSelected"></mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" 
                                        [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order</th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>

                            <!-- ndc Name Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC</th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                          <!-- Dosage Form Column -->
                          <ng-container matColumnDef="dosageForm">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dosage Form</th>
                            <td mat-cell *matCellDef="let element"> {{element.dosageForm}} </td>
                        </ng-container>

                            <!-- customerDrugId column-->
                            <ng-container matColumnDef="customerDrugId">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugId}} </td>
                            </ng-container>

                            <!-- cddbName Column -->
                            <ng-container matColumnDef="cddbName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.cddbName}} </td>
                            </ng-container>


                            <!-- customerDrugName Column -->
                            <ng-container matColumnDef="customerDrugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Name</th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugName}} </td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th [hidden]="!isStrengthOralShow" mat-header-cell *matHeaderCellDef mat-sort-header>Strength </th>
                                <td [hidden]="!isStrengthOralShow" mat-cell *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>

                            <!-- drawer Column -->
                            <ng-container matColumnDef="drawer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer</th>
                                <td mat-cell *matCellDef="let element"> {{element.drawer}} </td>
                            </ng-container>

                            <!-- pipette Column -->
                            <ng-container matColumnDef="pipette">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Pipette</th>
                                <td mat-cell *matCellDef="let element"> {{element.pipette}} </td>
                            </ng-container>

                            <!-- containerSize column-->
                            <ng-container matColumnDef="containerSize">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Container Size </th>
                                <td mat-cell *matCellDef="let element"> {{element.containerSize}} </td>
                            </ng-container>

                            <!-- massIndex Column -->
                            <ng-container matColumnDef="massIndex">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mass Index </th>
                                <td mat-cell *matCellDef="let element"> {{element.massIndex}} </td>
                            </ng-container>

                            <!-- lideHole Column -->
                            <ng-container matColumnDef="lideHole">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Lide Hole</th>
                                <td mat-cell *matCellDef="let element"> {{element.lideHole}} </td>
                            </ng-container>

                            <!-- volcanic Column -->
                            <ng-container matColumnDef="volcanic">
                                <th [hidden]="!isVolcanicShow" mat-header-cell *matHeaderCellDef mat-sort-header>Volcanic </th>
                                <td [hidden]="!isVolcanicShow" mat-cell *matCellDef="let element"> {{element.volcanic}} </td>
                            </ng-container>

                            <!-- fragile Column -->
                            <ng-container matColumnDef="fragile">
                                <th [hidden]="!isFragileShow" mat-header-cell *matHeaderCellDef mat-sort-header>Fragile</th>
                                <td [hidden]="!isFragileShow" mat-cell *matCellDef="let element"> {{element.fragile}} </td>
                            </ng-container>

                            <!-- toggle Column -->
                            <ng-container matColumnDef="toggle">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Toggle </th>
                                <td mat-cell *matCellDef="let element"> {{element.toggle}} </td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                             <!-- usage Column -->
                             <ng-container matColumnDef="usage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Usage</th>
                                <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
                            </ng-container>
  <!-- location Column -->
  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Location</th>
    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
</ng-container>
                            <!-- Action Details -->
                            <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef>
                                  <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                      <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                  </mat-icon>
                                  <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="before">

                                      <mat-checkbox [(ngModel)]="isStrengthOralShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Strength
                                    </mat-checkbox>


                                  </mat-menu>
                              </th>
                              <td mat-cell *matCellDef="let element" style="text-align: right;">
                                  <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                      <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                  </mat-icon>
                              </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsNotOral"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" 
                                (mouseenter)="selectedRow = row" mat-row 
                                *matRowDef="let row; columns: displayedColumnsNotOral;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSource.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </section>
    <table [hidden]="true" #table5>
        <tr><td colspan="26" style="font-size: 15px; font-weight: bold;">{{modelNo}}{{synmedRobotNo}}-{{etuqueteModelTitle}}{{machineNo}} Uniprix Beaucage, French-Canada</td></tr>
        <tr>
            <th></th>
            <th>Tiroir--></th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>7</th>
            <th>8</th>
            <th>9</th>
            <th>10</th>
            <th>11</th>
            <th>12</th>
            <th>13</th>
            <th>14</th>
            <th>15</th>
            <th>16</th>
            <th>17</th>
            <th>18</th>
            <th>19</th>
            <th>20</th>
            <th>21</th>
            <th>22</th>
            <th>23</th>
            <th>Total général</th>
        </tr>
        <tr *ngFor="let item of etiquetteExportList">
            <td>{{ item.itemOne }}</td>
            <td>{{ item.itemTwo }}</td>
            <td>{{ item.one }}</td>
            <td>{{ item.two }}</td>
            <td>{{ item.three }}</td>
            <td>{{ item.four }}</td>
            <td>{{ item.five }}</td>
            <td>{{ item.six }}</td>
            <td>{{ item.seven }}</td>
            <td>{{ item.eight }}</td>
            <td>{{ item.nine }}</td>
            <td>{{ item.ten }}</td>
            <td>{{ item.eleven }}</td>
            <td>{{ item.twelve }}</td>
            <td>{{ item.thirteen }}</td>
            <td>{{ item.forteen }}</td>
            <td>{{ item.fifteen }}</td>
            <td>{{ item.sixteen }}</td>
            <td>{{ item.seventeen }}</td>
            <td>{{ item.eighteen }}</td>
            <td>{{ item.nineteen }}</td>
            <td>{{ item.twenty }}</td>
            <td>{{ item.twentyOne }}</td>
            <td>{{ item.twentyTwo }}</td>
            <td>{{ item.twentyThree}}</td>
            <td>{{ item.totalGeneral }}</td>
          </tr>
    </table>

    <table [hidden]="true" #table6>
        <tr><td colspan="6" style="font-size: 15px; font-weight: bold;">{{modelNo}}{{synmedRobotNo}}-{{etuqueteModelTitle}}{{machineNo}} Uniprix Beaucage, French-Canada</td></tr>
        <tr>
            <th>Tiroir</th>
            <th>No</th>
            <th>Nom Primaire</th>
            <th># Med ID</th>
            <th>Contenant</th>
            <th>Couvercles</th>
        </tr>
        <tr *ngFor="let item of etiquetteList">
            <td>{{item.troir}}</td>
            <td>{{item.location}}</td>
            <td>{{item.primaryMedName}}</td>
            <td>{{item.medId}}</td>
            <td>{{item.contenant}}</td>
            <td>{{item.couvercles}}</td>
        </tr>
    </table>
</app-card>
