import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppCardOptions } from '../../../../shared/models/ui/app-card-options';
import { ToastService } from '../../../../shared/services/toast.service';
import { OrganizationService } from '../../services/organization.service';
import { LaunchDarklyService } from 'src/app/shared/services/launch-darkly.service';
import { filter } from 'rxjs/operators';

export interface EditCustomerChildRoute {
  title: string;
  routerLink: string;
  idValue: string;
  launchDarklyFlag?: string;
}

export const editCustomerChildRoutes: EditCustomerChildRoute[] = [{
  title: 'Onboarding',
  routerLink: 'onboarding',
  idValue: 'OrgOnboardingTab',
  launchDarklyFlag: 'CustomerManagement_Onboarding',
}, {
  title: 'Permission Groups',
  routerLink: 'permission-groups',
  idValue: 'PermissionGroupsTab',
}, {
  title: 'User Mgmt & Permissions',
  routerLink: 'user-mgmt-permissions',
  idValue: 'OrgUserManagementTab',
}, {
  title: 'Consumables Subscription',
  routerLink: 'consumables-subscription',
  idValue: 'OrgConsumablesTab',
  launchDarklyFlag: 'CustomerManagement_ConsumablesSubscription',
}];

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit {

  public organizations: AppCardOptions[] = [];
  public selectedIndex: number;
  public visibleChildren: EditCustomerChildRoute[] = [];
  public SelectedOrgName: string;
  public SelectedSiteName: string;
  public activeChild: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private organizationService: OrganizationService,
    private toastService: ToastService,
    public launchDarklyService: LaunchDarklyService,
  ) { }

  ngOnInit() {
    this.loadCustomers();
    this.route.params.subscribe(() => this.setActiveCustomer());

    this.activeChild = this.route?.snapshot?.firstChild?.routeConfig?.path;
    this.router?.events.pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe(() => this.activeChild = this.route?.snapshot?.firstChild?.routeConfig?.path);

    this.launchDarklyService?.flagChange.subscribe((flags) => {
      this.visibleChildren = editCustomerChildRoutes
        .filter(({ launchDarklyFlag }) => !launchDarklyFlag ? true : flags[launchDarklyFlag]);

      // navigate if activated children is not in allowed tab
      if(!this.visibleChildren.some(({ routerLink }) => routerLink === this.activeChild)) {
        this.router.navigate([this.visibleChildren[0]?.routerLink], { relativeTo: this.route });
      }
    });
  }

  loadCustomers() {
    this.ngxLoader.start('load-customers');
    this.organizationService.getActiveOrganizations()
      .subscribe((organizations) => {
        this.ngxLoader.stop('load-customers');

        const activeId = this.route?.snapshot?.params?.id;
        const site = organizations.filter((t) => t?.organizationId === activeId);

        if (site.length > 0) {
          const org = organizations.filter((t) => t?.organizationId === site[0]?.parent_Account_Id);
          this.SelectedOrgName = org[0]?.name;
          this.SelectedSiteName = `${this.SelectedOrgName} - (${site[0]?.name})`;
        }
      }, (response) => this.toastService
        .stopLoadingAndShowError(response, 'Error while loading organizations!', 'load-customers'));
  }

  setActiveCustomer() {
    const activeId = this.route?.snapshot?.params?.id;
    this.selectedIndex = this.organizations.findIndex(({ organizationId }) => organizationId === activeId);
  }
}
