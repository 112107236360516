import * as fromPharmacyPeekReducer from './pharmacy-peek.reducer';
import * as fromPharmacyPeekWidgetReducer from './pharmacy-peek-widgets.reducer';
import * as fromInventoryReducer from './inventory.reducer';
import * as fromAssetsReducer from './assets.reducer';
import * as fromPreProvisionReducer from './fleet-manager.reducer';
import * as fromFleetConfigReducer from './fm-configuration.reducer';
import * as fromScriptsReducer from './scripts.reducer';
import * as fromSignalrReducer from './signalr.reducer';

import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from 'src/environments/environment';
import { InvRptData } from 'src/app/external-user/inventory/components/inventory-child/inventory-views/inventory-views.component';

export interface State {
    pharmacyPeek: fromPharmacyPeekReducer.State;
    pharmacyPeekWidgets: fromPharmacyPeekWidgetReducer.State;
    inventory: InvRptData;
    assets: fromAssetsReducer.State;
    iotDeviceDetails: fromPreProvisionReducer.State;
    fleetConfig: fromFleetConfigReducer.State;
    scripts: fromScriptsReducer.State;
    signalr: fromSignalrReducer.State;
  }

export const reducers: ActionReducerMap<State> = {
    pharmacyPeek: fromPharmacyPeekReducer.reducer,
    pharmacyPeekWidgets: fromPharmacyPeekWidgetReducer.reducer,
    inventory: fromInventoryReducer.reducer,
    assets: fromAssetsReducer.reducer,
    iotDeviceDetails : fromPreProvisionReducer.reducer,
    fleetConfig : fromFleetConfigReducer.reducer,
    scripts : fromScriptsReducer.reducer,
    signalr: fromSignalrReducer.reducer,
  };

  const reducerKeys = ['pharmacyPeek', 'inventory', 'assets', 'iotDeviceDetails', 'fleetConfig'];
  export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: reducerKeys})(reducer);
  }

  export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function(state, action) {
      console.log('state', state);
      console.log('action', action);

      return reducer(state, action);
    };
  }

  export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug, localStorageSyncReducer] : [localStorageSyncReducer];
