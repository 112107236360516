import { DataService } from '../../../shared/services/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FleetConfigurationService extends DataService<any> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.fleetConfigService}`, http);
    this.httpClient = http;
  }

  getConfigTemplates(): Observable<any> {
    return this.http.get<any[]>(`${this.url}`);
  }

  postConfigTemplate(templateDetails: any): Observable<any> {
    // return this.http.post( ${this.url}/${templateType}`, templateDetails);
    return this.http.post(`${this.url}`, JSON.stringify(templateDetails),
    {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }
  
  putConfigTemplate(templateDetails: any): Observable<any> {
    return this.http.put(`${this.url}`, JSON.stringify(templateDetails),
    {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }
  
  disableDevice(deviceId):Observable<any> {
    return this.http.post<any>(`${this.url}/${deviceId}/disable`, 
    {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }
}
