import { UpdateDrug } from './../models/drug';
import { CreateDrug } from './../models/create-drug';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateInventory, DestroyInventory } from '../models/inventory';
import { AssignCanister } from '../models/canister';

@Injectable({
  providedIn: 'root'
})
export class RpoToolService {
  httpClient: HttpClient;
  url: string;
  constructor(http: HttpClient) {
    this.httpClient = http;
    this.url = environment.apiUrl.rpoToolService;
  }


  getDrugData(
    url: string = this.url + "RpoLandingScreen"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });

  }

  getSearchDrugData(
    ndcOrName: string,
    country_id: number,
    pageNo: number,
    pageSize: number,

    url: string = this.url + "SearchDrug"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        ndcOrName: ndcOrName,
        pageNo: pageNo,
        pageSize: pageSize,
        country_id: country_id
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });

  }

  getQuarantinedDrugata(
    searchText: string,
    country_id: number,
    pageNo: number,
    pageSize: number,

    url: string = this.url + "QuarantinedDrug"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        ndcOrName: searchText,
        pageNo: pageNo,
        pageSize: pageSize,
        country_id: country_id
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });

  }

  getDrugDetailsByNdc(
    ndc: any,
    url: string = this.url + "GetDrugByNDC"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        ndc: ndc
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getCoating(
    url: string = this.url + "GetCoating"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getCountry(
    url: string = this.url + "GetCountry"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getClassificationShape(
    url: string = this.url + "GetClassificationShape"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getCountryList(
    url: string = this.url + "GetCountryList"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getDrugClassificationType(
    url: string = this.url + "GetDrugNumberType"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getCommonDrugStatus(
    url: string = this.url + "CommonDrugStatus"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getPassDrugStatus(
    url: string = this.url + "PassDrugStatus"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getMaxDrugStatus(
    url: string = this.url + "MaxDrugStatus"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  
  getSynmedDrugStatus(
    url: string = this.url + "SynmedDrugStatus"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getAtpDrugStatus(
    url: string = this.url + "AtpDrugStatus"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getPillType(
    url: string = this.url + "GetPillType"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getScoring(
    url: string = this.url + "GetScoring"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getSchedule(
    url: string = this.url + "GetSchedule"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getUnit(
    search: string,
    url: string = this.url + "GetUnit"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        search: search
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getQuarantineReasonList(
    url: string = this.url + "GetQuarantineReasonList"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getManufacture(
    search,
    url: string = this.url + "GetManufacture"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        search: search
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getDistributor(search): Observable<any> {
    return this.httpClient.get(this.url + "GetDistributor", {
      params: {
        search: search
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getRecommendedDrug(
    length: any,
    width: any,
    thickness: any,
    weight: any,
    diagonal: any,
    classificationShapeId: any,
    url: string = this.url + "GetRecommendedDrug"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        Length: length,
        Width: width,
        Thickness: thickness,
        Weight: weight,
        Diagonal: diagonal,
        ClassificationShapeId: classificationShapeId
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getOtc(
    url: string = this.url + "GetOtc"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getRouteOfAdministration(
    search: any,
    url: string = this.url + "GetRouteOfAdministration"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        search: search
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getColor(
    search: any,
    url: string = this.url + "GetColor"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        search: search
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getDosageForm(
    search: any,
    url: string = this.url + "GetDosageForm"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        search: search
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }


  getNiosh(
    url: string = this.url + "GetNiosh"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getPackageType(
    search: string,
    url: string = this.url + "GetPackageType"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        search: search
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getPackageSize(
    id: string,
    search: string,
    url: string = this.url + "GetPackageSize"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        id: id,
        search: search
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getImages(
    ndc: string,
    id: number,
    url: string = this.url + "SliderImage"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        ndc: ndc,
        id: id
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }


  getDataSource(
    url: string = this.url + "GetDataSource"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  saveDrug(
    savedrug: CreateDrug,
    url: string = this.url + "AddNewDrug"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(savedrug), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  updateDrug(
    drug: UpdateDrug,
    url: string = this.url + "EditDrug"
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(drug), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  assignCanister(
    assignCanister: AssignCanister,
    url: string = this.url + "AssignSelectedCanister"
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(assignCanister), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getInventoryById(
    id: any,
    url: string = this.url + "GetInventoryById"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        id: id
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }



  getInventoryByNDC(
    ndc: any,
    url: string = this.url + "GetInventoryByNdc"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        ndc: ndc
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  saveInventory(
    saveInventory: CreateInventory,
    url: string = this.url + "SaveInventory"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(saveInventory), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }


  destroyInventory(
    updateInventory: DestroyInventory,
    url: string = this.url + "UpdateInventory"
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(updateInventory), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
}
