import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightMatched'
})
export class HighlightMatchedPipe implements PipeTransform {

  transform(value: string, search: string): any {
    if (value && search) {
      const start = value.toLowerCase().indexOf(search.trim().toLowerCase());
      const end = search.length + start;

      if (start === -1) {
        return value;
      }

      return `${value.slice(0, start)}<mark>${value.slice(start, end)}</mark>${value.slice(end)}`;
    }
    return value;
  }
}
