<!---START: Wizard First Screen-->
<div *ngIf="this.isparentnotassociated===1 || this.isparentnotassociated===2" class="flex justify-between content-stretch items-stretch flex-col">

  <h1 mat-dialog-title id="addnewsite" style="margin: 0px 1px 13px 1px; ">
    <div class="flex justify-start content-center items-center flex-row">
      <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
        IMPORT WIZARD
      </div>
      <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
        <mat-icon (click)="dialogRefImportWizardComponent.close()" class="cross-wizard">
          <img src="/assets/img/icons/ico.16.clear.svg" alt="">
        </mat-icon>
      </div>

    </div>

  </h1>
  <div *ngIf="this.isparentnotassociated===1">
    <div style="padding: 0px 15px 0px 15px;" class="flex justify-start content-stretch items-stretch flex-row">
      <div appDragDrop class="fileuploaddiv" *ngIf="!fieleSelect" (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" (change)="onFileSelected($event)" 
          title="please select only xls and csv files" accept=".xlsx, .xls, .csv" />
        <div class="flex justify-start content-center items-center flex-row">
          <div class="basis-[5%]">
            <img src="../../../../assets/img/icons/ico.24.export.svg" alt="">
          </div>
          <div class="basis-[35%]">
            <p>Drag and drop the file you would like to upload</p>
            <label for="fileDropRef">Accepted file formats are .xlsx, .xls, and .csv.</label>
          </div>
          <div style="padding: 15px 0px 0px 0px;" class="flex flex-row justify-center content-stretch items-stretch h-full basis-1/4">
            <b> OR</b>
          </div>
          <div style="padding: 12px 0px 0px 0px;" class="flex flex-row justify-end content-stretch items-stretch h-full basis-[35%]">
            <button mat-button class="btn-light btn-md btn-done m-l-16" id="OrgChoosen" type="file" 
              aria-label="Next">Browse My Computer</button>
          </div>
        </div>


      </div>


      <div appDragDrop class="fileselecteddiv flex" *ngIf="fieleSelect && percentage==0">

        <div class="selected-file flex flex-1 justify-start content-stretch items-stretch max-w-[32%]">
          <mat-icon style="padding-bottom: 11px;">
            <img src="/assets/img/icons/ico.24.document.svg" alt="" style="width: 34px; margin-top: 15px;">
          </mat-icon>
          <span class="selected-file-name">{{fielName}}</span>
          <div style="padding: 12px 0px 0px 0px;">
            <mat-icon (click)="onRemoveFile()" id="OrgChoosen">
              <img src="/assets/img/icons/ico.16.trash.svg" alt="" style="color: red;">
            </mat-icon>
          </div>

        </div>

        <div *ngIf="fileUploadButtonShow" style="padding: 12px 0px 0px 0px;" class="flex flex-1 justify-start content-stretch items-stretch max-w-[48%]">
          <button mat-button class="btn-blue btn-sm btn-done m-l-16" (click)="fileUpload()" id="OrgChoosen" type="file" 
            aria-label="Next">Upload</button>
        </div>

      </div>

      <div appDragDrop class="fileselecteddiv" *ngIf="fieleSelect && percentage==100">

        <div class="meter" *ngIf="fieleSelect && percentage>=0">
          <p class="meter-filename"><b>Uploading </b>({{fielName}})</p>
          <span [style.width]="percentage+'%'" [style.background-color]="backgroundcolor"><b 
              style="padding-right: 50%; color: white !important;">{{percentage}}%</b></span>
        </div>

      </div>
    </div>

    <form [formGroup]="drugFileInfoForm">

      <mat-dialog-content style="margin: 19px 16px 0px !important; height: 21vw;">

        <h6 class="m-t-0 m-b-0">Map imported columns to [Tool Name] format</h6>

        <div class="flex justify-start content-center items-center flex-row">
          <span>
            <mat-icon class="cross-wizard">
              <img src="/assets/img/icons/ico.asterisk.svg" alt="">

            </mat-icon>
          </span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedDrugName">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mappedDrugName.hasError('required')">Drug Name required</mat-error>
          </mat-form-field>

          <span style="padding-left: 6px"> = Drug Name
          </span>
          <span class="help_icon" matTooltip=" This is the name the customer assigns to the drug. this is used as a reference only." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row">
          <span>
            <mat-icon class="cross-wizard">
              <img src="/assets/img/icons/ico.asterisk.svg" alt="">
            </mat-icon>
          </span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedNDC">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mappedNDC.hasError('required')">NDC required</mat-error>
          </mat-form-field>

          <span style="padding-left: 6px"> = NDC</span>
          <span class="help_icon" matTooltip="This is the number used to uniquely identify a Drug, Vitamin or OTC. Requirements - 1. must be between 9-12 digits, 2. can contain hyphens. 3. Acceptable formats - NDC or UPC" matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">
          <span>
            <mat-icon class="cross-wizard">
              <img *ngIf="drugFileInfoForm.get('noUsage').value == false" src="/assets/img/icons/ico.asterisk.svg" 
                alt="">
            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select (selectionChange)="updateNoUsage()" formControlName="mappedUsage">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mappedUsage.hasError('required')">Number of required</mat-error>
          </mat-form-field>

          <span style="padding-left: 6px; margin-top: 1px;">
            <mat-radio-group [disabled]="drugFileInfoForm.get('noUsage').value==true" formControlName="orderOrUsage" 
              aria-label="Number of Orders">
              <mat-radio-button [value]='1' [checked]="drugFileInfoForm.get('usageValue').value  == 2">
                Prescriptions Usage</mat-radio-button>
              <mat-radio-button style="padding-left: 6px" [checked]="drugFileInfoForm.get('usageValue').value == 1" 
                [value]="2" aria-label="Unit Usage"> Pill Usage</mat-radio-button>

            </mat-radio-group>
          </span>
          <span class="help_icon" matTooltip="This is the total number of RXs the pharmacy has filled. Requirements - numeric values only." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>


        </div>

        <div class="flex justify-start content-center items-center flex-row">
          <span>
            <mat-icon class="cross-wizard">

            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select (selectionChange)="updateNoUsage()" formControlName="mappedNoOfOrders">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>

          </mat-form-field>
          <span style="padding-left: 6px">
            <mat-radio-group [disabled]="drugFileInfoForm.get('noUsage').value==true" formControlName="usageValue" 
              aria-label="Number of Orders">
              <mat-radio-button [value]="1" [checked]="drugFileInfoForm.get('orderOrUsage').value  == 2">
                Prescriptions Usage</mat-radio-button>
              <mat-radio-button style="padding-left: 6px" [value]="2" aria-label="Unit Usage" 
                [checked]="drugFileInfoForm.get('orderOrUsage').value  == 1"> Pill
                Usage</mat-radio-button>

            </mat-radio-group></span>
          <span class="help_icon" matTooltip="This is the total number of Pills the pharmacy has dispensed. Requirements - numeric values only" matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>


        <div class="flex justify-start content-center items-center flex-row">
          <span>
            <mat-icon class="cross-wizard">

            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedMnemonic" (selectionChange)="isSelecteMappedMnomonic()">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug?.name">{{drug?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span style="padding-left: 6px"> = Customer Mnemonic </span>
          <span class="help_icon" matTooltip="a unique number for a drug assigned by a specific customer." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>


        <div class="flex justify-start content-center items-center flex-row">
          <span>
            <mat-icon class="cross-wizard">
              <img *ngIf="drugFileInfoForm.get('noUsage').value == true" src="/assets/img/icons/ico.asterisk.svg" 
                alt="">
            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select [disabled]="drugFileInfoForm.get('noUsage').value==false" 
              formControlName="mappedSuperCellSelect">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug?.name">{{drug?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span style="padding-left: 6px"> = Super Cell Select </span>
          <span class="help_icon" matTooltip="This option is for no usage Max implementations when the user manually assigns super cells to specific locations in the Max. • Requirements - create a new column with a Y next to any drug the user wants a super cell for." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedLockingCell">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Locking Cell Select</span>
          <span class="help_icon" matTooltip="This option is for no usage Max implementations when the user manually assigns locking cells to specific regular and super locations in the Max. • Requirements - create a new column with a Y next to any drug the user wants a locking cell for." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">
          <span>
            <mat-icon class="cross-wizard">
              <img *ngIf="drugFileInfoForm.get('noUsage').value == true" src="/assets/img/icons/ico.asterisk.svg" 
                alt="">
            </mat-icon>
          </span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedRanking">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>

          </mat-form-field>

          <span style="padding-left: 6px"> = Ranking</span>
          <span class="help_icon" matTooltip="this field is for Pharmacies that have no usage information to determine what their most preferred drugs are. • Requirements - • the user must add and map a column. • with the Ranking of each drug the most preferred marked with 1 ascending to the least preferred." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpLot">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>

          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Lot#</span>
          <span class="help_icon" matTooltip="an Inventory control number assigned by the customer. This will be exported directly to the appropriate Documents." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row">

          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpDrugExpirationDate">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Drug Expiration Date</span>
          <span class="help_icon" matTooltip="a drug use by date assigned by the customer. This will be exported directly to the appropriate Documents." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">

          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerQuantity">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Quantity</span>
          <span class="help_icon" matTooltip="The Number of Pills the Customer has loaded into the Canisters." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpDeviceLocation">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Trade In Device Location</span>
          <span class="help_icon" matTooltip="This number assigns a drug directly to a specific location inside a device. This will be exported directly to the appropriate Documents." matTooltipPosition="after"
             matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpPillDescription">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Description</span>
          <span class="help_icon" matTooltip="a customer assigned pill description, This will be exported directly to the appropriate Documents." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpPillColor">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Color</span>
          <span class="help_icon" matTooltip="A customer provided Drug color - This will be exported directly to the appropriate Documents. " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">

          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpPillShape">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Shape</span>
          <span class="help_icon" matTooltip="A customer provided Pill Shape - This will be exported directly to the appropriate Documents. " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpImp1">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>

          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Imprint 1</span>
          <span class="help_icon" matTooltip="A customer provided Imprint - This will be exported directly to the appropriate Documents." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpImp2">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Imprint 2</span>
          <span class="help_icon" matTooltip="A customer provided Imprint - This will be exported directly to the appropriate Documents." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerBarcode">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> =Customer Barcode</span>
          <span class="help_icon" matTooltip="A customer provided Barcode to uniquely Identify a Drug  - This will be exported directly to the appropriate fields & Documents. " matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>


        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerOtc">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer OTC</span>
          <span class="help_icon" matTooltip="a customer assigned OTC drug. • Requirements - create a new column with a Y next to any drug the user wants to assign as OTC drug." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerComment">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Comment</span>
          <span class="help_icon" matTooltip="A customer provided Comment - This will be exported directly to the appropriate Documents. " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerInventoryNumber">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Inventory Number</span>
          <span class="help_icon" matTooltip="A customer provided Inventory Number - This will be exported directly to the appropriate fields & Documents. " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>



      </mat-dialog-content>

      <mat-card *ngIf="errorMessages.length>0" style="height: 150px;">
        <h5 style="margin: 10px 25px 1px 37px;">
          <div class="flex justify-start content-center items-center flex-row">
            <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
              Error Messages
            </div>
            <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
              <mat-icon class="cross-wizard error-icon flex flex-row justify-end content-end items-end h-full" 
                (click)="this.errorMessages=[]" aria-hidden="true">cancel</mat-icon>
            </div>
          </div>

        </h5>
        <mat-card-content style="text-align: center; height: 120px; overflow: scroll;">

          <mat-error *ngFor="let item of errorMessages">
            <span *ngIf="item.length>0">{{ item }}</span>
          </mat-error>

        </mat-card-content>
      </mat-card>

      <mat-dialog-actions class="flex justify-end content-end items-end flex-row">
        <button mat-button class="btn-danger  btn-md" (click)="drugFileInfoForm.reset(); valueSetAfterResetForm()" 
          id="Reset" type="button" aria-label="Reset">Reset All</button>
        <button mat-button class="btn-primary  btn-md" (click)="dialogRefImportWizardComponent.close()" id="Cancel" 
          type="button" aria-label="Cancel">Cancel</button>
        <button mat-button [ngClass]="{'btn-blue':drugFileInfoForm.valid, 'btn-disabled':!drugFileInfoForm.valid}" 
          [disabled]="!drugFileInfoForm.valid" class=" btn-md btn-done m-l-16" (click)="showsite(true)" type="button" 
          aria-label="Next">Next</button>
      </mat-dialog-actions>
    </form>


  </div>

  <div *ngIf="this.isparentnotassociated===2" class="inner-mat-dialog flex flex-col justify-between content-stretch items-stretch w-full">
    <form [formGroup]="drugWithCanisterForm">
      <div>

        <mat-dialog-content #dialogContent class="add-site-exitorg-container">
          <!--START: Uploaded File Name-->
          <label> Document</label>
          <p style="font-weight: bold !important;">{{fielName}}</p>
          <!--END: Uploaded File Name-->

          <!--START: Device Type Dropdown -->
          <div style="padding-top: 10px;" class="flex flex-row">
            <mat-form-field floatLabel="never" class="sm:basis-[47%] basis-[17.3%]">
              <label for="DeviceType">
                <span>
                  <mat-icon class="cross-wizard" style="margin-bottom: 13px;">
                    <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                  </mat-icon>
                </span>
                <span>Device Type</span>
              </label>

              <mat-select formControlName="deviceId" (selectionChange)="getSystemDevieType($event.value)">
                <mat-option *ngFor="let type of deviceTypes" [value]="type.id">{{type.deviceName}}</mat-option>
              </mat-select>
              <mat-error *ngIf="deviceId.hasError('required')">Device Type required</mat-error>
            </mat-form-field>
          </div>
          <!--END: Device Type Dropdown -->

          <!--START: Device Sub Type Dropdown: ATP  & Synmed DEVICE -->
          <div class="sub-device flex flex-row" style="margin-bottom: 10px;" 
            *ngIf="drugWithCanisterForm.value.deviceId===3 || drugWithCanisterForm.value.deviceId===4">
            <mat-form-field floatLabel="never" class="sm:basis-[47%] basis-[17.3%]">
              <label for="Device Sub-Type">
                <span>
                  <mat-icon class="cross-wizard" style="margin-bottom: 13px;">
                    <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                  </mat-icon>
                </span>
                <span>Device Sub-Type</span>
              </label>
              <mat-select [disabled]="subdeviceTypes.length==0" (selectionChange)="isDisabled()" 
                formControlName="deviceTypeId">
                <div class="search-wrapper" style="padding: 9px">
                  <div class="search-container">
                    <app-icon icon="search" class="icon_search"></app-icon>
                    <input class="search-input" inputId="searchcustomers-input" minLengthToSearch="1" 
                      placeholder="Search" formControlName="searchText" id="search_customers">
                    <app-icon icon="clear" *ngIf="searchText.value != null" (click)="searchText.reset()" id="data-clear" 
                      class="pointer"></app-icon>
                  </div>
                </div>
                <mat-option *ngFor="let subtype of subdeviceTypes| filter: {deviceName : searchText.value} : false" 
                  [value]="subtype.subDeviceTypeId">{{subtype.subDeviceTypeName}}</mat-option>
              </mat-select>
              <mat-error *ngIf="deviceTypeId.hasError('required')">Device sub-type required</mat-error>
            </mat-form-field>
          </div>
          <!--END: Device Sub Type Dropdown: ATP & Synmed DEVICE -->

          <!--START: SYNMED Device => Robot Number-->
          <div style="margin-bottom: 10px;" *ngIf="drugWithCanisterForm.value.deviceId===4" class="flex flex-col">
            <label for="Machine Number" class="deviceType-label">
              <span>
                <mat-icon class="cross-wizard" style="margin-bottom: 13px;">
                  <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                </mat-icon>
              </span>
              <span>Customer Robot Number</span>
            </label>
            <div floatLabel="never" class="sm:basis-[47%] basis-1/4">
              <input class="custom-input" formControlName="machineNumber" type="text">
              <!-- <mat-error *ngIf="machineNumber.hasError('required')">Country code is required</mat-error> -->
            </div>
          </div>

          <div style="margin-bottom: 10px;" *ngIf="drugWithCanisterForm.value.deviceId===4" class="flex flex-col">
            <label for="Synmed Robot Number" class="deviceType-label">
              <span>
                <mat-icon class="cross-wizard" style="margin-bottom: 13px;">
                  <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                </mat-icon>
              </span>
              <span>Synmed Robot Number</span>
            </label>
            <div floatLabel="never" class="sm:basis-[47%] basis-1/4">
              <input class="custom-input" formControlName="synmedRobotNumber" type="text">
              <!-- <mat-error *ngIf="machineNumber.hasError('required')">Country code is required</mat-error> -->
            </div>
          </div>
          <!--END: SYNMED Device => Robot Number -->


          <!--START: SYNMED Device => Country Code-->
          <div class="synmed-device flex" style="margin-bottom: 10px;" *ngIf="drugWithCanisterForm.value.deviceId===4">
            <mat-form-field floatLabel="never" class="sm:basis-[47%] sm:w-[47%] basis-[17.3%] w-[17.3%]">
              <label for="Country Code">
                <span>
                  <mat-icon class="cross-wizard" style="margin-bottom: 13px;">
                    <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                  </mat-icon>
                </span>
                <span>Country Code</span>
              </label>
              <mat-select formControlName="countryCode">
                <mat-option *ngFor="let country of countryCodeList" [value]="country.value">{{country.key}}</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="countryCode.hasError('required')">Country code is required</mat-error> -->
            </mat-form-field>
          </div>
          <!--END: SYNMED Device => Country Code -->

          <!--START: SYNMED Device =>Drug ID-->
          <div class="synmed-device flex" style="margin-bottom: 10px;" *ngIf="isCustomerMnemonic.value === true">
            <mat-form-field floatLabel="never" class="sm:basis-[47%] sm:w-[47%] basis-[17.3%] w-[17.3%]">
              <label for="Drug ID">
                <span>
                  <mat-icon class="cross-wizard" style="margin-bottom: 13px;">
                    <!-- <img src="/assets/img/icons/ico.asterisk.svg" alt=""> -->
                  </mat-icon>
                </span>
                <span>Drug ID</span>
              </label>
              <mat-select formControlName="ndcVariant">
                <mat-option *ngFor="let row of ndcVariantList" [value]="row.key">{{row.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--END: SYNMED Device => Drug ID -->
          <!--START: DrugName choosen Dropdown: ATP DEVICE -->
          <div style="margin-bottom: 10px;">
            <h6 class="m-t-0 m-b-0" *ngIf="drugWithCanisterForm.value.deviceId===3">Choose the drug name
              export format</h6>
          </div>
          <div style="margin-bottom: 10px;" 
            *ngIf="drugWithCanisterForm.value.deviceId===3" class="flex flex-row gap-2.5">

            <mat-form-field floatLabel="never" class="sm:basis-[24%] basis-[11%]">
              <label for="primaryName">
                <span>Primary Drug Name</span>
              </label>

              <mat-select formControlName="primaryDrugName">
                <mat-option *ngFor="let type of drugNameSource" [value]="type.value">{{type.key}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field floatLabel="never" class="sm:basis-[24%] basis-[11%]">
              <label for="secondaryName">
                <span>Secondary Drug Name</span>
              </label>

              <mat-select formControlName="secondaryDrugName">
                <mat-option *ngFor="let type of drugNameSource" [value]="type.value">{{type.key}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--END: DrugName choosen Dropdown: ATP DEVICE -->


          <!-- START : Exclution Section-->
          <div style="padding-top: 1.5rem;">
            <h6 class="m-t-0 m-b-0">Choose what to exclude from the attached file</h6>
            <div class="flex flex-row">
              <div floatLabel="never" class="file-type-config sm:basis-[47%] basis-[47.3%]">
                <mat-checkbox formControlName="isOtcExcluded" value="true">
                  Exclude</mat-checkbox>
                <span>Over the counter (OTC) drugs</span>

              </div>
            </div>

            <div class="flex flex-row">
              <div floatLabel="never" class="file-type-config sm:basis-[15.3%] basis-[15.3%]">
                <mat-checkbox (change)="resetDrugsCheckbox()" formControlName="isScheduleExcluded" [value]="true">
                  Exclude</mat-checkbox>
                <span>Schedule</span>
              </div>
              <div class="drug-checkbox-group">
                <mat-checkbox [disabled]="drugWithCanisterForm.get('isScheduleExcluded').value==false"
                  formControlName="drugTypeOne" [value]="1" (change)="onChange(1, $event.checked)">
                  I</mat-checkbox>

                <mat-checkbox [disabled]="drugWithCanisterForm.get('isScheduleExcluded').value==false" 
                  formControlName="drugTypeTwo" [value]="2" (change)="onChange(2, $event.checked)">
                  II</mat-checkbox>
                <mat-checkbox [disabled]="drugWithCanisterForm.get('isScheduleExcluded').value==false" 
                  formControlName="drugTypeThree" [value]="3" (change)="onChange(3, $event.checked)">
                  III</mat-checkbox>

                <mat-checkbox [disabled]="drugWithCanisterForm.get('isScheduleExcluded').value==false" 
                  formControlName="drugTypeFour" [value]="4" (change)="onChange(4, $event.checked)">
                  IV</mat-checkbox>

                <mat-checkbox [disabled]="drugWithCanisterForm.get('isScheduleExcluded').value==false" 
                  formControlName="drugTypeFive" [value]="5" (change)="onChange(5, $event.checked)">
                  V</mat-checkbox>

                <span>Drugs</span>
              </div>

            </div>

            <div class="flex flex-row">
              <div floatLabel="never" class="file-type-config sm:basis-[15.3%] basis-[15.3%]">
                <mat-checkbox (change)="resetDrugsCheckbox()" formControlName="isNioshExcluded" [value]="true">
                  Exclude</mat-checkbox>
                <span>Niosh Drugs</span>
              </div>
              <div class="drug-checkbox-group">
                <mat-checkbox [disabled]="drugWithCanisterForm.get('isNioshExcluded').value ==false" 
                  formControlName="drugAntineoplastic" [checked]="drugAntineoplastic.value==1" [value]="1" 
                  (change)="onChangeNioshDrug(1, $event.checked)">
                  I Antineoplastic</mat-checkbox>

                <mat-checkbox [disabled]="drugWithCanisterForm.get('isNioshExcluded').value ==false" 
                  formControlName="drugNonAntineoplastic" [checked]="drugAntineoplastic.value==2" [value]="2" 
                  (change)="onChangeNioshDrug(2, $event.checked)">
                  II non-antineoplastic haz</mat-checkbox>

                <mat-checkbox [disabled]="drugWithCanisterForm.get('isNioshExcluded').value ==false" 
                  formControlName="drugReproductive" [value]="3" [checked]="drugAntineoplastic.value==3" 
                  (change)="onChangeNioshDrug(3, $event.checked)">
                  III Reproductive effects</mat-checkbox>

              </div>

            </div>

            <div class="flex flex-row">
              <div floatLabel="never" class="file-type-config sm:basis-[17.3%] basis-[17.3%]">

                <mat-checkbox formControlName="isUnitOfUsageExcluded" (change)="resetDrugsCheckbox()" value="true">
                  Exclude</mat-checkbox>
                <span>Unit Of Usage </span>
              </div>
              <div class="drug-checkbox-group">
                <mat-radio-group [disabled]="isUnitOfUsageExcluded.value==false" formControlName="unitOfUsageValue" 
                  aria-label="Select an option Unit Of Usage">
                  <mat-radio-button value="30" [checked]="drugWithCanisterForm.value.unitOfUsageValue == 30">QTY
                    30</mat-radio-button>
                  <mat-radio-button style="padding-left: 6px" value="60" 
                    [checked]="drugWithCanisterForm.value.unitOfUsageValue == 60">QTY
                    60</mat-radio-button>
                  <mat-radio-button style="padding-left: 6px" value="90" 
                    [checked]="drugWithCanisterForm.value.unitOfUsageValue == 90">QTY
                    90</mat-radio-button>
                </mat-radio-group>
              </div>


            </div>
            <!-- <div class="flex flex-row">
              <div floatLabel="never" class="file-type-config sm:basis-[15.3%] basis-[15.3%]">
                <mat-checkbox formControlName="isScheduleExcluded" [value]="true">
                  Exclude</mat-checkbox>
                <span>Antibiotics</span>
              </div>
            </div> -->

            <div *ngIf="drugWithCanisterForm.value.deviceId===3 && drugWithCanisterForm.value.deviceTypeId" class="flex flex-row">
              <div class="cell-input"><input min="0" onkeypress="return !(event.charCode == 46)" 
                  [readonly]="this.isReadOnly" formControlName="minimumDaysSupply" type="number">
                <label>Minimum Days Supply </label>
              </div>

              <div class="cell-input"><input min="0" (change)="atpValidationForAnalyze()" 
                  onkeypress="return !(event.charCode == 46)" 
                  [readonly]="this.isReadOnly || drugWithCanisterForm.value.deviceTypeId===9" 
                  formControlName="highCapDrawerNo" type="number"><label>High Cap Drawer</label></div>

              <div class="cell-input"><input min="0" (change)="atpValidationForAnalyze()" 
                  onkeypress="return !(event.charCode == 46)" [readonly]="this.isReadOnly" 
                  formControlName="smartDrawerNo" type="number"><label>Smart Drawers </label></div>
            </div>
            <div style="margin-top: .8rem;" class="flex flex-row"
              *ngIf="drugWithCanisterForm.value.deviceId===3 && drugWithCanisterForm.value.deviceTypeId">

              <div style="width:105px;">
                <mat-form-field>
                  <mat-select formControlName="dayOfUse">
                    <mat-option *ngFor="let row of dayOfUsageList" [value]="row.key">{{row.key}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div style="font: normal normal normal 14px/16px Lato; padding-top: .8rem; padding-right: .5rem;">
                <label> Customer Drug List Timespan </label>
              </div>
              <span>
                <mat-icon class="cross-wizard">
                  <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                </mat-icon>
              </span>
            </div>
          </div>
          <!-- END : Exclution Section-->

          <div *ngIf="drugWithCanisterForm.get('deviceId').value>0" class="analyze-section flex flex-col">
            <div class="basis-[17.3%]">
              <button style="margin-bottom: .8rem;" mat-button class="btn-blue btn-md" 
                [disabled]="isAnalyzeButtonDisabled()" (click)="endPointRequestSentBasedOnDevice(true)" id="Analyze" 
                aria-label="Analyze" type="button">Analyze</button>
            </div>

            <mat-error *ngIf="drugWithCanisterForm.value.deviceId===1 && countAnalysisDrugInfo.length===0" 
              style="margin-top: 9px;">* Analyzation must be completed before adding a sub device
              type</mat-error>


            <div style="margin-top: .8rem;" class="flex flex-col"
              *ngIf="countAnalysisDrugInfo.length>0 && drugWithCanisterForm.value.deviceId===3">
              <div class="count-section flex flex-row">
                <span class="basis-[13.3%]">Available Locations: <b 
                    *ngIf="countAnalysisDrugInfo[0]">{{countAnalysisDrugInfo[0].value}}</b></span>
                <span class="basis-[14.3%]">Available Smart Locations: <b 
                    *ngIf="countAnalysisDrugInfo[1]">{{countAnalysisDrugInfo[1].value}}</b></span>
                <span class="basis-[18.3%]">Available HighCap Locations: <b 
                    *ngIf="countAnalysisDrugInfo[2]">{{countAnalysisDrugInfo[2].value}}</b></span>
              </div>
            </div>
            <div class="flex flex-col" *ngIf="countAnalysisDrugInfo.length>0 && drugWithCanisterForm.value.deviceId===1">
              <h5 style="margin: 16px 3px;">Cell Recommendation</h5>
              <div class="count-section flex flex-row">
                <span class="basis-[13.3%]">Super cell: <b>{{countAnalysisDrugInfo[2]?.value}}</b></span>
                <span class="basis-[12.3%]">Locking cell: <b>{{countAnalysisDrugInfo[0]?.value}}</b></span>
                <span class="basis-[12.3%]">Regular cell: <b 
                    *ngIf="countAnalysisDrugInfo[4]?.value">{{countAnalysisDrugInfo[4]?.value}}</b></span>
                <span class="basis-[16.3%]">Super locking cell:
                  <b>{{countAnalysisDrugInfo[3]?.value}}</b></span>
                <span class="basis-[12.3%]">Schedule ll: <b>{{countAnalysisDrugInfo[0]?.value}}</b></span>
                <span class="basis-[12.3%]">Schedule lll: <b>{{countAnalysisDrugInfo[1]?.value}}</b></span>
              </div>
            </div>
            <!--START: Device Sub Type Dropdown: MAX DEVICE -->
            <div class="sub-device flex" style="margin-bottom: 10px;" 
              *ngIf="drugWithCanisterForm.value.deviceId===1 && countAnalysisDrugInfo.length>0">

              <mat-form-field floatLabel="never" class="sm:basis-[47%] sm:w-[47%] basis-[17.3%] w--[17.3%]">
                <label for="DeviceType">
                  <span>
                    <mat-icon class="cross-wizard" style="margin-bottom: 13px;">
                      <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                    </mat-icon>
                  </span>
                  <span>Device Sub-Type</span>
                </label>
                <mat-select [disabled]="subdeviceTypes.length==0" (selectionChange)="isDisabled()" 
                  formControlName="deviceTypeId">
                  <div class="search-wrapper" style="padding: 9px">
                    <div class="search-container">
                      <app-icon icon="search" class="icon_search"></app-icon>
                      <input class="search-input" inputId="searchcustomers-input" minLengthToSearch="1" 
                        placeholder="Search" formControlName="searchText" id="search_customers">
                      <app-icon icon="clear" *ngIf="searchText.value != null" (click)="searchText.reset()" 
                        id="data-clear" class="pointer"></app-icon>
                    </div>
                  </div>

                  <mat-option 
                    *ngFor="let subtype of subdeviceTypes| filter: {subDeviceTypeName : searchText.value} : false" 
                    [value]="subtype.subDeviceTypeId">{{subtype.subDeviceTypeName}}</mat-option>



                </mat-select>
                <mat-error *ngIf="deviceTypeId.hasError('required')">Device sub-type
                  required</mat-error>

              </mat-form-field>

            </div>
            <!--END: Device Sub Type Dropdown: MAX DEVICE -->
            <div class="max-form-control flex flex-row gap-5">

              <!-- Minimum Days Supply -->
              <div class="cell-input flex flex-col basis-[13.3%]"
                *ngIf="drugWithCanisterForm.value.deviceId === 1 && countAnalysisDrugInfo.length > 0 && noUsage.value == false">
                <div style="padding-bottom: 3px;">
                  <span>
                    <mat-icon class="cross-wizard">
                      <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                    </mat-icon>
                  </span>
                  <label>Minimum Days Supply: </label>
                </div>

                <input min="0" onkeypress="return !(event.charCode == 46)" [readonly]="this.isReadOnly" 
                  formControlName="minimumDaysSupply" type="number">
              </div>

              <!-- Days Per Week Pharmacy Open -->
              <div class="timespan-input flex flex-col basis-[15.3%]" 
                *ngIf="countAnalysisDrugInfo.length > 0 && drugWithCanisterForm.value.deviceId === 1 && noUsage.value == false">
                <div class="flex flex-row">
                  <span>
                    <mat-icon class="cross-wizard">
                      <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                    </mat-icon>
                  </span>
                  <label>Days Per Week Pharmacy Open </label>
                </div>
                <mat-form-field>
                  <mat-select formControlName="daysOfWeek">
                    <mat-option *ngFor="let row of daysPerWeekList" [value]="row.key">{{row.key}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- Customer Drug List Timespan -->
              <div class="timespan-input flex flex-col basis-[15.3%]" 
                *ngIf="countAnalysisDrugInfo.length > 0 && drugWithCanisterForm.value.deviceId === 1 && noUsage.value == false">
                <div class="flex flex-row">
                  <span>
                    <mat-icon class="cross-wizard">
                      <img src="/assets/img/icons/ico.asterisk.svg" alt="">
                    </mat-icon>
                  </span>
                  <label>Customer Drug List Timespan: </label>
                </div>
                <mat-form-field>
                  <mat-select formControlName="dayOfUse">
                    <mat-option *ngFor="let row of dayOfUsageList" [value]="row.key">{{row.key}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Super Cells -->
              <div class="cell-input flex flex-col basis-[10.3%]" 
                *ngIf="countAnalysisDrugInfo.length > 0 && drugWithCanisterForm.value.deviceId === 1">
                <label>Super Cells: </label>
                <input onkeypress="return !(event.charCode == 46)" min="0" [readonly]="this.isReadOnlyForLite" 
                  (change)="validataionMessage()" formControlName="superCellNo" type="number">
              </div>

            </div>
            <div *ngIf="drugWithCanisterForm.value.deviceId===3 && countAnalysisDrugInfo.length>0" class="flex flex-row">
              <div class="cell-input"><input min="0" onkeypress="return !(event.charCode == 46)" 
                  [readonly]="this.isReadOnly" (change)="atpValidationForAnalyze()" formControlName="initalCanister" 
                  type="number"> <label>Initial Canisters </label>
              </div>
              <div class="cell-input"><input min="0" onkeypress="return !(event.charCode == 46)" 
                  [readonly]="this.isReadOnly" (change)="atpValidationForAnalyze()" formControlName="smartCanister" 
                  type="number"><label>Initial Smart Canisters</label>
              </div>
              <div class="cell-input"><input min="0" onkeypress="return !(event.charCode == 46)" 
                  [readonly]="this.isReadOnly" (change)="atpValidationForAnalyze()" formControlName="highCapCanister" 
                  type="number"><label>Initial High Cap
                  Canisters</label></div>
            </div>


            <div floatLabel="never" class="type-configuration flex flex-col sm:grow-[47] sm:basis-0 grow-[17] basis-0" 
              style="margin-top: 13px;" 
              *ngIf="drugWithCanisterForm.value.deviceId===1 && countAnalysisDrugInfo.length>0">

              <label for="Configuration">
                <span>Configuration</span>
              </label>
              <div class="flex flex-row">
                <mat-radio-group formControlName="configuration" aria-label="Select an option" class="config-radio-button-group">
                  <mat-radio-button [value]="1" 
                    [checked]="drugWithCanisterForm.value.configuration == 1">Standard</mat-radio-button>
                  <mat-radio-button [value]="3" 
                    [checked]="drugWithCanisterForm.value.configuration == 3">Locking</mat-radio-button>
                  <mat-radio-button 
                    [disabled]="drugWithCanisterForm.get('deviceTypeId').value == 3 || drugWithCanisterForm.get('deviceTypeId').value == 4" 
                    [value]="2" [checked]="drugWithCanisterForm.value.configuration == 2">Custom</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="configuration.hasError('required')">Configuration is
                  required</mat-error>
              </div>

            </div>

            <div *ngIf="drugWithCanisterForm.value.deviceId===1 && countAnalysisDrugInfo.length>0" class="flex flex-row">
              <div class="cell-input basis-[16.3%]" *ngIf="drugWithCanisterForm.value.configuration==2">
                <label>locking cells: </label><input min="0" onkeypress="return !(event.charCode == 46)" 
                  (change)="validataionMessage()" [readonly]="this.isReadOnly" formControlName="lockingCellNo" 
                  width="51%" type="number">
              </div>
              <div class="cell-input basis-[18.3%]" *ngIf="drugWithCanisterForm.value.configuration==2">
                <label>super locking cells: </label><input onkeypress="return !(event.charCode == 46)" 
                  [readonly]="this.isReadOnly" (change)="validataionMessage()" min="0" 
                  formControlName="superLockingCellNo" width="51%" type="number">
              </div>
            </div>

            <div *ngIf="drugWithCanisterForm.value.deviceId===4 && !this.isEmpty(synmedAnalyzeData)" class="flex flex-row gap-2.5">

              <div class="count-section flex flex-col gap-2.5">
                <span class="basis-[13.3%]">Large Containers & Large Pipettes: <b>{{
                    this.synmedAnalyzeData.largeLargeCount}}</b></span>
                <span class="basis-[12.3%]">Large Containers & Either Pipettes:
                  <b>{{this.synmedAnalyzeData.largeEitherCount}}</b></span>
                <span class="basis-[12.3%]">Large Containers & Small Pipettes:
                  <b>{{this.synmedAnalyzeData.largeSmallCount }}</b></span>
                <span class="basis-[16.3%]">Non-Certified Drugs:
                  <b>{{this.synmedAnalyzeData.nonCetritifedDrugs}}</b></span>

              </div>
              <div class="count-section flex flex-col gap-2.5">
                <span class="basis-[12.3%]">Small Containers & Either Pipettes:
                  <b>{{this.synmedAnalyzeData.smallEitherCount }}</b></span>
                <span class="basis-[12.3%]">Small Containers &amp;amp; Small Pipettes:
                  <b>{{this.synmedAnalyzeData.smallSmallCount }}</b></span>
                <span class="basis-[16.3%]">Schedule II Drugs:
                  <b>{{this.synmedAnalyzeData.schedule2DrugCount}}</b></span>
                <span class="basis-[13.3%]">Fragile Drugs: <b>{{this.synmedAnalyzeData.fragileCount
                    }}</b></span>
              </div>
              <div class="count-section flex flex-col gap-2.5">
                <span class="basis-[13.3%]">Both Containers & Large Pipettes:
                  <b>{{this.synmedAnalyzeData.bothLargeCount}}</b></span>
                <span class="basis-[12.3%]">Both Containers & Either Pipettes: <b>{{
                    this.synmedAnalyzeData.bothEitherCount}}</b></span>
                <span class="basis-[12.3%]">Both Container & Small Pipettes:
                  <b>{{this.synmedAnalyzeData.bothSmallCount}}</b></span>
                <span class="basis-[16.3%]">Toggle Drugs: <b>{{this.synmedAnalyzeData.toggleCount}}</b></span>
              </div>
            </div>
          </div>
          <div #analyzeDiv><span></span></div>

        </mat-dialog-content>
      </div>

      <mat-dialog-actions *ngIf="this.isparentnotassociated===2" class="flex justify-end content-end items-end xs:flex xs:flex-col flex-row">
        <button mat-button class="btn-primary btn-md" (click)="dialogRefImportWizardComponent.close()" 
          id="CancelNewUser" aria-label="Cancel" type="button">Cancel</button>
        <button mat-button *ngIf="showbackbutton" class="btn-primary btn-md m-l-16" (click)="hidesite(1)" id="Back" 
          aria-label="Back" type="button">Back</button>
        <button *ngIf="drugWithCanisterForm.value.deviceId!==4 " mat-button 
          [ngClass]="{'btn-blue':drugWithCanisterForm.valid || analyzeOpened, 'btn-disabled':!drugWithCanisterForm.valid||!analyzeOpened}" 
          [disabled]="!drugWithCanisterForm.valid || validataionMessage() || atpValidationForAnalyze()" 
          class="btn-md m-l-16" id="Next" (click)="drugWithCanisterInfoSave()" aria-label="Import" type="button">Next
        </button>
        <button *ngIf="drugWithCanisterForm.value.deviceId ===4 " mat-button 
          [ngClass]="{'btn-blue':drugWithCanisterForm.valid || analyzeOpened, 'btn-disabled':!drugWithCanisterForm.valid||!analyzeOpened ||!synmedValidation()}" 
          [disabled]="!drugWithCanisterForm.valid" class="btn-md m-l-16" id="Next" (click)="synmedDrugSave()" id="Next"
          aria-label="Import" type="button">Next </button>
      </mat-dialog-actions>
    </form>
  </div>

</div>
<!---END: Wizard First Screen-->


<!--START: Import Wizard GCN-->
<div *ngIf="this.isparentnotassociated===3" class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
  <div>
    <!-- <h1 mat-dialog-title class="flex flex-row">
      <div class="flex justify-start content-center items-center flex-row">
        <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-2/4">
          Adjust Duplicate NDC
        </div>
        <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-2/4">
          <mat-icon (click)="dialogRefImportWizardComponent.close()" class="cross-wizard">
            <img src="/assets/img/icons/icon.16.states.svg" alt="">
          </mat-icon>
        </div>
      </div>

    </h1> -->
    <h1 mat-dialog-title id="addnewsite" style="margin: 0px 1px 13px 1px; ">
      <div class="flex justify-start content-center items-center flex-row">
        <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
          Adjust Duplicate NDC
        </div>
        <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
          <mat-icon (click)="dialogRefImportWizardComponent.close()" class="cross-wizard">
            <img src="/assets/img/icons/ico.16.clear.svg" alt="">
          </mat-icon>
        </div>
  
      </div>
  
    </h1>


    <mat-dialog-content>
      <div class="flex justify-start content-center items-center flex-row">
        <button mat-button [ngClass]="{'btn-primary':isHighestUsageDrugSelect, 'btn-light':!isHighestUsageDrugSelect}" 
          class="btn-light btn-sm" style="margin-right:5px" (click)="highestUsageDrugSelect()" id="Highest-Usage-Drugs" 
          type="button" aria-label="Highest Usage Drugs">Highest Usage Drugs</button>
        <button mat-button [ngClass]="{'btn-primary': isLowestUsageDrugSelect, 'btn-light':!isLowestUsageDrugSelect}" 
          class="btn-light btn-sm" style="margin-right:5px" id="Lowest-Usage-Drugs" (click)="lowestUsageDrugSelect()" 
          type="button" aria-label="Lowest Usage Drugs">Lowest Usage
          Drugs</button>
        <button mat-button [ngClass]="{'btn-primary':isButtonSelectLargeBottle, 'btn-light':!isButtonSelectLargeBottle}" 
          class="btn-light btn-sm" style="margin-right:5px" id="largest-bottole-size" (click)="largestBottleSize()" 
          type="button" aria-label="Largest Bottle Size">Largest Bottle Size</button>
        <button mat-button [ngClass]="{'btn-primary': isUnselectAll , 'btn-light':!isUnselectAll}" 
          class="btn-light btn-sm" style="margin-right:5px" (click)="allDrugUnSelect()" id="unselect-all" type="button" 
          aria-label="Unselect All">Unselect All</button>

      </div>
      <div class="mat-elevation-z8" #TABLE>
        <table #table mat-table [dataSource]="dataSource">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>

            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox [(ngModel)]="element.isChecked" 
                (change)="drugCellRowChecked(element, $event.checked)"></mat-checkbox>
            </td>
          </ng-container>
          <!-- SL Name Column -->
          <ng-container matColumnDef="sl">
            <th mat-header-cell *matHeaderCellDef> SL </th>
            <td mat-cell *matCellDef="let element"> {{element?.sl}} </td>
          </ng-container>

          <!-- Drug Name Column -->
          <ng-container matColumnDef="drugName">
            <th mat-header-cell *matHeaderCellDef> Drug Name </th>
            <td mat-cell *matCellDef="let element"> {{element?.customerDrugName}} </td>
          </ng-container>

          <!-- GCN Column -->
          <ng-container matColumnDef="gcnNumber">
            <th mat-header-cell *matHeaderCellDef> GCN </th>
            <td mat-cell *matCellDef="let element"> {{element?.gcnNumber}} </td>
          </ng-container>

          <!-- Ndc Column -->
          <ng-container matColumnDef="ndc">
            <th mat-header-cell *matHeaderCellDef> NDC </th>
            <td mat-cell *matCellDef="let element"> {{element?.ndc}}</td>
          </ng-container>

          <!-- packageSize Column -->
          <ng-container matColumnDef="packageSize">
            <th mat-header-cell *matHeaderCellDef> Pack QTY </th>
            <td mat-cell *matCellDef="let element"> ({{element.packageSize}})</td>
          </ng-container>


          <!-- Manufacturer Column -->
          <ng-container matColumnDef="manufacturer">
            <th mat-header-cell *matHeaderCellDef> Manufacturer</th>
            <td mat-cell *matCellDef="let element"> {{element?.manufacturer}} </td>
          </ng-container>
          <!-- strength Column -->
          <ng-container matColumnDef="strength">
            <th mat-header-cell *matHeaderCellDef> Strength</th>
            <td mat-cell *matCellDef="let element"> {{element?.strength}} </td>
          </ng-container>

          <!-- Unit Usage Column -->
          <ng-container matColumnDef="unitOfUsage">
            <th mat-header-cell *matHeaderCellDef> Unit Usage </th>
            <td mat-cell *matCellDef="let element"> {{element?.unitOfUsage}} </td>
          </ng-container>

          <!-- Rx Qty Column -->
          <ng-container matColumnDef="rxQty">
            <th mat-header-cell *matHeaderCellDef> Rx Qty </th>
            <td mat-cell *matCellDef="let element"> {{element?.rxQty}} </td>
          </ng-container>




          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row (click)="selectedRow = row" [style.background]="row?.backgroundColor" 
            [ngClass]="{ 'highlight': row === selectedRow }" *matRowDef="let row; columns: displayedColumns;"></tr>


        </table>
        <table>
          <tr *ngIf="dataSource?.data.length==0">
            <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
              No Records Found!
            </td>
          </tr>
        </table>

      </div>


    </mat-dialog-content>
  </div>
  <mat-dialog-actions class="flex justify-end content-end items-end w-full flex-row">

    <button mat-button class="btn-primary btn-md" (click)="hidesite(2)" id="Go_Back" type="button" 
      aria-label="Go_Back">Go Back</button>
    <button mat-button class="btn-blue btn-md" [disabled]="this.dataSource.data.length===0" 
      (click)="openAlertWindowForGcnExport()" id="Export" type="button" aria-label="Export">Export</button>
    <button mat-button class="btn-blue btn-md" id="Confirm" (click)="isOpenAlertWizard()" type="button" 
      aria-label="Confirm">Next</button>
  </mat-dialog-actions>

</div>
<!--END: Import Wizard GCN-->


<!--START: Group Canisters-->
<div *ngIf="this.isparentnotassociated===4 && drugWithCanisterForm.value.deviceId!==4" 
  class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
  <div>
    <h1 mat-dialog-title id="addnewsite" style="margin: 0px 1px 13px 1px; ">
      <div class="flex justify-start content-center items-center flex-row">
        <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
          Group Canisters
        </div>
        <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
          <mat-icon (click)="dialogRefImportWizardComponent.close()" class="cross-wizard">
            <img src="/assets/img/icons/ico.16.clear.svg" alt="">
          </mat-icon>
        </div>
  
      </div>
  
    </h1>


    <mat-dialog-content>
      <table mat-table [dataSource]="dataSourceGroupCanister" multiTemplateDataRows class="mat-elevation-z8 main-table">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef width="10%"> </th>
          <td mat-cell *matCellDef="let element" width="10%"><span style="font-weight: bold;">
              <mat-checkbox [(ngModel)]="element.isChecked" 
                (change)="groupCanisterRowSelect(element, $event.checked); element.isExpanded = !element.isExpanded;"></mat-checkbox>
              <span *ngIf="element.isExpanded">
                <mat-icon class="cross-wizard">
                  <img src="/assets/img/icons/ico.16.arwup.svg" alt="">
                </mat-icon>
              </span>
              <span *ngIf="!element.isExpanded">
                <mat-icon class="cross-wizard">
                  <img src="/assets/img/icons/ico.16.arwdown.svg" alt="">
                </mat-icon>
              </span>
            </span> </td>
        </ng-container>
        <!-- Checkbox Column -->
        <!-- <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox [(ngModel)]="element.isChecked" (change)="groupCanisterRowSelect(element, $event.checked)" ></mat-checkbox>
                    </td>
                </ng-container> -->

        <!--  Top Order Column -->
        <ng-container matColumnDef="topOrder">
          <th mat-header-cell *matHeaderCellDef> Top Order </th>
          <td mat-cell *matCellDef="let element"> {{element?.topOrder}} </td>
        </ng-container>
        <!--  Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element?.customerDrugName}} </td>
        </ng-container>
        <!--  Strength Column -->
        <ng-container matColumnDef="strength">
          <th mat-header-cell *matHeaderCellDef> Strength </th>
          <td mat-cell *matCellDef="let element"> {{element?.strength}} </td>
        </ng-container>
        <!-- NDC Column -->
        <ng-container matColumnDef="ndc">
          <th mat-header-cell *matHeaderCellDef> NDC </th>
          <td mat-cell *matCellDef="let element"> {{element?.ndc}} </td>
        </ng-container>

        <!-- Usage Column -->
        <ng-container matColumnDef="usage">
          <th mat-header-cell *matHeaderCellDef> Usage </th>
          <td mat-cell *matCellDef="let element"> {{element?.usage}} </td>
        </ng-container>


        <!-- Group Canister Column -->
        <ng-container matColumnDef="groupCanister">
          <th mat-header-cell *matHeaderCellDef>Canisters Assigned</th>
          <td mat-cell *matCellDef="let element">
            <input class="custom-input" 
              (change)="updateSelectedGroupCanisterList(element); element.groupCanister = element.groupCanister > 20 ? 0 : element.groupCanister" 
              [disabled]="true" type="number" [(ngModel)]="element.groupCanister">
          </td>
        </ng-container>
        <!-- hcgroupcanister Column -->
        <ng-container matColumnDef="highCapGroupCanister">
          <th mat-header-cell *matHeaderCellDef>HC Canisters Assigned</th>
          <td mat-cell *matCellDef="let element">
            <input class="custom-input" type="number" 
              (change)="updateSelectedGroupCanisterList(element); element.highCapGroupCanister = element.highCapGroupCanister > 20 ? 0 : element.highCapGroupCanister" 
              [disabled]="true" [(ngModel)]="element.highCapGroupCanister">
          </td>
        </ng-container>

        <!-- bottleQty Column -->
        <ng-container matColumnDef="bottleQty">
          <th mat-header-cell *matHeaderCellDef>Stock Bottle Size</th>
          <td mat-cell *matCellDef="let element">
            {{element.bottleQty}}</td>
        </ng-container>
        <!-- maxCap Column -->
        <ng-container matColumnDef="maxCap">
          <th mat-header-cell *matHeaderCellDef>Canister Capacity</th>
          <td mat-cell *matCellDef="let element">
            {{element.maxCap}}</td>
        </ng-container>

        <!-- hccMaxCap Column -->
        <ng-container matColumnDef="hccMaxCap">
          <th mat-header-cell *matHeaderCellDef>HC Canister Capacity</th>
          <td mat-cell *matCellDef="let element">
            {{element.hccMaxCap}}</td>
        </ng-container>

        <!-- daysOfPillQtyReq Column -->
        <ng-container matColumnDef="daysOfPillQtyReq">
          <th mat-header-cell *matHeaderCellDef>Days Of Use Pill Qty Req</th>
          <td mat-cell *matCellDef="let element">
            {{element.daysOfPillQtyReq}}</td>
        </ng-container>

        <!-- recCanister Column -->
        <ng-container matColumnDef="recCanister">
          <th mat-header-cell *matHeaderCellDef>Recomended Canisters</th>
          <td mat-cell *matCellDef="let element">
            {{element.recCanister}}</td>
        </ng-container>

        <!-- recHcCanister Column -->
        <ng-container matColumnDef="recHcCanister">
          <th mat-header-cell *matHeaderCellDef>Recomended HC Canisters</th>
          <td mat-cell *matCellDef="let element">
            {{element.recHcCanister}}</td>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" style="padding:0px" 
            [attr.colspan]="displayedColumnsGroupCanister.length">
            <div class="row queue-element-detail" [@detailExpand]="(element.isExpanded) ? 'expanded' : 'collapsed'">
              <div class="flex justify-start content-between items-between flex-row">
                <div class="device-cell flex flex-col">
                  <table>
                    <tr>
                      <th colspan="4">
                        ATP - Device
                      </th>
                    </tr>
                    <tr *ngFor="let row of atpTableData">
                      <td [id]="'drawer-'+element.ndc+'-'+cell" (click)=" showhideElement(element.ndc, cell, $event)" 
                        *ngFor="let cell of row">{{ cell }}</td>
                    </tr>
                  </table>
                </div>
                <div *ngFor="let drawer of deviceDrawer" class="device-design" style="display:none" 
                  [id]="element.ndc+'-'+drawer.drawerNumber">
                  <p [id]="'highcaplevel-'+element.ndc+'-'+drawer.drawerNumber" style="display:none">High Cap</p>
                  <p [id]="'smartdrawerlevel-'+element.ndc+'-'+drawer.drawerNumber" style="display:none;color:red">Smart</p>
                  
                  <table>
                    <tr *ngFor="let row of drawer.drawerCell">
                      <td [id]="'drawercell-'+element.ndc+'-'+drawer.drawerNumber+'-'+cell" 
                        (click)="selectDrawerCell(drawer.drawerNumber,element.ndc, cell, $event )" 
                        *ngFor="let cell of row">{{ cell }}</td>
                    </tr>
                  </table>
                  <span [id]="'drawerlevel-'+element.ndc+'-'+drawer.drawerNumber">{{drawer.drawerName}}</span>
                </div>

              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsGroupCanister; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumnsGroupCanister;" class="queue-element-row" 
          [class.queue-expanded-row]="element.isExpanded">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="queue-detail-row"></tr>


      </table>
      <table class="no-result" *ngIf="dataSourceGroupCanister.data.length==0">
        <tr style="text-align: center;">
          <td [attr.colspan]="displayedColumnsGroupCanister.length">
            <span>No results found</span>
          </td>
        </tr>

      </table>


    </mat-dialog-content>
  </div>
  <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
    <button mat-button class="btn-primary btn-md" (click)="openAlertWindowForGroupCanisterBackButton()" id="Go_Back" 
      type="button" aria-label="Go_Back">Go Back</button>
    <button mat-button class="btn-blue btn-md" (click)="saveGroupCanister()" id="Import" type="button" 
      aria-label="Import">Import</button>
  </mat-dialog-actions>

</div>
<!--END: Import Wizard GCN-->