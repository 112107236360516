<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
  <form [formGroup]="formGroup" >
    <div>
      <div>
        <h1 mat-dialog-title tabindex="0" class="xs:flex-1 sm:basis-full sysconfigdialog-header">
          System Configuration
        </h1>
      </div>

      <mat-dialog-content class="sysconfigdialog-container">
        <div class="xs:flex xs:flex-col flex gap-4 sm:flex sm:flex-row sm:flex-wrap">
            <mat-form-field  floatLabel="never" class="xs:flex-1 sm:basis-1/5">
              <label for="CustomerId">
                Account Name
                <custom-tooltip text="Customer's Account Name in Salesforce."></custom-tooltip>
              </label>
              <p>{{orgName}}</p>
              <input type="text" matInput id="CustomerId" 
                tabindex="0" readonly style="display: none !important;">
            </mat-form-field>           
         </div>
      
         <div class="deploy-color padding ">
          <div class="flex justify-start content-stretch items-stretch xs:flex-col gap-4 gt-xs:flex-row gt-xs:flex-wrap">
            <mat-form-field floatLabel="never" class="gt-xs:basis-1/5">
              <label for="DeploymentType">
                <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                Product Line Type
                <custom-tooltip text="Model of Device."></custom-tooltip>
              </label>

              <mat-select class="DeploymentType" id="DeploymentType" formControlName="deploymentType" 
                (selectionChange)="updateTemplate($event.value)" tabindex="0">
                <mat-option *ngFor="let type of deploymentList" [value]="type.formData">
                  {{ type.viewData }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="btn_add xs:flex-1 gt-xs:basis-[78%]">
              <button mat-button class="btn-default btn-sm" id="add" tabindex="0" type="button" (click)="addSystems()">Add</button>
            </div>
          </div>
          <div class="m-t-10 xs:flex xs:flex-col flex gap-4 flex-row">
            <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/5">
              <label for="siteId">
                <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                Customer Number
                <custom-tooltip text="Salesforce Customer Number."></custom-tooltip>
              </label>
              <input type="text" matInput appNoHtmlTags id="siteId" formControlName="siteId" [matAutocomplete]="auto"  (blur)="getSite($event.target.value)">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='getSite($event.option.value)'  [displayWith]="getSiteName.bind(this)">
                  <mat-option *ngFor="let option of filteredSites | async" [value]="option" matTooltip="{{option.name}} ({{option.customerNo}})" matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
                    {{option.name}} ({{option.customerNo}})
                  </mat-option>
                </mat-autocomplete>    
                <mat-error>{{ getSiteIdErrorMessage() }}</mat-error>                    
            </mat-form-field>
            <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/5">
              <label for="SystemId">
                <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                Serial Number 
                <custom-tooltip text="Device serial number, found on the nameplate attached to the chassis."></custom-tooltip>
              </label>
              <input type="text" matInput appNoHtmlTags id="systemId" formControlName="systemId" [matAutocomplete]="autosys" >
                <mat-autocomplete autoActiveFirstOption #autosys="matAutocomplete" [displayWith]="getSystemName.bind(this)">
                  <mat-option class="disableProvisionedSystems" *ngFor="let option of filteredSystems | async" [value]="option"  [disabled]="option.isProvisioned" matTooltip="{{option.name}} ({{option.machineNo}})" matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
                    <span *ngIf="option.isProvisioned">{{option.name}} ({{option.machineNo}}) &nbsp;&nbsp;&nbsp;&nbsp;CONFIGURED</span>
                    <span *ngIf="!option.isProvisioned">{{option.name}} ({{option.machineNo}})</span>
                  </mat-option>
                </mat-autocomplete>                                      
                <mat-error>{{ getSystemIdErrorMessage() }}</mat-error>
            </mat-form-field> 
            <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/5">
              <label for="HostName">
                <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                IP Address
                <custom-tooltip text="The IP address of the database on the device connected to stratus."></custom-tooltip>
              </label>
              <input type="text" matInput appNoHtmlTags trim id="dbHostName" formControlName="dbHostName" maxlength="50"
                tabindex="0" pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$">
              <mat-error *ngIf="formGroup.get('dbHostName').hasError('required')">IP Address required</mat-error>
              <mat-error *ngIf="formGroup.get('dbHostName').hasError('pattern')">IP Address is invalid</mat-error>
              <mat-error *ngIf="formGroup.get('dbHostName').hasError('duplicateIp')">IP Address is duplicate</mat-error>
            </mat-form-field>
              <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/5">
                <label for="User">
                  <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
                  Database Username
                  <custom-tooltip text="Username for the device database."></custom-tooltip>
                </label>
                <mat-select class="usernamedd" id="dbusername" formControlName="dbusername" tabindex="0">
                <mat-option *ngFor="let type of deploymentUserList" [value]="type.formData">
                  {{ type.viewData }}
                </mat-option>
              </mat-select>
                <mat-error *ngIf="formGroup.get('dbusername').hasError('required')">Database Username required</mat-error>
              </mat-form-field>
            </div>        
        </div>

        <div class="sysmgmt m-t-20">
          <mat-accordion class="usermgmgt" [multi]="true">
            <!-- Common Table Header -->
            <table #tblProvisionedHeader mat-table [dataSource]="packagerList" 
             class="prepro_table header-table table-container" tabindex="0" 
            role="table">
       
              <!-- Common Column Definitions -->
              <ng-container matColumnDef="siteFriendlyName">
                <th mat-header-cell *matHeaderCellDef>
                  <span class="cls-siteFriendlyName" style="padding-bottom: 10px;">
                    <img src="../../../../../assets/img/icons/ico.12.states.svg" alt="" class="icon-states">
                    Customer Friendly Name
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.siteFriendlyName" data-label="Customer Friendly Name" tabindex="0"></td>
              </ng-container>
              <ng-container matColumnDef="siteId" >
                <th mat-header-cell *matHeaderCellDef >
                  <span class="cls-siteid">                    
                    Customer Number
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.siteId" data-label="Site ID" tabindex="0"></td>
              </ng-container>
              <ng-container matColumnDef="systemFriendlyName">
                <th mat-header-cell *matHeaderCellDef>
                  <span class="cls-systemFriendlyName">
                    System Friendly Name
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.systemFriendlyName" data-label="System Friendly Name" tabindex="0"></td>
              </ng-container>
              <ng-container matColumnDef="systemId" >
                <th mat-header-cell *matHeaderCellDef > 
                  <span class="cls-systemid">Serial Number</span>
                </th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.systemId" data-label="System ID" tabindex="0"></td>
              </ng-container>
              <ng-container matColumnDef="hostName"   >
                <th mat-header-cell *matHeaderCellDef   >
                  <span class="cls-systemid">IP Address</span>
                </th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.hostName" data-label="HostName" tabindex="0"></td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            </table>
       
            <mat-expansion-panel togglePosition="before" [expanded]="true" *ngFor="let element of provisionedList.data">
              <mat-expansion-panel-header (click)="toggleRow(element)">
                <mat-panel-title>
                  <span class="cls-orgname basis-4/5">
                    {{element?.groupName | uppercase}} ({{ element.count}})</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row-tables ">
                <table #tblProvisionedContent mat-table [dataSource]="element.orgSysList" class="systems_table row-table" tabindex="0" role="table">
       
                  <!-- Common Column Definitions -->
                  <ng-container matColumnDef="siteFriendlyName" >
                    <th mat-header-cell *matHeaderCellDef   class="cls-siteFriendlyName" >Customer Friendly Name</th>
                      <td mat-cell *matCellDef="let element" data-label="Site ID" tabindex="0" >
                        <span class="txt-ellipsis"  
                        style="padding-left: 16px; width: 184px !important; display: inline-block;"
                        [matTooltipDisabled]="false"
                        [matTooltip]="element.siteFriendlyName"
                        matTooltipPosition="below" 
                        matTooltipClass="my-custom-tooltip"
                        [innerHTML]="element.siteFriendlyName">
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="siteId" >
                    <th mat-header-cell *matHeaderCellDef   class="cls-siteid" >Customer Number</th>
                      <td mat-cell *matCellDef="let element" data-label="Site ID" tabindex="0" >
                        <span class="txt-ellipsis cls-siteid"  
                        [matTooltipDisabled]="false"
                        [matTooltip]="element.siteId"
                        matTooltipPosition="below" 
                        matTooltipClass="my-custom-tooltip"
                        [innerHTML]="element.siteId">
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="systemFriendlyName">
                    <th mat-header-cell *matHeaderCellDef class="cls-systemFriendlyName">System Friendly Name</th>
                    <td mat-cell *matCellDef="let element" data-label="System Friendly Name" tabindex="0">
                      <span class="txt-ellipsis cls-systemFriendlyName" [matTooltipDisabled]="false" [matTooltip]="element.systemFriendlyName"
                        matTooltipPosition="below" matTooltipClass="my-custom-tooltip" [innerHTML]="element.systemFriendlyName">
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="systemId" >
                    <th mat-header-cell *matHeaderCellDef  >Serial Number</th>
                    <td mat-cell *matCellDef="let element" data-label="System ID" tabindex="0">
                      <span class="txt-ellipsis cls-systemid"  
                      [matTooltipDisabled]="false"
                      [matTooltip]="element.systemId"
                      matTooltipPosition="below" 
                      matTooltipClass="my-custom-tooltip"
                      [innerHTML]="element.systemId">
                    </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="hostName"   >
                    <th mat-header-cell *matHeaderCellDef   >IP Address</th>
                    <td mat-cell *matCellDef="let element" data-label="HostName" tabindex="0">
                      <span class="cls-systemid">{{ element?.hostName }}</span>
                    </td>
                  </ng-container>
                  
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleRow(row)"></tr>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      
      </mat-dialog-content>
    </div>
  </form>
    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
      <button mat-button class="btn-default btn-md" [mat-dialog-close]="true" id="cancel" tabindex="0" 
        type="button">Cancel</button>
      <button mat-button type="submit" class="btn-primary btn-md" id="Done" tabindex="0" (click)="submitForm()">
        Done
      </button>
    </mat-dialog-actions>
  
</div>
