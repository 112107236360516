import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component } from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { FleetManagerActions } from 'src/app/store/actions';
import { State } from 'src/app/store/state';
import { FleetConfigurationService } from '../services/fleet-configuration.service';
import { FleetManagementService } from '../services/fleet-management.service';
import { OrganizationService } from '../../customer-management/services/organization.service';

@Component({
  selector: 'app-fleet-management',
  templateUrl: './fleet-management.component.html',
  styleUrls: ['./fleet-management.component.scss']
})
export class FleetManagementComponent implements AfterViewInit {

  constructor(private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
    private ngxLoader: NgxUiLoaderService,
    public fleetManagementService: FleetManagementService,
    public fleetConfigService: FleetConfigurationService,
    public auth: AuthService,
    public toastService: ToastService,
    public organizationService: OrganizationService,
    public store: Store<State>
  ) { }

  selected = '1';
  organizations: any[];
  
  ngOnInit() {
    this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());

    this.loadOrganizations();
  }

  loadOrganizations()
  {
    const ngxLoaderKey = 'loadOrganization';
    this.ngxLoader.start(ngxLoaderKey);
    this.organizationService.getParenOrganizations()
      .subscribe({next: (parentOrganizations) => {        
        this.organizations = parentOrganizations;         
        this.ngxLoader.stop(ngxLoaderKey);       
      }, error: (e) => {
        this.toastService.stopLoadingAndShowError(e,
          'Unable to fetch organizations!', ngxLoaderKey);        
      }});
  }

  ngAfterViewInit() {
  }

  radioChange(event: MatRadioChange) {
    this.selected = event.value;
  }
}
