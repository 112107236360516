<div class="flex justify-between content-stretch items-stretch flex-col">
    <div>
        <h1 mat-dialog-title tabindex="0" class="xs:flex-1 sm:basis-full">
            {{title}}
        </h1>
    </div>

    <mat-dialog-content>
        <div class="xs:flex xs:flex-col flex gap-8 sm:flex sm:flex-row sm:flex-wrap deviceDetails">
            <div  floatLabel="never" class="xs:flex-1 sm:basis-1/5 pr-20">
              <label for="edgeDeviceId">
                Host ID                
              </label>
              <p id="edgeDeviceId">{{edgeDeviceId}}</p>              
            </div>           
            <div  floatLabel="never" class="xs:flex-1 sm:basis-1/5 pr-20">
                <label for="status">
                  Agent Status                  
                </label>
                <p id="status">{{status}}</p>                
            </div>
            <div *ngIf="status==='Offline'" floatLabel="never" class="xs:flex-1 sm:basis-1/5 pr-20">
                <label for="lastKnownOnlineTime">
                  Last Online Time                
                </label>                
                <p id="lastKnownOnlineTime"> {{ lastKnownOnlineTime | date:'dd-MMM-yyyy hh:mm aaaaa\'m\'' }}</p>                
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full py-5">        
        <button mat-button class="btn-default btn-md" id="Disable" [disabled]="status == 'Disabled'" (click)="onDisable()" tabindex="0">
            Disable
        </button>
        <button mat-button type="submit" class="btn-primary btn-md" id="Done" [mat-dialog-close]="true" tabindex="0">
            Done
        </button>
    </mat-dialog-actions>
</div>